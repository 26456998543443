// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  useColorModeValue,
  Container,
  SimpleGrid,
  Image,
  Heading,
  Text,
  Stack,
  StackDivider,
  VStack,
  Grid,
  IconButton,
  Badge
} from '@chakra-ui/react';
import { FaHeart, FaHandPointer } from 'react-icons/fa';
import { FaUserShield } from 'react-icons/fa';
import { MdLocationOn, MdAccessTimeFilled, MdMessage } from 'react-icons/md';
import { AiFillLike } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';
// Custom components
import Card from 'components/card/Card.js';
import React, { useState, useEffect, useRef } from 'react';
// Assets
import { MdUpload } from 'react-icons/md';
import Dropzone from 'views/admin/profile/components/Dropzone';
import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';
import { t } from 'helpers/TransWrapper';
import socket from '../../../../socket';

import { io } from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Description(props) {
  const {
    isShopPromoted,
    promotedPrice,
    id,
    name,
    phone,
    userId,
    category,
    description,
    price,
    dateCreated,
    city,
    receiverId,
    subcategory,
    discountShop
  } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const brandColor2 = useColorModeValue('red.500', 'white');
  const btn = useColorModeValue('gray.400', 'brand.500');
  const textColorSecondary = 'gray.400';
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const history = useHistory();
  const [status, setStatus] = useState();
  const [fav, setFav] = useState(false);
  const [isValide, setIsValide] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [conversation, setConversation] = useState();
  const [ad, setAd] = useState();
  const [currentChat, setCurrentChat] = useState(null);
  const [listFavorites, setListFavorites] = useState([]);
  const [pre, setPre] = useState(true);
  const red = useColorModeValue('white', 'red.500');
  const fetchData = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API}/ads/ad/${id}`)
        .then(response => {
          console.log(response.data);
          setAd(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);
  console.log('ad description', ad);
  const handleLike = async () => {
    setFav(!fav);
    console.log('malk wliti object', id);

    if (currentUser) {
      try {
        await axios.patch(
          `${process.env.REACT_APP_API}/ads/${id}/like`,
          { userId: currentUser._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        // Update the local favorite list
        if (fav) {
          setListFavorites(prevFavorites =>
            prevFavorites.filter(favoriteId => favoriteId !== id)
          );
        } else {
          setListFavorites(prevFavorites => [...prevFavorites, id]);
        }

        await getFavorites(); // Fetch the updated favorites list
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    } else {
      history.push('/auth/login');
    }
  };

  useEffect(() => {
    getFavorites();
  }, [fav]);

  const getFavorites = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${currentUser._id}/favorites`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      setListFavorites(response.data);
      const isIdInFavorites = response.data.includes(id);
      setFav(isIdInFavorites);
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/conversations/${currentUser._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setConversations(res.data);
      } catch (err) {}
    };

    fetchConversations();
  }, []);
  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/conversations/find/${currentUser._id}/${receiverId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setCurrentChat(res.data);
      } catch (err) {}
    };

    fetchConversation();
  }, []);

  const addConv = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/conversations`,
        {
          senderId: currentUser._id,
          receiverId: receiverId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get(
          ` ${process.env.REACT_APP_API}/messages/` + currentChat?._id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setMessages(res.data);
      } catch (err) {}
    };
    getMessages();
  }, [conversation]);
  const createConv = async () => {
    let selectedChat = null;

    if (conversations.length === 0) {
      const newChat = await addConv();
      selectedChat = newChat;
    } else {
      conversations.forEach(conversation => {
        if (conversation.members.includes(receiverId)) {
          selectedChat = conversation;
        }
      });

      if (!selectedChat) {
        const newChat = await addConv();
        selectedChat = newChat;
      }
    }

    if (selectedChat) {
      setCurrentChat(selectedChat);

      history.push({
        pathname: '/chat/chat',
        state: { currentChat: selectedChat },
      });
      history.go(0);
    }
    console.log('currentChat', currentChat);
    localStorage.setItem('currentChat', JSON.stringify(selectedChat));
  };

  const fetchUser = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${id}`
      );
      const user = response.data;
      return user;
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error to be handled elsewhere if needed.
    }
  };

  const validerAd = async id => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/ads/${id}/valider`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const newAd = response.data;
      setStatus(newAd.status);
      toast.success('Annonce validée !', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      history.go(0);

      const userAd = await fetchUser(receiverId);
      console.log('sender notif : ', userAd);
      const notifications = {
        sender: receiverId,
        message: ` ${t('Nouvelle annonce créée par')} ${userAd.firstName} ${
          userAd.lastName
        }.`,
        type: 'newAdFollower',
        adId: id,
      };

      const followerIds = userAd.followers.map(follower => follower);

      const notificationData = followerIds.map(followerId => ({
        recipient: followerId,
        ...notifications,
      }));

      notificationData.forEach(async notification => {
        try {
          await axios.post(
            `${process.env.REACT_APP_API}/notifications`,
            notification,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
        socket.emit('sendNotification', notification);
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const annulerAd = async id => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/ads/${id}/annuler`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      history.goBack();

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <Card mb="20px" align="start" p="20px">
      <SimpleGrid columns={{ base: 1, md: 1 }}>
        <Stack align="start" spacing={5}>
          <Flex w="100%" justify="space-between" align="center">
            <Flex
              position="absolute"
              top="-6px"
              right="-6px"
              align="center"
              justify="center"
              bg={fav || listFavorites.includes(id) ? 'red.500' : 'gray.500'}
              borderRadius="full"
              w="40px"
              h="40px"
              boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
            >
              <IconButton
                icon={<FaHeart />}
                aria-label="Favorite"
                variant="solid"
                colorScheme="white"
                size="xl"
                onClick={handleLike}
              />
            </Flex>
            <Text
              textTransform={'uppercase'}
              color={brandColor}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('white', 'brand.500')}
              p={2}
              rounded={'md'}
            >
              {category}
            </Text>
            {subcategory &&        <Text
              textTransform={'uppercase'}
              color={brandColor2} // Use a different color to differentiate subcategory
              fontWeight={600}
              fontSize={'sm'}
              bg={red}
              p={2}
              ml="auto"
              rounded={'md'}
            >
              {subcategory}
            </Text>}
     
          </Flex>
          <Flex w='100%' justify='space-between'>
            {' '}
            <Box textAlign="start">
              {' '}
              <Heading>{name}</Heading>
              <Flex>
                <Text
                  color={textColorPrimary}
                  fontWeight="500"
                  fontSize="2xl"
                  style={{
                    textDecoration: isShopPromoted ? 'line-through' : 'none',
                    textDecorationThickness: isShopPromoted ? '2px' : 'auto',
                  }}
                >
                  {' '}
                  {price}{' '}
                </Text>
                {isShopPromoted && (
                  <Text
                    ml={2}
                    color={brandColor}
                    fontSize="2xl"
                    fontWeight="600"
                    mb="4px"
                  >
                    {' '}
                    {promotedPrice}{' '}
                  </Text>
                )}
              </Flex>
            </Box>
            {isShopPromoted && (
            <Box
          
              zIndex={1}
              align="center"
              justify="center"
          
              
            >
              <Badge fontSize="sm" colorScheme="red">
                PROMO -{discountShop}%
              </Badge>
            </Box>
          )}
          </Flex>

          <Text color={'gray.500'} fontSize={'lg'}>
            {description}
          </Text>
          <Flex>
            <Icon w={5} h={5} mr={2} as={MdAccessTimeFilled} />
            <Text
              pr={3}
              color={textColorPrimary}
              fontWeight="500"
              fontSize="sm"
            >
              {dateCreated}
            </Text>

            <Icon w={5} h={5} mr={2} as={MdLocationOn} />
            <Text
              pr={3}
              color={textColorPrimary}
              fontWeight="500"
              fontSize="sm"
            >
              {city}
            </Text>
          </Flex>

          {receiverId != currentUser?._id &&
            (currentUser ? (
              <Grid
                templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                gap={4}
                w="100%"
              >
                <Button
                  leftIcon={<MdMessage />}
                  variant="solid"
                  colorScheme="navy"
                  fontWeight="regular"
                  fontSize="sm"
                  minW="100%"
                  onClick={createConv}
                >
                  {t('Contacter')}
                </Button>

                <Button
                  leftIcon={<IoLogoWhatsapp />}
                  variant="solid"
                  colorScheme="whatsapp"
                  fontWeight="regular"
                  fontSize="sm"
                  minW="100%"
                  ml="auto"
                  onClick={() => {
                    const message =
                      "Bonjour, je suis intéressé(e) par vos produits. Pourriez-vous s'il vous plaît m'envoyer plus d'informations ?";
                    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
                      message
                    )}`;
                    window.open(whatsappUrl, '_blank');
                  }}
                >
                  Whatsapp
                </Button>
              </Grid>
            ) : (
              <NavLink to="/auth/login">
                <Button
                  variant="action"
                  fontWeight="regular"
                  fontSize="sm"
                  minW="60%"
                  ml="auto"
                  mt="auto"
                >
                  {t(`Connectez vous pour contacter l'annonceur`)}
                </Button>
              </NavLink>
            ))}

          {currentUser?.isAdmin && (
            <Box w="100%" mb="4">
              <Box w="100%" mb="4" display="flex" alignItems="center">
                <Icon as={FaUserShield} boxSize={5} mr="2" />{' '}
                {/* Add your icon here */}
                <Text fontWeight="bold" fontSize="lg">
                  Admin Actions
                </Text>
              </Box>
              <Grid
                templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                gap={4}
                w="100%"
              >
                {ad?.status === 'Validée' ? (
                  <Button
                    variant="solid"
                    colorScheme="gray" // Change to the desired color for non-valide ads
                    fontWeight="bold"
                    fontSize="sm"
                    minW="100%"
                    isDisabled // Disable the button for non-valide ads
                  >
                    Annonce déjà validée
                  </Button>
                ) : (
                  <Button
                    variant="solid"
                    colorScheme="green"
                    fontWeight="bold"
                    fontSize="sm"
                    minW="100%"
                    onClick={() => validerAd(id)}
                  >
                    Valider l'annonce
                  </Button>
                )}

                <Button
                  variant="solid"
                  colorScheme="red"
                  fontWeight="bold"
                  fontSize="sm"
                  minW="100%"
                  ml="auto"
                  onClick={() => annulerAd(id)}
                >
                  Annuler l'annonce
                </Button>
              </Grid>
            </Box>
          )}
        </Stack>
      </SimpleGrid>
    </Card>
  );
}
