import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Flex,
  Text,
  Button,
  Tooltip,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useColorMode
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import { ref, getDownloadURL, getMetadata } from 'firebase/storage';
import { storage } from '../../../../firebase';
import Nft3 from 'assets/img/nfts/Nft3.png';
import ReactPlayer from "react-player";

import { FaDownload } from 'react-icons/fa';
const checkImageExists = async imageUrl => {
  const storageRef = ref(storage, `messagesImages/${imageUrl}`);

  try {
    // Get the metadata of the image
    await getMetadata(storageRef);
    // Image exists in Firebase Storage
    return true;
  } catch (error) {
    // Image doesn't exist in Firebase Storage
    return false;
  }
};
const Messages = ({ messages, currentUser }) => {
  const [user, setUser] = useState(null);
  const [enlargedImageUrl, setEnlargedImageUrl] = useState('');
  const { colorMode } = useColorMode();

  // Function to open the image in a larger size
  const openEnlargedImage = imageUrl => {
    setEnlargedImageUrl(imageUrl);
  };

  // Function to close the enlarged image modal
  const closeEnlargedImage = () => {
    setEnlargedImageUrl('');
  };
  const messagesEndRef = useRef(null); // Create a ref to the last message element
  const handleDownload = fileURL => {
    window.open(fileURL, '_blank'); // Opens the file URL in a new tab
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios(
          `${process.env.REACT_APP_API}/users?userId=` + messages.sender
        );
        setUser(res.data);
      } catch (err) {
        // Handle errors
      }
    };

    getUser();
  }, [messages]);

  useEffect(() => {
    // Scroll to the last message when new messages are added
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  const [imageExists, setImageExists] = useState(true);

  const [imageError, setImageError] = useState(false);

  const handleImageError = event => {
    setImageError(true);
    event.target.src = Nft3;
  };
  return (
    <Flex flexDirection="column" p="3">
      {messages.sender === currentUser._id ? (
        <Flex w="100%" justify="flex-end">
        {messages.voiceURL && (
                <ReactPlayer
        url={messages.voiceURL}
        width="70%"
        height="40px"
        playing={false}
        controls={true}
        style={{
        borderRadius: '20px',
        backgroundColor: colorMode === 'light' ? '#422AFB' : '#F5F6FA',
        overflow: 'hidden',
      }}
        
      />
              )}
              {!messages.voiceURL &&  <Flex
            borderRadius='15px'
            bg="brand.500"
            color="white"
            maxW="100%"
            p="3"
            my="1"
            ml={2}
          >
            <Flex flexDirection="column">
              {messages.fileURL && (
                <Button
                  mb={1}
                  variant="action"
                  size="sm"
                  leftIcon={<FaDownload />}
                  onClick={() => handleDownload(messages.fileURL)}
                >
                  Fichier
                </Button>
              )}
             
              {messages.imageURL && !imageError && (
                <Image
                borderRadius='15px'
                  cursor="pointer"
                  src={messages.imageURL}
                  h="auto"
                  w="10rem"
                  mb={1}
                  onClick={() => openEnlargedImage(messages.imageURL)}
                  onError={handleImageError}
                />
              )}
              {imageError && (
                <Text fontSize="sm" fontStyle="italic">
                  "Image supprimée"
                </Text>
              )}
              <Text>{messages.text}</Text>{' '}
            </Flex>
          </Flex>}
         
          <Modal isOpen={!!enlargedImageUrl} onClose={closeEnlargedImage}>
            <ModalOverlay />
            <ModalContent
              maxW="40vw"
              maxH="40vh"
              w="100%"
              h="100%"
              bg="black" // Optional: Set the background color to match the image or your preference
            >
              <ModalBody p={0}>
                <Image
                  w="100%"
                  h="100%"
                  src={enlargedImageUrl}
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      ) : (
        <Flex w="100%">
          <Avatar src={user?.profilePicture} bg="blue.300" mr={2}/>
          {messages.voiceURL && (
                <ReactPlayer
        url={messages.voiceURL}
        width="70%"
       
        height="40px"
        playing={false}
        controls={true}
        style={{
          marginLeft : '5px',
          marginTop: '5px',
        borderRadius: '20px',
        backgroundColor: colorMode === 'light' ? '#422AFB' : '#F5F6FA',
        overflow: 'hidden',
      }}
        
      />
              )}
          
              {!messages.voiceURL &&<Flex
            borderRadius={10}
            bg="brand.100"
            color="navy"
            maxW="100%"
            my="1"
            p="3"
            ml={2}
          >
            <Flex flexDirection="column">
              {messages.fileURL && (
                <Button
                  mb={1}
                  variant="action"
                  size="sm"
                  leftIcon={<FaDownload />}
                  onClick={() => handleDownload(messages.fileURL)}
                >
                  Fichier
                </Button>
              )}
           
              {messages.imageURL && !imageError && (
                <Image
                  cursor="pointer"
                  src={messages.imageURL}
                  h="auto"
                  w="10rem"
                  mb={1}
                  borderRadius='15px'
                  onClick={() => openEnlargedImage(messages.imageURL)}
                  onError={handleImageError}
                />
              )}
              {imageError && (
                <Text fontSize="sm" fontStyle="italic">
                  Image supprimée
                </Text>
              )}
              <Text>{messages.text}</Text>{' '}
            </Flex>
          </Flex>}
        </Flex>
      )}
      <Text
        fontSize="xs"
        alignSelf={
          messages.sender === currentUser._id ? 'flex-end' : 'flex-start'
        }
      >
        {moment(messages.createdAt).fromNow()}
      </Text>
      <Modal isOpen={!!enlargedImageUrl} onClose={closeEnlargedImage}>
        <ModalOverlay />
        <ModalContent
          maxW="40vw"
          maxH="40vh"
          w="100%"
          h="100%"
          bg="black" // Optional: Set the background color to match the image or your preference
        >
          <ModalBody p={0}>
            <Image
              w="100%"
              h="100%"
              src={enlargedImageUrl}
              style={{ objectFit: 'cover', objectPosition: 'center' }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Create an empty div with a ref to scroll to */}
      <div ref={messagesEndRef}></div>
    </Flex>
  );
};

export default Messages;
