import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { openDB } from "idb";
import rootReducer from "./index";

const dbName = "adsIDB";
const adsStoreName = "ads";

const idbStorage = {
  async setItem(key, value) {
    const db = await openDB(dbName, 2, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log(`Upgrade triggered: oldVersion=${oldVersion}, newVersion=${newVersion}`);

        if (!db.objectStoreNames.contains(adsStoreName)) {
          const adsStore = db.createObjectStore(adsStoreName, { keyPath: "_id" });
          console.log(`Object store "${adsStoreName}" created.`);
        }
      },
    });

    const tx = db.transaction(adsStoreName, "readwrite");
    const store = tx.objectStore(adsStoreName);
    store.put(key);
    await tx.done;
    console.log(`Item "${key}" stored in object store.`);
  },

  async getItem(key) {
    const db = await openDB(dbName, 2);
    const store = db.transaction(adsStoreName).objectStore(adsStoreName);
    return store.get(key);
  },

  async removeItem(key) {
    const db = await openDB(dbName, 2);
    const tx = db.transaction(adsStoreName, "readwrite");
    const store = tx.objectStore(adsStoreName);
    store.delete(key);
    await tx.done;
  },
};

const persistConfig = {
  key: "recentAds",
  storage: idbStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Other store configuration options...
});

export const persistor = persistStore(store);

export default store;
