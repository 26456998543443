import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const FavoritesContext = createContext();

export function useFavorites() {
  return useContext(FavoritesContext);
}

export function FavoritesProvider({ children }) {
  const [listFavorites, setListFavorites] = useState([]);
  const [favoritesCount, setFavoritesCount] = useState(0);
  const userToken = JSON.parse(localStorage.getItem('user-token'));


  useEffect(() => {
    // Fetch the initial list of favorites and set the count
    const fetchFavorites = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/${userToken._id}/favorites`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setListFavorites(response.data);
        setFavoritesCount(response.data.length);
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchFavorites();
  }, []);

  const updateFavorites = async () => {
    try {
      // Fetch the updated list of favorites
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${userToken._id}/favorites`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setListFavorites(response.data);

      // Update the favorite count
      const numFavorites = response.data.length;
      setFavoritesCount(numFavorites);
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  return (
    <FavoritesContext.Provider value={{ listFavorites, favoritesCount, updateFavorites }}>
      {children}
    </FavoritesContext.Provider>
  );
}