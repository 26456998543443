// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "routes.js";

// Custom Chakra theme
export default function Shop(props) {
  const { ...rest } = props;
  const { t } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    const currentPath = window.location.hash.replace('#', '');
    const shopPath = '/shop/';
  
    if (currentPath === `${shopPath}create`) {
      return 'Créer ma boutique';
    }
    if (currentPath.startsWith(`/shop/update`)) {
      return 'Gérer ma boutique';
    }
    if (currentPath.startsWith(`/shop/dashboard`)) {
      return 'Tableau de bord';
    }
    if (currentPath.startsWith(`/shop/paypal/success`)) {
      return 'Paiement Réussi !';
    }
    if (currentPath.startsWith(`/shop/cmi/success`)) {
      return 'Paiement Réussi !';
    }
  
    if (currentPath.startsWith(shopPath)) {
      const lastShopIndex = currentPath.lastIndexOf(shopPath);
      const shopName = currentPath.substring(lastShopIndex + shopPath.length);
      const formattedShopName = shopName.replace(/-/g, ' ');
      return formattedShopName;
    }
  
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== 'Default Brand Text') {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== 'Default Brand Text') {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
  
    return 'Default Brand Text';
  };
  
  // Usage
  const activeRoute = getActiveRoute(routes); // Pass your routes array to the function
  
  
  
  
  
  
  
  
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/shop") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  const token = useSelector((state) => state.token);
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  
  

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}>
        <Sidebar routes={routes} display='none' {...rest} />
        <Box
          float='right'
          minHeight='100vh'
          height='100%'
          overflow='auto'
          position='relative'
          maxHeight='100%'
          w={{ base: "100%", xl: "calc( 100% - 300px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          <Portal>
                  

            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"JUMATIK"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx='auto'
              p={{ base: "20px", md: "30px" }}
              pe='20px'
              minH='100vh'
              pt='50px'>
              <Switch>
                {getRoutes(routes)}
                <Redirect from='/' to='/admin/default' />
              </Switch>
            </Box>
          ) : null}
          <Box>
            <Footer routes={routes}  />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
