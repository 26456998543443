import React, { useState, useEffect,useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import CategorySelect from '../component/CategorySelect';
import useAlert from '../../../components/alert/useAlert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloseIcon, AddIcon,MinusIcon  } from '@chakra-ui/icons';
import { v4 } from 'uuid';
import { deleteObject, ref, uploadBytes, getDownloadURL,refFromURL   } from 'firebase/storage';
import { storage } from '../../../firebase';
// Chakra imports
import {
  ThemeProvider,
  theme,
  Box,
  Button,
  GridItem,
  Image,
  Grid,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
} from '@chakra-ui/react';
import { MdUpload } from 'react-icons/md';
import { SmallCloseIcon } from '@chakra-ui/icons';
// Custom components
import { HSeparator } from 'components/separator/Separator';
// Assets
import illustration from 'assets/img/auth/auth.png';
import Dropzone from 'views/admin/profile/components/Dropzone';
import Card from 'components/card/Card.js';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { t } from 'helpers/TransWrapper';
import socket from '../../../socket'

function UpdateAd() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');

  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const { success, error } = useAlert();
  const history = useHistory();
  const [login, setLogin] = useState(false);
  const userData = localStorage.getItem('user-token');
  const user = JSON.parse(userData);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [orientation, setOrientation] = useState('horizontal');
  const [subcategories, setSubcategories] = useState([]);
  const [selectedType, setSelectedType] = useState('');

  const [tableFields, setTableFields] = useState([]);

  const [files, setFiles] = useState(Array.from({ length: 6 }, () => null));  // const fileInputs = useRef(new Array(6).fill(null));
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [tableSubFields, setTableSubFields] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedCityId, setSelectedCityId] = useState('');
  const [tableFieldsCat, setTableFieldsCat] = useState([]);

  const [secteurs, setSecteurs] = useState([]);
  const [selectedSecteur, setSelectedsecteur] = useState('');
  const [adId , setAdId ] = useState();

  const [ad, setAd] = useState({

  });

  const [admines,setAdmines]=useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API}/users/admin/all`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });
        const users = res.data;
        const admins = users.filter(user => user.isAdmin);
        setAdmines(admins);
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }
    };
  
    fetchData(); // Call the asynchronous function here
  
  }, []);
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    ad.subcategoryName
  );
  const [selectedSubcategoryLabel, setSelectedSubcategoryLabel] = useState(
    ad.subcategoryLabel
  );
  const { id } = useParams();
  const [images, setImages] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/ads/ad/${id}`)
      .then((res) => {
        const currentAd = res.data;
        console.log("ad result:", currentAd);
        const test=[]
        if (currentAd?.adPictures) {
          for (let i = 0; i < currentAd?.adPictures.length; i++) {
            test.push( currentAd?.adPictures[i]);
          }
          setFiles(test);
        }
        
        setSelectedCity(currentAd?.city);
        setSelectedType(currentAd?.type);
        setSelectedCategory(currentAd?.categoryName);
        setSelectedsecteur(currentAd?.secteur);
        setSelectedSubcategory(currentAd?.subcategoryName);
        setAd({
          userId: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          categoryName: currentAd?.categoryName,
          categoryLabel: currentAd?.categoryLabel,
          subcategoryName: currentAd?.subcategoryName,
          subcategoryLabel: currentAd?.subcategoryLabel,
          name: currentAd?.name,
          type: currentAd?.type,
          secteur: currentAd?.secteur,
          city: currentAd?.city,
          region: currentAd?.region,
          price: currentAd?.price,
          description: currentAd?.description,
          adPictures: currentAd?.adPictures,
          status: 'En cours de Validation',
          userProfilePicture: user.profilePicture,
        });
        setSelectedCategoryId(currentAd?.id);
        setSelectedSubcategory(currentAd?.subcategoryName || '');
        setSelectedSubcategoryLabel(currentAd?.subcategoryLabel || '');
        // Set the selectedSubcategory and selectedSubcategoryLabel state based on the fetched data
        setSelectedSubcategory(currentAd?.subcategoryName);
        setSelectedSubcategoryLabel(currentAd?.subcategoryLabel);
      })
      .catch((err) => console.error(err));
  }, [id]);
  console.log("ad cat label:",ad.categoryLabel);
  const categorySelected = categories.find((category)=> category.name === ad.categoryName);
  console.log("old category Id", categorySelected?._id);
  function FetchSubCat(categoryId) {
    useEffect(() => {
      if (categoryId) {
        axios
          .get(`${process.env.REACT_APP_API}/subcategories/${categoryId}`)
          .then(res => setSubcategories(res.data))
          .catch(err => console.error(err));
      }
    }, [categoryId]);
    
  }
  const OldSubCat = FetchSubCat(categorySelected?._id);

  ///////////////////////////////////////////////////////////

console.log(" waaa wriinii les  imageee ",files)
console.log("images : ",images);
  const cityselected = cities.find((city)=> city.name === ad.city);
  function FetchSect(selectedCityId) {
    useEffect(() => {
      if (selectedCityId) {
        axios
          .get(`${process.env.REACT_APP_API}/secteurs/${selectedCityId}`)
          .then(res => setSecteurs(res.data))
          .catch(err => console.error(err));
      }
    }, [selectedCityId]);
  }
  const OldSect = FetchSect(cityselected?.id);

  ////////////////////////////////////////////////////////////
  console.log("All subcat from Old cat",OldSubCat);
  console.log("All subcat from Old secteurs",OldSect);
  console.log("cityselected?.id ",cityselected?.id);
  console.log("All secteur from Old city",cityselected);

// images :
useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth < 872) {
      setOrientation('vertical');
    } else {
      setOrientation('horizontal');
    }
  };

  // Add event listener for window resize
  window.addEventListener('resize', handleResize);

  // Call the handleResize function initially to set the correct orientation on component mount
  handleResize();

  // Remove event listener on component unmount
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
// images.forEach(element => {
// console.log(element.src);
// })
const handleDelete = async (index, file) => {
  const updatedFiles = [...files];
  updatedFiles.splice(index, 1);
  setFiles(updatedFiles);

  // Delete the file from Firebase Storage
  try {
    const fileRef = ref(storage, file);
    await deleteObject(fileRef);
    console.log('File deleted:', file);
  } catch (error) {
    console.error('Error deleting file:', error);
  }
};
const handleDragStart = (event, id) => {
  event.dataTransfer.setData('text/plain', id);
};

const handleDragOver = event => {
  event.preventDefault();
};

const handleDrop = (event, targetIndex) => {
  event.preventDefault();

  const draggedFileIndex = event.dataTransfer.getData('text/plain');
  const draggedFile = files[draggedFileIndex];

  if (draggedFileIndex !== targetIndex) {
    const newFiles = [...files];
    newFiles.splice(draggedFileIndex, 1); // Remove the dragged file from its original position
    newFiles.splice(targetIndex, 0, draggedFile); // Insert the dragged file at the target index

    setFiles(newFiles);
  }
};
const handleFileChange = (event, index) => {
  const file = event.target.files[0];

  const updatedFiles = [...files];
  updatedFiles[index] = file;
  setFiles(updatedFiles);
};




  console.log("All secteur ",secteurs);
  const subcategorySelected = subcategories.find((subcategory)=> subcategory.name = ad.subcategoryName);
  console.log("old subcategory", subcategorySelected?.name);
  useEffect(() => {
    if (selectedCategory) {
      axios.get(`${process.env.REACT_APP_API}/customFields/get/category/${selectedCategory}`)
        .then(response => {
          setTableFieldsCat(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedCategory]);
  
  useEffect(() => {
    if (selectedSubcategory) {
      axios
        .get(`${process.env.REACT_APP_API}/customFields/get/subcategory/${selectedSubcategory}`)
        .then(response => {
          setTableFields(response.data[0]); // set the first object in the array as the state value
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [selectedSubcategory]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/categories`)
      .then(res => setCategories(res.data))
      .catch(err => console.error(err));
  }, []);
  useEffect(() => {
    if (selectedCategoryId) {
      axios
        .get(`${process.env.REACT_APP_API}/subcategories/${selectedCategoryId}`)
        .then(res => setSubcategories(res.data))
        .catch(err => console.error(err));
    }
  }, [selectedCategoryId]);


  useEffect(() => {
    
      axios
        .get(`${process.env.REACT_APP_API}/cities/`)
        .then(res => setCities(res.data))
        .catch(err => console.error(err));
    
  }, []);
  useEffect(() => {
    if (selectedCityId) {
      axios
        .get(`${process.env.REACT_APP_API}/secteurs/${selectedCityId}`)
        .then(res => setSecteurs(res.data))
        .catch(err => console.error(err));
    }
  }, [selectedCityId]);

  const HandleChange = e => {
    const { name, value } = e.target;
    setAd({
      ...ad, //spread operator
      [name]: value,
    });
  };
  const [fieldsValues, setFieldsValues] = useState([{
    ad_id: '',
    field_id: '',
    field_name: '',
    value: '',
  }]);
  const [subFieldsValues, setSubFieldsValues] = useState([{
    ad_id: '',
    field_id: '',
    field_name: '',
    value: '',
  }]);
  const [file, setFile] = useState([]);
  const handleFieldsChange = (fieldId, fieldType, e) => {
    let value;
    if (fieldType === 'radio') {
      value = e;
    } else if (fieldType === 'select') {
      value = e.target.value;
    } else {
      value = e.target.value;
    }
  
    setFieldsValues(prevFieldsValues => {
      const updatedValues = [...prevFieldsValues];
      const index = updatedValues.findIndex(item => item.field_id === fieldId);
      if (index >= 0) {
        updatedValues[index].value = value;
      } else {
        updatedValues.push({
          ad_id: ad._id,
          field_id: fieldId,
          field_name: e.target.name,
          value: value,
        });
      }
      return updatedValues;
    });
  };
  useEffect(() => {
    
     
    console.log(fieldsValues.find(item => item.field_id === tableFields._id));
  
    axios
    .get(
      `${process.env.REACT_APP_API}/subCustomFields/get/customField/value/${fieldsValues.find(item => item.field_id === tableFields._id)?.value}`
    )
    .then(response => {
      setTableSubFields(response.data);
    })
    .catch(error => {
      console.log(error);
    })
    ;
  
  
  }, [fieldsValues,tableFields?._id]);
  
    
  
  
    
    
    const handleSubFieldsChange = (subfieldId, subfieldType, e) => {
      let value;
    if (subfieldType === 'radio') {
      value = e;
    } else if (subfieldType === 'select') {
      value = e.target.value;
    } else {
      value = e.target.value;
    }
  
    setSubFieldsValues(prevFieldsValues => {
      const updatedValues = [...prevFieldsValues];
      const index = updatedValues.findIndex(item => item.field_id === subfieldId);
      if (index >= 0) {
        updatedValues[index].value = value;
      } else {
        updatedValues.push({
          ad_id: ad._id,
          field_id: subfieldId,
          field_name: e.target.name,
          value: value,
        });
      }
      return updatedValues;
    });
    };
  
  const onFileChange = event => {
    setAd({
      ...ad, //spread operator
      adPictures: event.target.files,
    });
    setFile(event.target.files);
  };
  function showToastAndRedirect() {
    try {
      history.push('/');
    } catch (error) {
      console.error(error);
    }
  }
  const update = async (fieldsValues,subfieldsValues) => {
    const formData = new FormData();
    const promises = [];
    formData.append('userId', ad.userId);
    formData.append('firstName', ad.firstName);
    formData.append('lastName', ad.lastName);
    formData.append('phone', ad.phone);
    formData.append('name', ad.name);
    formData.append('secteur', selectedSecteur);
    formData.append('city', selectedCity);
    formData.append('type', selectedType);
    formData.append('region', selectedRegion);
    formData.append('categoryName', selectedCategory);
    formData.append('categoryLabel', selectedCategoryLabel);
    formData.append('subcategoryName', selectedSubcategory);
    formData.append('subcategoryLabel', selectedSubcategoryLabel);
    formData.append('price', ad.price);
    formData.append('description', ad.description);
    formData.append('userProfilePicture', ad.userProfilePicture);
    formData.append('status', 'En cours de Validation');

    const uploadPromises = files.map(async (element) => {
      if (element) { // Check if the file is not empty
        const storageRef = ref(storage, `adPictures/${ad.name}/${element + v4()}`);
        await uploadBytes(storageRef, element);
        const downloadURL = await getDownloadURL(storageRef);
        formData.append('adPictures', downloadURL);
      }
    });
    
    await Promise.all(uploadPromises);

  
    const configuration = {
      method: 'put',
      url: `${process.env.REACT_APP_API}/ads/${id}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': `multipart/form-data; charset=utf-8`, // Specify U
      },
    };

    await axios(configuration)
    .then(result => {
      setLogin(true);

      const adToken = result.data;
      setAdId(adToken._id);
      const notifications = {
        // recipient: userId, // replace with actual recipient ID
        sender: 'system', 
        message: `${t('Nouvelle annonce créee par')} ${user.firstName} ${user.lastName} ${t('et nécessitant une vérification.')}`,
        type: 'newAddAdmin', // specify the type of notification
        adId: adToken._id, // adId
      };
      const notificationData = admines.map(admin => ({
        recipient: admin._id,
        ...notifications,
      }));
      
      notificationData.forEach(async (notification) => {
        socket.emit('sendNotification', notification);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API}/notifications`,
            notification,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
      
          const createdNotification = response.data;
          //console.log("waaaaaaaaaaaaaaaaaaaaaaaaw",createdNotification)
          // setCreatedNotification(createdNotification);
      
          // Dispatch the created notification to Redux store
          // store.dispatch(addNotification(createdNotification));
        } catch (error) {
          console.error(error);
        }
      });
      const foundFieldValue = fieldsValues.find(item => item.field_id === tableFields._id);

      const fieldValue = {
        
          ad_id: adToken._id,
          field_id: tableFields._id,
          field_name: tableFields.name,
          value: foundFieldValue ? foundFieldValue.value : ''
        };
      
      
        try {
          const response = axios.post(
            `${process.env.REACT_APP_API}/customFieldsValues/add/new`,
            fieldValue,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          console.log(response.data);
          // show a success message to the user
          toast.success('Field value created successfully!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } catch (error) {
          console.log(error);
          // show an error message to the user
          toast.error('Error creating field value!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      

      const fieldValuesCat = tableFieldsCat.map(field => {
        const foundFieldValue = fieldsValues.find(item => item.field_id === field._id);
        return {
          ad_id: adToken._id,
          field_id: field._id,
          field_name: field.name,
          value: foundFieldValue ? foundFieldValue.value : ''
        };
      });
      for (const fieldValueCat of fieldValuesCat) {
        try {
          const response = axios.post(
            `${process.env.REACT_APP_API}/customFieldsValues/add/new`,
            fieldValueCat,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          console.log(response.data);
          // show a success message to the user
          toast.success('Field value created successfully!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } catch (error) {
          console.log(error);
          // show an error message to the user
          toast.error('Error creating field value!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }
   
      const subfieldValues = tableSubFields.map(field => {
        const foundFieldValue = subfieldsValues.find(item => item.field_id === field._id);
        return {
          ad_id: adToken._id,
          field_id: field._id,
          field_name: field.name,
          value: foundFieldValue ? foundFieldValue.value : ''
        };
      });
      for (const subfield of subfieldValues) {
        try {
          const response = axios.post(
            `${process.env.REACT_APP_API}/subCustomFieldsValues/add/new`,
            subfield,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          console.log(response.data);
          // show a success message to the user
          toast.success('Field value created successfully!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } catch (error) {
          console.log(error);
          // show an error message to the user
          toast.error('Error creating field value!', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }
      if (!adToken) {
        error('Error', 'Un probleme est survenu ');
        return;
      }
    })
    .catch(error => {
      error = new Error();
    });
  };
  const adData = {
    name: document.getElementById('name')?.value,
    description: document.getElementById('description')?.value,
    userId: user._id,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    categoryName: document.getElementById('category')?.value,
    city: document.getElementById('city')?.value,
    price: 'Non défini',
    adPictures: '',
    status: 'Brouillon',
    userProfilePicture: user.profilePicture,

    // Other fields
  };

  console.log(id);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!ad.name.trim()) {
      toast.error('Nommez votre annonce!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
    if (!ad.description.trim()) {
      toast.error('Décrivez votre annonce!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
    if (!selectedCity.trim()) {
      toast.error('Donner une ville à votre annonce!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
    if (typeof ad.price === 'string' && !ad.price.trim()) {
      toast.error(`Fixez le prix de votre annonce!`, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
    if (!selectedCategory.trim()) {
      toast.error('Attribuez une catégorie à votre annonce!', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    await update(fieldsValues,subFieldsValues);
    toast(
      `Nous avons bien reçu votre annonce et nous vous remercions de votre confiance. Votre annonce est en cours de vérification et sera publiée prochainement si elle respecte nos critères de publication.

      Nous vous informerons de la décision prise dans les heures à venir. Merci de patienter pendant que nous examinons votre annonce.`,
      {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      }
    );
    setTimeout(() => {
      showToastAndRedirect();
    }, 4000);
  };
  const handleRadioChange = (value) => {
    console.log("Selected value:", value);
    setSelectedType(value);
  };
  return (
    <Card padding="20px" mt={{ base: '80px', md: '10px' }}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Flex
        direction="column"
        w={{ base: '100%', md: '100%' }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: 'auto', lg: 'unset' }}
        me="auto"
        mb={{ base: '20px', md: 'auto' }}
      >
        <Text
          mb={5}
          color={textColor}
          align="start"
          fontSize="2xl"
          fontWeight="600"
        >
{t('Modifier votre annonce')}        </Text>
<FormControl onSubmit={e => handleSubmit(e)}>
<FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="md"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    mt={10}
                  >
                    {t('Ajoutez les images du produit (Max 6 images)')}
                  </FormLabel>{' '}
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    mt={10}
                  >
                    <ul>
                      <li>
                        {t('Une annonce avec des images a plus de visibilité')}{' '}
                      </li>
                      <li>{t('La première image est la principale')} </li>
                      <li>
                        {t(
                          `Pour changer l'image principale glisser l'image de votre choix vers la première case`
                        )}
                      </li>
                    </ul>
                  </FormLabel>{' '}
                  <Box>
                  <Grid
                      gridTemplateColumns={{
                        xl: 'repeat(3, 1fr)',
                        '2xl': '1fr 0.46fr',
                      }}
                      display={{ base: 'block', xl: 'grid' }}
                      gap={4}
                      mb="10px"
                    >
                    
                    {Array.from({ length: 6 }).map((_, index) => {
                        const file = files[index];


                        return (
                          <GridItem key={index} mb={2}>
                            <Box
                              border={
                                index === 0
                                  ? '4px dashed red'
                                  : '2px dashed gray'
                              }
                              height="100%"
                              width="100%"
                              onDragOver={handleDragOver}
                              onDrop={event => handleDrop(event, index)}
                            >
                              {file ? (
                                <>
                                  <Box position="relative">
                                    <IconButton
                                      colorScheme="red"
                                      aria-label="Delete Image"
                                      icon={<CloseIcon />}
                                      size="sm"
                                      onClick={() => handleDelete(index)}
                                      position="absolute"
                                      top="2"
                                      right="2"
                                    />
                                    <Image
                                      src={file}
                                      w="100%"
                                      h="100%"
                                      objectFit="cover"
                                      draggable
                                      onDragStart={event =>
                                        handleDragStart(event, index)
                                      }
                                    />
                                  </Box>
                                </>
                              ) : index === 0 ? (
                                <Button
                                  as="label"
                                  htmlFor="fileInput"
                                  w="100px"
                                  h="100px"
                                  bg="transparent"
                                  _hover={{ bg: 'transparent' }}
                                  _active={{ bg: 'transparent' }}
                                  _focus={{ boxShadow: 'none' }}
                                  leftIcon={<AddIcon />}
                                  cursor="pointer"
                                >
                                  {t(`Image principale`)}
                                  <Input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    style={{
                                      display: 'none',
                                    }}
                                    onChange={event =>
                                      handleFileChange(event, index)
                                    }
                                  />
                                </Button>
                              ) : (
                                <Button
                                  as="label"
                                  htmlFor="fileInput"
                                  w="100px"
                                  h="100px"
                                  bg="transparent"
                                  _hover={{ bg: 'transparent' }}
                                  _active={{ bg: 'transparent' }}
                                  _focus={{ boxShadow: 'none' }}
                                  leftIcon={<AddIcon />}
                                  cursor="pointer"
                                >
                                  {t('Ajouter une image')}
                                  <Input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    style={{
                                      display: 'none',
                                    }}
                                    onChange={event =>
                                      handleFileChange(event, index)
                                    }
                                  />
                                </Button>
                              )}
                            </Box>
                          </GridItem>
                        );
                      })}
                    </Grid>
                  </Box>
          <Flex h="100%" px={5} mb={2}></Flex>
          <Box height="90px">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              {t(`Nom de l 'annonce`)}
              <Text color={brandStars}>*</Text>{' '}
            </FormLabel>{' '}
            <Input
              id="name"
              isRequired={true}
              variant="auth"
              name="name"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="text"
              placeholder={t(`Entrez le nom de l'annonce`)}
              mb="24px"
              fontWeight="500"
              size="lg"
              value={ad.name}
              onChange={HandleChange}
            />
          </Box>{' '}
          <Box height="90px">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              {t(`Choisir une categorie`)} <Text color={brandStars}> * </Text>{' '}
            </FormLabel>{' '}
            <Select
              id="category"
              name="categoryName"
              isRequired={true}
              fontSize="sm"
              mb="24px"
              size="lg"
              variant="auth"
              onChange={e => {
                const selectedCategoryId =
                  e.target.options[e.target.selectedIndex].dataset.id;
                const selectedCategory = categories.find(
                  category => category._id === selectedCategoryId
                );
                setSelectedSubcategory(null);
                setSelectedCategory(selectedCategory?.name);
                setSelectedCategoryId(selectedCategoryId);
                setSelectedCategoryLabel(selectedCategory?.label);
              }}
              value={selectedCategory ? selectedCategory : ad.categoryName}
              placeholder={t(`Choisir une categorie`)}
            >
              {categories.map(category => (
                <option
                  key={category._id}
                  value={category.name}
                  name={category.label}
                  data-id={category._id}
                >
                  {category.label}
                </option>
              ))}
            </Select>
          </Box>
          <Box height="90px">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              {t(`Choisir une sous-categorie`)}{' '}
              <Text color={brandStars}> * </Text>{' '}
            </FormLabel>{' '}
            <Select
              id="category"
              name="categoryName"
              isRequired={true}
              fontSize="sm"
              mb="24px"
              size="lg"
              value={selectedSubcategory ? selectedSubcategory :  subcategorySelected?.name}
              variant="auth"
              onChange={e => {
                setSelectedSubcategory(e.target.value);
                setSelectedSubcategoryLabel(
                  e.target.options[e.target.selectedIndex].text
                );
              }}
              placeholder={t(`Choisir une sous-categorie`)}
            >
              {' '}
              {subcategories.map(subcategory => (
                <option
                  key={subcategory._id}
                  value={subcategory?.name}
                  name={subcategory?.label}
                >
                  {' '}
                  {subcategory.label}{' '}
                </option>
              ))}{' '}
            </Select>
          </Box>
          {selectedSubcategory && (
            <>
              
                <>
                <Box height="90px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    {tableFields?.name}
                  </FormLabel>
                  <FormControl isRequired={true}>
                    {tableFields?.type === 'text' && (
                      <InputGroup size="md">
                        <Input
                          fontSize="sm"
                          placeholder="Enter text"
                          mb="24px"
                          size="lg"
                          variant="auth"
                          value={fieldsValues.find(item => item.field_id === tableFields?._id)?.value}
                          onChange={value =>
                            handleFieldsChange(tableFields?._id, tableFields?.type, value)
                          }
                          name={tableFields?.name}
                        />
                      </InputGroup>
                    )}
                    {tableFields?.type === 'textarea' && (
                      <Textarea
                        fontSize="sm"
                        placeholder="Enter text"
                        mb="24px"
                        size="lg"
                        variant="auth"
                        name={tableFields?.name}
                        value={fieldsValues.find(item => item.field_id === tableFields?._id)?.value}
                        onChange={value =>
                          handleFieldsChange(tableFields?._id, tableFields?.type, value)
                        }
                      />
                    )}
                    {tableFields?.type === 'radio' && (
                      <RadioGroup
                        variant="auth"
                        name={tableFields?.name}
                        value={fieldsValues.find(item => item.field_id === tableFields?._id)?.value}
                        onChange={value =>
                          handleFieldsChange(tableFields?._id, tableFields?.type, value)
                        }
                        mb="24px"
                        size="lg"
                      >
                        <Stack direction="row">
                          {tableFields.options.map(option => (
                            <Radio value={option} key={option}>
                              {option}
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                    )}
                    {tableFields?.type === 'select' && (
                      <Select
                        placeholder="Select option"
                        name={tableFields?.name}
                        value={fieldsValues.find(item => item.field_id === tableFields?._id)?.value}
                        onChange={value =>
                          handleFieldsChange(tableFields?._id, tableFields?.type, value)
                        }
                        fontSize="sm"
                        mb="24px"
                        size="lg"
                        variant="auth"
                      >
                        {tableFields.options.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Box>
                  {console.log(fieldsValues.find(item => item.field_id === tableFields?._id))}
                  {fieldsValues.find(item => item.field_id === tableFields?._id) && (
                    <>
                      {tableSubFields.map(subField => (
                        
                        <Box height="90px">
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            display="flex"
                          >
                            {subField?.name}
                          </FormLabel>
                          <FormControl isRequired={true}>
                            {subField?.type === 'text' && (
                              <InputGroup size="md">
                                <Input
                                  fontSize="sm"
                                  placeholder="Enter text"
                                  mb="24px"
                                  size="lg"
                                  variant="auth"
                                  value={subFieldsValues.find(item => item.field_id === subField?._id)?.value}
                                  onChange={value =>
                                    handleFieldsChange(
                                      subField._id,
                                      subField?.type,
                                      value
                                    )
                                  }
                                  name={subField?.name}
                                />
                              </InputGroup>
                            )}
                            {subField?.type === 'textarea' && (
                              <Textarea
                                fontSize="sm"
                                placeholder="Enter text"
                                mb="24px"
                                size="lg"
                                variant="auth"
                                name={subField?.name}
                                value={subFieldsValues.find(item => item.field_id === subField?._id)?.value}
                                onChange={value =>
                                  handleSubFieldsChange(
                                    subField._id,
                                    subField.type,
                                    value
                                  )
                                }
                              />
                            )}
                            {subField?.type === 'radio' && (
                              <RadioGroup
                                variant="auth"
                                name={subField?.name}
                                value={subFieldsValues.find(item => item.field_id === subField?._id)?.value}
                                onChange={value =>
                                  handleSubFieldsChange(
                                    subField._id,
                                    subField.type,
                                    value
                                  )
                                }
                                mb="24px"
                                size="lg"
                              >
                                <Stack direction="row">
                                  {subField.options.map(option => (
                                    <Radio value={option} key={option}>
                                      {option}
                                    </Radio>
                                  ))}
                                </Stack>
                              </RadioGroup>
                            )}
                            {subField?.type === 'select' && (
                              <Select
                                placeholder="Select option"
                                name={subField?.name}
                                value={subFieldsValues.find(item => item.field_id === subField?._id)?.value}
                                onChange={value =>
                                  handleSubFieldsChange(
                                    subField._id,
                                    subField.type,
                                    value
                                  )
                                }
                                fontSize="sm"
                                mb="24px"
                                size="lg"
                                variant="auth"
                              >
                                {subField.options.map(option => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </Select>
                            )}
                          </FormControl>
                        </Box>
                      ))}
                      
                    </>
                  )}
                 
                  </> 
              
            </>
          )}
          {selectedCategory && (
            <>
              {tableFieldsCat.map(field => (
                <Box height="90px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    {field?.name}
                  </FormLabel>
                  <FormControl isRequired={true}>
                    {field?.type === 'text' && (
                      <InputGroup size="md">
                        <Input
                          fontSize="sm"
                          placeholder="Enter text"
                          mb="24px"
                          size="lg"
                          variant="auth"
                          value={fieldsValues.find(item => item.field_id === field?._id)?.value}
                          onChange={value =>
                            handleFieldsChange(field?._id, field?.type, value)
                          }
                          name={field?.name}
                        />
                      </InputGroup>
                    )}
                    {field?.type === 'textarea' && (
                      <Textarea
                        fontSize="sm"
                        placeholder="Enter text"
                        mb="24px"
                        size="lg"
                        variant="auth"
                        name={field?.name}
                        value={fieldsValues.find(item => item.field_id === field?._id)?.value}
                        onChange={value =>
                          handleFieldsChange(field?._id, field?.type, value)
                        }
                      />
                    )}
                    {field?.type === 'radio' && (
                      <RadioGroup
                        variant="auth"
                        name={field?.name}
                        value={fieldsValues.find(item => item.field_id === field?._id)?.value}
                        onChange={value =>
                          handleFieldsChange(field?._id, field.type, value)
                        }
                        mb="24px"
                        size="lg"
                      >
                        <Stack direction="row">
                          {field.options.map(option => (
                            <Radio value={option} key={option}>
                              {option}
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                    )}
                    {field.type === 'select' && (
                      <Select
                        placeholder="Select option"
                        name={field.name}
                        value={fieldsValues.find(item => item.field_id === field?._id)?.value}
                        onChange={value =>
                          handleFieldsChange(field?._id, field.type, value)
                        }
                        fontSize="sm"
                        mb="24px"
                        size="lg"
                        variant="auth"
                      >
                        {field.options.map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Box>
              ))}
            </>
          )}
          <Box height="90px">
                    <FormLabel
                      ms="4px"
                      fontSize="md"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      {t(`Choisissez le type de votre annonce`)}{' '}
                      <Text color={brandStars}> * </Text>{' '}
                    </FormLabel>
                    <RadioGroup
        name="type"
        variant="auth"
        onChange={handleRadioChange}
        value={selectedType}
        mb="20px"
      >
        <Stack  direction="row">
          <Radio colorScheme="red" size="lg" value="offre">
             {t('Offre')}
          </Radio>
          <Radio colorScheme="green" size="lg" value="demande">
             {t('Demande')}
          </Radio>
        </Stack>
      </RadioGroup>{' '}
                    {' '}
                  </Box>
          <Box height="90px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              {t(`Entrez le prix en MAD`)} <Text color={brandStars}> * </Text>{' '}
            </FormLabel>{' '}
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder={t(`Entrez le prix en MAD`)}
                mb="24px"
                size="lg"
                variant="auth"
                value={ad.price}
                name="price"
                onChange={HandleChange}
              />
            </InputGroup>{' '}
          </Box>{' '}
          <Box height="90px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              {t(`Choisissez votre ville`)} <Text color={brandStars}> * </Text>{' '}
            </FormLabel>{' '}
            <InputGroup>
              <Select
                id="city"
                name="location"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder={t(`Choisissez votre ville`)}
                mb="24px"
                fontWeight="200"
                size="lg"
               value={selectedCity?selectedCity:ad.city}
               onChange={e => {
                  const selectedCityName = e.target.value;

                  const selectedCity = cities.find(
                    city => city.name === selectedCityName
                  );

                  if (selectedCity) {
                    setSelectedCityId(selectedCity.id);
                    // do something with the selected city id
                  }
                  setSelectedCity(e.target.value);
                }}
               >
                
              
                {' '}
                {cities.map(city => (
                  <option key={city.id} value={city.name}>
                    {' '}
                    {city.name}{' '}
                  </option>
                ))}{' '}
              </Select>
            </InputGroup>
          </Box>{' '}
          <Box height="90px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              {t(`Choisissez votre secteur`)}{' '}
              <Text color={brandStars}> * </Text>{' '}
            </FormLabel>{' '}
            <InputGroup>
              <Select
                id="city"
                name="location"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                placeholder={t(`Choisissez votre secteur`)}
                mb="24px"
                fontWeight="200"
                value={selectedSecteur?selectedSecteur:ad.secteur}
                size="lg"
                onChange={e => setSelectedsecteur(e.target.value)}
              >
                {' '}
                {secteurs?.map(secteur => (
                  <option key={secteur._id} value={secteur.name}>
                    {' '}
                    {secteur.name}{' '}
                  </option>
                ))}{' '}
              </Select>
            </InputGroup>
          </Box>{' '}
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            {t(`Entrez la description de l 'annonce`)}
            <Text color={brandStars}>*</Text>{' '}
          </FormLabel>
          <Textarea
            id="description"
            fontSize="sm"
            mb="24px"
            fontWeight="500"
            size="lg"
            ms={{ base: '0px', md: '0px' }}
            isRequired={true}
            placeholder="description de l'annonce"
            value={ad.description}
            name="description"
            onChange={HandleChange}
          />
          <Alert status="error" mb={5} align="start">
            <AlertDescription>
              {t(
                `Pour créer une nouvelle annonce, veuillez noter que les contenus à caractère sexuel, promotionnels de produits illégaux ou dangereux, ou tout autre contenu considéré comme inapproprié ne seront pas acceptés. Nous nous réservons le droit de refuser ou de retirer toute annonce qui ne respecte pas nos règles de publication. Merci de votre compréhension.`
              )}
            </AlertDescription>
          </Alert>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={handleSubmit}
          >
            {t(`Ajouter`)}{' '}
          </Button>{' '}
        </FormControl>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        ></Flex>{' '}
      </Flex>
    </Card>
  );
}

export default UpdateAd;