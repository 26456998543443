// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Text,
  useColorModeValue,
  Button,
  Stack,
  Center,
  Badge,
  IconButton 
} from '@chakra-ui/react';
import axios from 'axios';
import Card from 'components/card/Card.js';
import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';
import { FaStoreAlt } from 'react-icons/fa';
import { FaUserTie } from 'react-icons/fa';
import socket from '../../../../socket'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  MdPersonAdd,
  MdPhone,
  MdPersonAddDisabled,
  MdPersonSearch,
  MdSettings,
} from 'react-icons/md';
import { t } from 'helpers/TransWrapper';

export default function Banner(props) {
  const { banner, avatar, name, job, posts, userId, propart } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important'
  );


  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [followersCount, setFollowersCount] = useState();
  const [followersFlag, setFollowersFlag] = useState(false);
  const [followingCount, setFollowingCount] = useState();
  const [followers, setFollowers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [following, setFollowing] = useState([]);
  const [isFollowing, setIsFollowing] = useState(() => {
    const storedFollowStatus = localStorage.getItem('followStatus');
    return storedFollowStatus ? JSON.parse(storedFollowStatus) : false;
  });
  const notification = {
    recipient: userId, // replace with actual recipient ID
    sender: currentUser?._id, // replace with actual sender ID
    message: `${currentUser?.firstName} ${currentUser?.lastName}, vous suit.`,
    type: 'new_follower', // specify the type of notification
  };
  const history = useHistory();
  const updateProfile = e => {
    history.push('/register/updateUser');
   
  };
  console.log("followersFlag",followersFlag);
  const handleFollow = async () => {
   setFollowersFlag(!followersFlag);
    if (currentUser) {
  
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_API}/users/follow/${currentUser?._id}/${userId}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        if (followersFlag) {
        
          toast.error(`Utilisateur retirée de votre liste des abonnements`, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else {
          toast.success(`Utilisateur ajouté à votre liste des abonnements`, { // Corrected from toast.sucess to toast.success
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
       
      } catch (error) {
        console.error(error);
      }
      if (!followers.some(follower => follower._id === currentUser?._id)) {
        // only send notification when following
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API}/notifications`,
            notification,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          const newNotification = response.data;
          setNotifications(newNotification);
        } catch (error) {
          console.error(error);
        }
        socket.emit('sendNotification', notification);
     
      }
    } else {
      history.push('/auth/login');
    }
  };

  const fetchFollowers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${userId}/followers`
      );
      const followers = response.data;
      setFollowersCount(followers.length);

      setFollowers(followers);
       const isIdInFavorites = followers.some(follower => follower._id === currentUser?._id);
       console.log("isIdInFollowers" ,isIdInFavorites);
      setFollowersFlag(isIdInFavorites);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFollowers();
  }, [followersFlag]);
  const fetchFollowing = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${userId}/following`
      );
      const following = response.data;
      setFollowingCount(following.length);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFollowing();
  }, [userId]);

  return (
    <Card      shadow="lg"
    p={0}
    mb={{ base: '0px', lg: '5px' }}
    align="center">
       <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
          borderBottomRadius={0}
        h="160px"
          w="100%"
      />
      
      {/* Add the shop badge overlay to the user avatar */}
      {propart === 'pro' ? (
        <Flex
          position="absolute"
          top="-6px"
          right="-6px"
          align="center"
          justify="center"
          bg="green.500"
          borderRadius="full"
          w="35px"
          h="35px"
          boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
        >
          <IconButton
            icon={<FaStoreAlt />}
            aria-label="Shop Account"
            variant="solid"
            colorScheme="white"
            size="xl"
          />
        </Flex>
      ): <Flex
          position="absolute"
          top="-6px"
          right="-6px"
          align="center"
          justify="center"
          bg="red.500"
          borderRadius="full"
          w="35px"
          h="35px"
          boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
        >
          <IconButton
icon={<FaUserTie />}            
aria-label="Shop Account"
            variant="solid"
            colorScheme="white"
            size="xl"
          />
        </Flex>}
     
      {/* User avatar with modified border color */}
      <Avatar
        mx="auto"
        src={avatar}
        h="90px"
        w="90px"
        mt="-43px"
        //border={`2px solid ${props.propart === 'pro' ? 'green' : borderColor}`}
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize="sm">
        {job}
      </Text>
      <Center>
        <Stack align="center" direction={['column', 'row']} spacing="60px">
          <Box>
            <Flex mx="auto" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {posts}
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                 {t('Annonces')}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Flex mx="auto" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {followersCount ? followersCount : 0}
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                 {t('Abonnes')}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Flex mx="auto" direction="column">
              <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                {followingCount ? followingCount : 0}
              </Text>
              <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                  {t('Abonnements')}
              </Text>
            </Flex>
          </Box>
        </Stack>
      </Center>
      {currentUser?._id === userId ? (
        <Stack mt={3} p={1}>
        <NavLink to='/admin/profile' > <Button
            leftIcon={<MdPersonSearch />}
            variant="action"
            fontWeight="regular"
            fontSize="md"
            minW="100%"
            mx="auto"
          >
             {t('Afficher mon profile')} 
          </Button></NavLink>
         
          <Button
            leftIcon={<MdSettings />}
            variant="action"
            fontWeight="regular"
            fontSize="md"
            minW="100%"
            mx="auto"
            onClick={updateProfile}
          >
              {t('Modifier mon profile')} 
          </Button>
         
        </Stack>
      ) : (
        <Stack mt={3} p={1}>
          {followersFlag || followers.some(follower => follower._id === currentUser?._id) ? (
            <Button
              leftIcon={<MdPersonAddDisabled />}
              variant="solid"
              colorScheme="red"
              fontWeight="regular"
              fontSize="md"
              minW="100%"
              mx="auto"
              onClick={handleFollow}
            >
               {t('Se désabonner')} 
            </Button>
          ) : (
            <Button
              leftIcon={<MdPersonAdd />}
              variant="solid"
              colorScheme="green"
              fontWeight="regular"
              fontSize="md"
              minW="100%"
              mx="auto"
              onClick={handleFollow}
            >
              {t(`S'abonner`)}
            </Button>
          )}

          <Button
            leftIcon={<MdPhone />}
            variant="action"
            fontWeight="regular"
            fontSize="md"
            minW="100%"
            mx="auto"
            onClick={() => {
    window.location.href = `tel:${job}`; // replace +1234567890 with the phone number you want to call
  }}

          >
             {t(`Appeler`)}
          </Button>
        </Stack>
      )}
    </Card>
  );
}
