// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Badge,
  Box,
  HStack,
  keyframes,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Stack,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { BsThreeDotsVertical, BsChatSquareQuote } from 'react-icons/bs';
import { useFavorites } from '../../contexts/FavoritesContext'; // Import the FavoritesContext
import {
  MdPerson,
  MdHome,
  MdLock,
  MdCardMembership,
  MdEmail,
  MdCategory,
  MdPersonAddAlt1,
} from 'react-icons/md';
import { NavLink, useHistory } from 'react-router-dom';
// Custom Components
import { FaAppStoreIos, FaGooglePlay } from 'react-icons/fa';
import {
  FiUser,
  FiSettings,
  FiLogOut,
  FiHelpCircle,
  FiShoppingCart,
} from 'react-icons/fi';
//import css module
import { ItemContent } from 'components/menu/ItemContent';
import { NewFollower } from 'components/notifications/NewFollower';
import { NewAd } from 'components/notifications/NewAd';
import { NewAddAdmin } from 'components/notifications/NewAddAdmin';
import { AllNotification } from 'components/notifications/AllNotification';
import HistoryItem from './HistoryItem';

import { NewMessage } from 'components/notifications/NewMessage';
import { t, LanguageSwitcher, getLocale } from 'helpers/TransWrapper';
import { motion } from 'framer-motion';
import { Message } from 'components/menu/Message';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { MdOutlineNotificationsPaused, MdDeleteForever } from 'react-icons/md';

import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import Chat from 'views/admin/chat';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import {
  MdNotificationsNone,
  MdInfoOutline,
  MdFavoriteBorder,
  MdFavorite,
  MdOutlineChat,
} from 'react-icons/md';
import moment from 'moment';

import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
import { ThemeEditor } from './ThemeEditor';
import axios from 'axios';
import socketIOClient from 'socket.io-client'; // Import the Socket.IO client library
import socket from '../../socket';
import { FaWallet } from 'react-icons/fa'; // Importez l'icône de portefeuille depuis la bibliothèque d'icônes
export default function HeaderLinks(props) {
  const { secondary } = props;
  // Chakra Color Mode
  const pulseRing = keyframes`
	0% {
    transform: scale(0.3);
  }
  40% {
    opacity: 0.3;
  },
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
	`;
  const { listFavorites, favoritesCount } = useFavorites();
  const navbarIcon = useColorModeValue('gray.400', 'white');
  const { colorMode, toggleColorMode } = useColorMode();
  const { banner, avatar, name, job, posts, followers, following } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const notification = useColorModeValue('red.600', 'red.200');
  const favorites = useColorModeValue('green.600', 'green.200');
  const bgM = useColorModeValue('gray.200', 'gray.500');
  const userToken = JSON.parse(localStorage.getItem('user-token'));

  const icon = useColorModeValue('brand.500', 'white');
  const [notificationCount, setNotificationsCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);

  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const size = '40px';
  const color = 'teal';
  const color2 = 'orange';
  const history = useHistory();
  const [messageCount, setMessageCount] = useState(0);
  const [messageComponents, setMessageComponents] = useState([]);
  const [notificationComponents, setNotificationComponents] = useState([]);

  // const [socket, setSocket] = useState(null);
  // // Add the Socket.IO connection setup
  // useEffect(() => {
  //   const socket = socketIOClient(`${process.env.REACT_APP_SOCKET}`); // Replace with your server URL
  //   setSocket(socket);

  // Listen for new messages

  useEffect(() => {
    // Check if socket is defined before setting up event listeners
    socket.on('receiveNotification', messageData => {
      // Handle new notifications here
      // Access the data in messageData
      console.log('Received new notification:', messageData);

      const { type, recipient, sender, message } = messageData;

      // Create a new notification object
      const newNotification = {
        type,
        recipient,
        sender,
        message,
        createdAt: Date.now(), // You can set the timestamp here
      };

      // Update the state with the new notification
      if (newNotification.type === 'new_message') {
        setMessagesCount(messagesCount + 1);
      }
    });
  }, [messagesCount]);
  useEffect(() => {
    // Check if socket is defined before setting up event listeners
    socket.on('receiveNotification', messageData => {
      // Handle new notifications here
      // Access the data in messageData
      console.log('Received new notification:', messageData);

      const { type, recipient, sender, message } = messageData;

      // Create a new notification object
      const newNotification = {
        type,
        recipient,
        sender,
        message,
        createdAt: Date.now(), // You can set the timestamp here
      };

      // Update the state with the new notification
      if (
        newNotification.type === 'newAddAdmin' ||
        newNotification.type === 'newAdFollower' ||
        newNotification.type === 'validation' ||
        newNotification.type === 'new_follower'
      ) {
        setNotificationsCount(notificationCount + 1);
      }
    });
  }, [notificationCount]);

  //   // Listen for new notifications
  //   socket.on('receiveNotification', (notificationData) => {
  //     // Handle new notifications here
  //     console.log('New notification received:', notificationData);
  //     // Update your notification count and display
  //   });

  //   return () => {
  //     // Disconnect from the socket when the component unmounts
  //     socket.disconnect();
  //   };
  // }, []);
  const fetchNotificationsCount = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/notifications/count/${userToken._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const num = res.data;
      setNotificationsCount(num.count);
    } catch (err) {}
  };
  // const getFavorites = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API}/ads/${userToken._id}/favorites`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     setListFavorites(response.data);
  //   } catch (error) {
  //     console.error(error);
  //     // Handle the error
  //   }
  // };

  // // This useEffect will trigger the getFavorites function whenever userToken.favorites changes
  // useEffect(() => {
  //   getFavorites();
  // }, []);
  const [favoriteAdDetails, setFavoriteAdDetails] = useState([]);
  const formatShopNameForURL = shopName => {
    // Replace spaces with hyphens and convert to lowercase
    return shopName.replace(/\s+/g, '-').toLowerCase();
  };
  // Fetch details of favorite ads and store them in the state
  useEffect(() => {
    const fetchFavoriteAdDetails = async () => {
      const detailsPromises = listFavorites.map(async favoriteId => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/ads/ad/${favoriteId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          return response.data;
        } catch (error) {
          console.error(error);
          // Handle the error
        }
      });

      const adDetails = await Promise.all(detailsPromises);
      setFavoriteAdDetails(adDetails);
    };

    fetchFavoriteAdDetails();
    console.log(listFavorites);

    console.log(favoriteAdDetails);
  }, [listFavorites]);
  const favoriteItems = favoriteAdDetails.map(adDetails => (
    <HistoryItem
      key={adDetails?._id} // Assuming each ad has a unique ID
      name={adDetails?.name}
      author={`${adDetails?.firstName} ${adDetails?.lastName}`}
      date={moment(adDetails?.createdAt).fromNow()} // You can update this with the actual date logic
      image={adDetails?.adPictures[0]}
      price={adDetails?.price}
      click={() => history.push(`/ads/${adDetails?._id}`)} // Define the click action here
    />
  ));

  useEffect(() => {
    fetchNotificationsCount();
  }, []);
  const fetchMessagesCount = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/notifications/messages/${userToken._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const num = res.data;
      setMessagesCount(num.messageCount);
    } catch (err) {}
  };
  useEffect(() => {
    fetchMessagesCount();
  }, [messagesCount]);
  const handleNotificationClickReadAll = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API}/notifications/read/${userToken._id}`,
        { read: true },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      history.go(0);

      // You could also update the list of notifications to mark them as read
    } catch (err) {
      // Handle error
    }
  };
  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: -10 },
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const logout = e => {
    localStorage.removeItem('user-token');
    localStorage.removeItem('token');
    localStorage.removeItem('user-img');
    history.push('/auth/sign-in');
    history.go(0);
  };
  let timeOfDay;
  const date = new Date();
  const hours = date.getHours();

  if (hours < 12) {
    timeOfDay = t('Bonjour');
  } else if (hours >= 12 && hours < 17) {
    timeOfDay = t('Bonjour');
  } else {
    timeOfDay = t('Bonsoir');
  }

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const storedImage = localStorage.getItem('user-img');
    if (storedImage) {
      setImageSrc(storedImage);
    }
  }, []);

  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <Flex
    
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <Flex align="center" display={{ base: 'none', xl: 'flex' }}>
        {' '}
        <Text
          fontSize={{ base: 'lg', xl: '17px' }}
          fontWeight="bold"
          textAlign="center"
          px="10px"
        >
          {t('Téléchargez notre app')}
        </Text>
        <HStack spacing={5} px={5} alignItems="flex-start">
          <Box
            position="relative"
            w={size}
            h={size}
            _before={{
              content: "''",
              position: 'relative',
              display: 'block',
              width: '300%',
              height: '300%',
              boxSizing: 'border-box',
              marginLeft: '-100%',
              marginTop: '-100%',
              borderRadius: '50%',
              bgColor: color,
              animation: `2.25s ${pulseRing} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
            }}
          >
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.marocapps.jumatik.sellandbuy"
            >
              <IconButton
                position="absolute"
                top={0}
                aria-label="PlayStore"
                variant="action"
                size="md"
                icon={<FaGooglePlay size="25px" />}
              />
            </Link>{' '}
          </Box>
          <Box
            position="relative"
            w={size}
            h={size}
            _before={{
              content: "''",
              position: 'relative',
              display: 'block',
              width: '300%',
              height: '300%',
              boxSizing: 'border-box',
              marginLeft: '-100%',
              marginTop: '-100%',
              borderRadius: '50%',
              bgColor: color2,
              animation: `2.25s ${pulseRing} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
            }}
          >
            <IconButton
              position="absolute"
              top={0}
              aria-label="AppStore"
              variant="action"
              size="md"
              icon={<FaAppStoreIos size="25px" />}
            />
          </Box>
        </HStack>
      </Flex>
      <Flex px={2}>
        <LanguageSwitcher />
      </Flex>
      <SearchBar
        mb={secondary ? { base: '10px', md: 'unset' } : 'unset'}
        me="10px"
        borderRadius="30px"
      />
      <SidebarResponsive routes={routes} />
      {userToken ? (
        <React.Fragment>
          {' '}
          <Menu>
            <MenuButton p="0px">
              <Box position="relative">
                <Icon
                  mt="6px"
                  as={MdOutlineChat}
                  color={navbarIcon}
                  w="22px"
                  h="22px"
                  me="10px"
                />
                <Text
                  color={notification}
                  fontSize="0.6em"
                  fontWeight="bold"
                  px={1}
                  w={5}
                  position="absolute"
                  right="0"
                  top="-3"
                >
                  {messagesCount === 0
                    ? null
                    : messagesCount > 9
                    ? '+9'
                    : messagesCount}
                </Text>{' '}
              </Box>
            </MenuButton>{' '}
            <MenuList
              boxShadow={shadow}
              p="20px"
              borderRadius="20px"
              bg={menuBg}
              border="none"
              mt="22px"
              me={{ base: '30px', md: 'unset' }}
              minW={{ base: 'unset', md: '400px', xl: '450px' }}
              maxW={{ base: '360px', md: 'unset' }}
            >
              <Flex jusitfy="space-between" w="100%" mb="20px">
                <Text fontSize="md" fontWeight="600" color={textColor}>
                  {t('Boite de messagerie')}{' '}
                </Text>{' '}
              </Flex>{' '}
              <Flex
                flexDirection="column"
                overflowY="scroll"
                overflowX="hidden"
              >
                <SearchBar
                  w="100%"
                  mb={secondary ? { base: '15px', md: '15px' } : '15px'}
                  me="15px"
                  borderRadius="30px"
                />

                <NewMessage currentUserId={userToken._id} />
              </Flex>{' '}
            </MenuList>{' '}
          </Menu>
          <Menu>
            <MenuButton p="0px">
              <Box position="relative">
                <Icon
                  mt="6px"
                  as={favoriteItems.length > 0 ? MdFavorite : MdFavoriteBorder}
                  color={navbarIcon}
                  w="22px"
                  h="22px"
                  me="10px"
                />
                <Text
                  color={notification}
                  fontSize="0.6em"
                  fontWeight="bold"
                  px={1}
                  w={5}
                  position="absolute"
                  right="0"
                  top="-3"
                >
                  {favoriteItems.length === 0
                    ? null
                    : favoriteItems.length > 99
                    ? '+99'
                    : favoriteItems.length}{' '}
                </Text>
              </Box>
            </MenuButton>{' '}
            <MenuList
              boxShadow={shadow}
              pr="25px"
              borderRadius="20px"
              bg={menuBg}
              border="none"
              mt="22px"
              me={{ base: '30px', md: 'unset' }}
              minW={{ base: 'unset', md: '400px', xl: '450px' }}
              maxW={{ base: '360px', md: 'unset' }}
            >
              <Grid templateColumns="repeat(5, 1fr)" pl={5}>
                <GridItem colSpan={2} h="10" display="flex" alignItems="center">
                  <Text fontSize="md" fontWeight="600" color={textColor}>
                    {t('Mes annonces favorites')}
                  </Text>
                </GridItem>
              </Grid>
              {favoriteAdDetails.length === 0 ? (
                <>
                  {' '}
                  <Box w="100%" display="flex" justifyContent="center" mb={2}>
                    <Flex direction="row">
                      {' '}
                      <Icon
                        as={MdOutlineNotificationsPaused}
                        color="grey"
                        w={14}
                        h={14}
                      />
                    </Flex>
                  </Box>
                  <Box w="100%" display="flex" justifyContent="center">
                    <Flex direction="row" align="center">
                      <Text
                        mb="5px"
                        fontWeight="regular"
                        color="grey"
                        fontSize={{ base: 'md', md: 'md' }}
                      >
                        Désolé, pas d'annonces favorites à l'horizons
                      </Text>
                    </Flex>
                  </Box>
                </>
              ) : (
                <Box maxHeight="300px" overflowY="scroll" overflowX="hidden">
                  {favoriteItems}
                </Box>
              )}{' '}
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton p="0px">
              <Box position="relative">
                <Icon
                  mt="6px"
                  as={MdNotificationsNone}
                  color={navbarIcon}
                  w="22px"
                  h="22px"
                  me="10px"
                />
                <Text
                  color={notification}
                  fontSize="0.6em"
                  fontWeight="bold"
                  px={1}
                  w={5}
                  position="absolute"
                  right="0"
                  top="-3"
                >
                  {notificationCount === 0
                    ? null
                    : notificationCount > 9
                    ? '+9'
                    : notificationCount}{' '}
                </Text>{' '}
              </Box>
            </MenuButton>{' '}
            <MenuList
              boxShadow={shadow}
              p="20px"
              borderRadius="20px"
              bg={menuBg}
              border="none"
              mt="22px"
              me={{ base: '30px', md: 'unset' }}
              minW={{ base: 'unset', md: '400px', xl: '450px' }}
              maxW={{ base: '360px', md: 'unset' }}
            >
              <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                <GridItem colSpan={2} h="10" display="flex" alignItems="center">
                  <Text fontSize="md" fontWeight="600" color={textColor}>
                    {t('Notifications')}
                  </Text>
                </GridItem>
                <GridItem
                  colStart={6}
                  colEnd={6}
                  h="10"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Popover placement="bottom" isLazy>
                    <PopoverTrigger>
                      <IconButton
                        aria-label="More server options"
                        icon={<BsThreeDotsVertical />}
                        variant="solid"
                        w="fit-content"
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      w="fit-content"
                      _focus={{ boxShadow: 'none' }}
                    >
                      <PopoverArrow />
                      <PopoverBody>
                        <Stack>
                          <Button
                            w="194px"
                            variant="ghost"
                            rightIcon={<BsChatSquareQuote />}
                            justifyContent="space-between"
                            fontWeight="normal"
                            onClick={handleNotificationClickReadAll}
                            fontSize="sm"
                          >
                            Read All
                          </Button>
                        </Stack>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </GridItem>
              </Grid>
              <AllNotification currentUserId={userToken._id} />{' '}
            </MenuList>
          </Menu>
          {userToken.ProPart === 'pro' &&  <Menu>
            <MenuButton p="0px">
              <Box position="relative">
                <Icon
                  mt="6px"
                  as={FaWallet} // Utilisez l'icône de portefeuille ici
                  color={navbarIcon}
                  w="22px"
                  h="22px"
                  me="10px"
                />
                {/* ... (compteur de portefeuille similaire à celui des notifications) */}
              </Box>
            </MenuButton>
            <MenuList boxShadow={shadow}
              p="20px"
              borderRadius="20px"
              bg={menuBg}
              border="none"
              mt="22px"
              me={{ base: '30px', md: 'unset' }}
              minW={{ base: 'unset', md: '400px', xl: '450px' }}
              maxW={{ base: '360px', md: 'unset' }}>
    
              {userToken.ProPart === 'pro' && (
                <MenuItem bg={menuBg}>
                 
                  <Box p="4">
                    <Text mb={2} fontSize="xl">Portefeuille</Text>
                    <Flex>
                      <Text mr="2">Crédits disponibles:</Text>
                      <Text  fontSize="lg" fontWeight="700">{userToken.credit} Crédits</Text>
                    </Flex>
                 
                  </Box>
                </MenuItem>
              )}
            </MenuList>
          </Menu>}
         
        </React.Fragment>
      ) : (
        <Menu>
          <MenuButton p="0px">
            <Icon
              mt="6px"
              as={MdPerson}
              color={icon}
              w="22px"
              h="22px"
              me="10px"
            />
          </MenuButton>{' '}
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋 &nbsp; {t('Bienvenu sur JUMATIK')}{' '}
              </Text>{' '}
            </Flex>{' '}
            <Flex flexDirection="column" p="10px">
              <NavLink to="/auth/login">
                <MenuItem
                  _hover={{ bg: bgM }}
                  bg={menuBg}
                  borderRadius="8px"
                  px="14px"
                >
                  <Icon as={FiUser} boxSize={5} mr="2" />
                  <Text fontSize="sm"> {t('Se connecter')}</Text>
                </MenuItem>
              </NavLink>
              <NavLink to="/auth/register">
                <MenuItem
                  _hover={{ bg: bgM }}
                  bg={menuBg}
                  borderRadius="8px"
                  px="14px"
                >
                  <Icon as={FiSettings} boxSize={5} mr="2" />
                  <Text fontSize="sm"> {t(`S'enregistrer`)} </Text>
                </MenuItem>
              </NavLink>
              <Link href="mailto:jumatikmaroc@gmail.com" target="_blank">
                <MenuItem
                  _hover={{ bg: bgM }}
                  bg={menuBg}
                  borderRadius="8px"
                  px="14px"
                >
                  <Icon as={FiHelpCircle} boxSize={5} mr="2" />
                  <Text fontSize="sm"> {t(`Support`)} </Text>
                </MenuItem>
              </Link>{' '}
            </Flex>{' '}
          </MenuList>{' '}
        </Menu>
      )}
      <Menu>
        <MenuButton onClick={toggleColorMode} p="0px">
          <Icon
            onClick={toggleColorMode}
            mt="6px"
            as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
            color={navbarIcon}
            w="22px"
            h="22px"
            me="10px"
          />
        </MenuButton>{' '}
      </Menu>
      {userToken && (
        <Menu>
          <MenuButton p="0px" onClick={toggleMenu}>
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              src={userToken.profilePicture}
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow="md"
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            opacity={isMenuOpen ? 1 : 0}
            visibility={isMenuOpen ? 'visible' : 'hidden'}
            transformOrigin="top"
            transform={isMenuOpen ? 'scaleY(1)' : 'scaleY(0.95)'}
            transition="transform 0.2s ease-in-out"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor="gray.200"
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋 &nbsp; {timeOfDay}, &nbsp; {userToken.firstName}{' '}
              </Text>
            </Flex>
            <Box mt={2} pl="20px">
              {userToken.ProPart === 'pro' ? (
                <Badge
                  colorScheme={
                    userToken.memberShip === 'Débutant '
                      ? 'green'
                      : userToken.memberShip === 'Professionnel'
                      ? 'yellow'
                      : 'red'
                  }
                  fontSize="sm"
                  mr="2"
                  borderRadius="md"
                  px="2"
                  py="1"
                >
                  Compte {userToken.memberShip}
                </Badge>
              ) : (
                <Badge
                  colorScheme="blue"
                  fontSize="sm"
                  mr="2"
                  borderRadius="md"
                  px="2"
                  py="1"
                >
                  Compte gratuit
                </Badge>
              )}
            </Box>
            <Flex flexDirection="column" px="10px">
              <NavLink to="/admin/profile">
                <MenuItem
                  _hover={{ bg: bgM }}
                  bg={menuBg}
                  borderRadius="8px"
                  px="14px"
                >
                  <Icon as={FiUser} boxSize={5} mr="2" />
                  <Text fontSize="sm"> {t(`Afficher mon profil`)} </Text>
                </MenuItem>
              </NavLink>

              {userToken.ProPart === 'pro' && userToken.idShop && (
                <NavLink
                  to={`/shop/shop/${formatShopNameForURL(userToken.shop)}`}
                >
                  <MenuItem
                    _hover={{ bg: bgM }}
                    bg={menuBg}
                    borderRadius="8px"
                    px="14px"
                  >
                    <Icon as={FiShoppingCart} boxSize={5} mr="2" />
                    <Text fontSize="sm">
                      {' '}
                      {t(`Afficher ma boutique`)}{' '}
                    </Text>{' '}
                    <Badge
                      ml={1}
                      colorScheme="green"
                      fontSize="xs"
                      mr="2"
                      borderRadius="md"
                      px="2"
                      py="1"
                    >
                      NEW
                    </Badge>
                  </MenuItem>
                </NavLink>
              )}
              <NavLink to={`/admin/memberships`}>
                <MenuItem
                  _hover={{ bg: bgM }}
                  bg={menuBg}
                  borderRadius="8px"
                  px="14px"
                >
                  <Icon as={MdCardMembership} boxSize={5} mr="2" />
                  <Text fontSize="sm"> {t(`Abonnements boutique`)} </Text>{' '}
                  <Badge
                    ml={1}
                    colorScheme="green"
                    fontSize="xs"
                    mr="2"
                    borderRadius="md"
                    px="2"
                    py="1"
                  >
                    NEW
                  </Badge>
                </MenuItem>
              </NavLink>

              {userToken.ProPart === 'pro' && !userToken.idShop && (
                <NavLink to="/shop/create">
                  <MenuItem
                    _hover={{ bg: bgM }}
                    bg={menuBg}
                    borderRadius="8px"
                    px="14px"
                  >
                    <Icon as={FiShoppingCart} boxSize={5} mr="2" />
                    <Text fontSize="sm" fontWeight={600}>
                      {' '}
                      {t(`Créer ma boutique`)}{' '}
                    </Text>{' '}
                    <Badge
                      ml={1}
                      colorScheme="green"
                      fontSize="xs"
                      mr="2"
                      borderRadius="md"
                      px="2"
                      py="1"
                    >
                      NEW
                    </Badge>
                  </MenuItem>
                </NavLink>
              )}

              <NavLink to="/register/updateUser">
                <MenuItem
                  _hover={{ bg: bgM }}
                  bg={menuBg}
                  borderRadius="8px"
                  px="14px"
                >
                  <Icon as={FiSettings} boxSize={5} mr="2" />
                  <Text fontSize="sm"> {t(`Modifier mon profil`)} </Text>
                </MenuItem>
              </NavLink>
              <Link href="mailto:jumatikmaroc@gmail.com" target="_blank">
                <MenuItem
                  _hover={{ bg: bgM }}
                  bg={menuBg}
                  borderRadius="8px"
                  px="14px"
                >
                  <Icon as={FiHelpCircle} boxSize={5} mr="2" />
                  <Text fontSize="sm"> {t(`Support`)} </Text>
                </MenuItem>
              </Link>
              <MenuItem
                _hover={{ bg: bgM }}
                bg={menuBg}
                onClick={logout}
                borderRadius="8px"
                px="14px"
              >
                <Icon as={FiLogOut} boxSize={5} mr="2" />
                <Text fontSize="sm"> {t(`Déconnexion`)} </Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      )}{' '}
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
