//Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  Text,
  SimpleGrid,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
// Assets
import Card from 'components/card/Card.js';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React, { useEffect, useState } from 'react';
import {
  MdIntegrationInstructions,
  MdCategory,
  MdBarChart,
  MdPeople,
  MdAssignmentTurnedIn,
  MdWorkspacePremium,
  MdShoppingBasket,
  MdCheck,
  MdShoppingBag 
} from 'react-icons/md';
import CheckTable from 'views/admin/default/components/CheckTable';
import Orders from 'views/admin/default/components/Orders';
import SubscriptionsTable from 'views/admin/default/components/SubscriptionsTable';
import SubcategoriesTable from 'views/admin/default/components/SubcategoriesTable';
import SubscriptionsConfig from 'views/admin/default/components/SubscriptionsConfig';
import SecteursTable from 'views/admin/default/components/SecteursTable';
import TableUsers from 'views/admin/default/components/TableUsers';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import SpecialFields from 'views/admin/default/components/SpecialFields';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import SubcategoryConfig from 'views/admin/default/components/SubcategoryConfig';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import {
  columnsDataCheck,
  columnsDataComplex,
  columnsDataUsers,
  columnsSubcategories,
  columnsSecteurs,
  columnsDataSubs,
} from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import AdsData from 'views/admin/default/variables/AdsData';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';
import axios from 'axios';
import { FaUsers, FaCheck, FaTimes, FaUserShield } from 'react-icons/fa';
import { AiOutlineFileSearch } from 'react-icons/ai';
export default function UserReports() {
  // Chakra Color Mode
  const [totalsuers, setTotalsuers] = useState(0);
  const [totaladmins, setTotaladmins] = useState(0);
  const [totalads, setTotalads] = useState(0);
  const [totalvalidads, setTotalvalidads] = useState(0);
  const [totalcanceledads, setTotalcanceledads] = useState(0);
  const [totalUndervalidationads, setTotalundervalidationads] = useState(0);


  const brandColor = useColorModeValue('brand.500', 'white');
  const validColor = useColorModeValue('green.600', 'green.200');
  const cancelColor = useColorModeValue('red.500', 'red.200');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const validBg = useColorModeValue('green.100', 'whiteAlpha.100');
  const cancelBg = useColorModeValue('red.100', 'whiteAlpha.100');
  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/get/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );


      const users = response.data;
      // Total number of users
      const totalUsers = users.length;
      setTotalsuers(totalUsers);

      
      // Filter for admins
      const admins = users.filter(user => user.isAdmin === true);

      // Total number of admins
      const totalAdmins = admins.length;
      setTotaladmins(totalAdmins);
    } catch (error) {
      console.log(error);

    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchAds() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/admin/allAds/total`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const ads = response.data;
      // Total number of users
      const totalAds = ads.length;

      // Filter for admins
      const undervalidationAds = ads.filter(ad => ad.status === 'En cours de Validation');
      const validAds = ads.filter(ad => ad.status === 'Validée');
      const canceledAds = ads.filter(ad => ad.status === 'Annulée');

      const totalValidAds = validAds.length;
      const totalCanceledAds = canceledAds.length;
      const totalUndervalidationAds = undervalidationAds.length;
      

      setTotalads(totalAds);
      setTotalcanceledads(totalCanceledAds);
      setTotalundervalidationads(totalUndervalidationAds);
      setTotalvalidads(totalValidAds);

    


    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchAds();
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px" mb={5}>
        <MiniCalendar h="100%" minW="100%" selectRange={false} />
        <Box minH="100%" minW="100%">
  <Box mb={5}>
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={FaUsers}
              color={brandColor}
            />
          }
        />
      }
      name="Nbr d'utilisateurs"
      value={totalsuers}
    />
  </Box>
  <Box mb={5}>
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={MdShoppingBag}
              color={brandColor}
            />
          }
        />
      }
      name="Nbr total d'annonces"
      value={totalads}
    />
  </Box>
  <Box mb={5}>
    <MiniStatistics
    
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={validBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={FaCheck}
              color={validColor}
            />
          }
        />
      }
      name="Nbr d'annonces valides"
      value={totalvalidads}
    />
  </Box>
</Box>
<Box minH="100%" minW="100%">
  <Box mb={5}>
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={FaUserShield}
              color={brandColor}
            />
          }
        />
      }
      name="Nbr d'admins"
      value={totaladmins}
    />
  </Box>
  <Box mb={5}>
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={boxBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={MdAssignmentTurnedIn}
              color={brandColor}
            />
          }
        />
      }
      name="Nbr d'annonces en validation"
      value={totalUndervalidationads}
    />
  </Box>
  <Box mb={5}>
    <MiniStatistics
      startContent={
        <IconBox
          w="56px"
          h="56px"
          bg={cancelBg}
          icon={
            <Icon
              w="32px"
              h="32px"
              as={FaTimes}
              color={cancelColor}
            />
          }
        />
      }
      name="Nbr d'annonces annules"
      value={totalcanceledads}
    />
  </Box>
</Box>
      </SimpleGrid>
      <Card>
        <Accordion borderRadius={15} allowMultiple>
          <AccordionItem>
            <AccordionButton
              _expanded={{ bg: boxBg, color: brandColor }}
              borderRadius={15}
            >
              <Box flex="1" textAlign="left">
                <Flex>
                  <Icon
                    color={brandColor}
                    mt={1}
                    as={MdAssignmentTurnedIn}
                    boxSize={10}
                    mr={4}
                  />

                  <Box flex="1" textAlign="left">
                    <Text fontSize={20} fontWeight={600}>
                      Annonces en cours de validation
                    </Text>
                    <Text
                      fontSize={14}
                      color="secondaryGray.600"
                      fontWeight={200}
                    >
                      Gérez facilement les annonces en attente de validation:
                      validez ou annulez-les en toute simplicité
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Box mt={5}>
                <CheckTable columnsData={columnsDataCheck} />
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              _expanded={{ bg: boxBg, color: brandColor }}
              borderRadius={15}
            >
              <Box flex="1" textAlign="left">
                <Flex>
                  <Icon
                    mt={1}
                    color={brandColor}
                    as={MdPeople}
                    boxSize={10}
                    mr={4}
                  />

                  <Box flex="1" textAlign="left">
                    <Text fontSize={20} fontWeight={600}>
                      Gestion des utilisateurs
                    </Text>
                    <Text
                      fontSize={14}
                      color="secondaryGray.600"
                      fontWeight={200}
                    >
                      Effectuez une gestion aisée des utilisateurs
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Box mt={5}>
                <TableUsers columnsData={columnsDataUsers} />
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              _expanded={{ bg: boxBg, color: brandColor }}
              borderRadius={15}
            >
              <Box flex="1" textAlign="left">
                <Flex>
                  <Icon
                    color={brandColor}
                    mt={1}
                    as={MdCategory}
                    boxSize={10}
                    mr={4}
                  />

                  <Box flex="1" textAlign="left">
                    <Text fontSize={20} fontWeight={600}>
                      Configuration des sous-catégories et des secteurs
                    </Text>
                    <Text
                      fontSize={14}
                      color="secondaryGray.600"
                      fontWeight={200}
                    >
                      Configuration intuitive des sous-catégories et des
                      secteurs. Adaptez votre contenu selon vos besoins
                      spécifiques
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Box mt={5}>
                <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px">
                  <SubcategoryConfig />
                </SimpleGrid>
              </Box>
              <Box mb={5}>
                <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px">
                  <SubcategoriesTable columnsData={columnsSubcategories} />
                  <SecteursTable columnsData={columnsSecteurs} />
                </SimpleGrid>
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton
              _expanded={{ bg: boxBg, color: brandColor }}
              borderRadius={15}
            >
              <Box flex="1" textAlign="left">
                <Flex>
                  <Icon
                    color={brandColor}
                    mt={1}
                    as={MdIntegrationInstructions}
                    boxSize={10}
                    mr={4}
                  />

                  <Box flex="1" textAlign="left">
                    <Text fontSize={20} fontWeight={600}>
                      Configuration des champs et sous-champs spéciaux
                    </Text>
                    <Text
                      fontSize={14}
                      color="secondaryGray.600"
                      fontWeight={200}
                    >
                      Configuration intuitive des champs et sous-champs
                      spéciaux. Adaptez votre contenu selon vos besoins
                      spécifiques
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Box mt={5}>
                <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px">
                  <SpecialFields />
                </SimpleGrid>
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              _expanded={{ bg: boxBg, color: brandColor }}
              borderRadius={15}
            >
              <Box flex="1" textAlign="left">
                <Flex>
                  <Icon
                    color={brandColor}
                    mt={1}
                    as={MdWorkspacePremium}
                    boxSize={10}
                    mr={4}
                  />

                  <Box flex="1" textAlign="left">
                    <Text fontSize={20} fontWeight={600}>
                      Gestion des plans d'annonces
                    </Text>
                    <Text
                      fontSize={14}
                      color="secondaryGray.600"
                      fontWeight={200}
                    >
                      Ajoutez de nouveaux abonnements, mettez à jour les options
                      existantes et assurez-vous d'une gestion optimale des
                      plans publicitaires
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Box mt={5}>
                <SubscriptionsTable columnsData={columnsDataSubs} />
                <SubscriptionsConfig />
              </Box>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              _expanded={{ bg: boxBg, color: brandColor }}
              borderRadius={15}
            >
              <Box flex="1" textAlign="left">
                <Flex>
                  <Icon
                    color={brandColor}
                    mt={1}
                    as={MdShoppingBasket}
                    boxSize={10}
                    mr={4}
                  />

                  <Box flex="1" textAlign="left">
                    <Text fontSize={20} fontWeight={600}>
                      Gestion des commandes
                    </Text>
                    <Text
                      fontSize={14}
                      color="secondaryGray.600"
                      fontWeight={200}
                    >
                      Suivez, gérez et traitez les commandes des utilisateurs
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <Box mt={5}>
                <Orders />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Card>
    </Box>
  );
}
