import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Badge,
  Box,
  HStack,
  keyframes,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Stack,
  Grid,
  GridItem,
  Tooltip 
} from '@chakra-ui/react';
import { t } from 'helpers/TransWrapper';
import Card from 'components/card/Card.js';
import { FaCheckCircle } from 'react-icons/fa';
// Custom components
import { NavLink, useHistory, useParams } from 'react-router-dom';

import { Skeleton } from '@chakra-ui/react';
// Assets

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Header } from 'semantic-ui-react';
import {
  MdBatteryCharging30,
  MdBatteryCharging60,
  MdBatteryCharging100,
} from 'react-icons/md';
const user = JSON.parse(localStorage.getItem('user-token'));

export default function MembershipPlan({
  icon,
  price,
  backgroundColor,
  textColorSecondary,
  mainColor,
  name,
  descriptionShort,
  descriptionLongue,
  Color_title,
  billingCycle,
  popular,
  btnColor,
  nbrAds,
  shopType,
  support,
  admins,
  analytics,
  credit,
  totalPrice
}) {
  const popul = useColorModeValue('white', 'brand.500');
  const populText = useColorModeValue('brand.500', 'white');
  const shadow = useColorModeValue(
    '5px 5px 2px 2px  rgba(0, 0, 0, 0.1)',
    '5px 5px 2px 2px rgba(255, 255, 255, 0.1)'
  );
  //const textColorSecondary = useColorModeValue('gray.900', 'gray.400');
  const history = useHistory();
  const parseNumber = (str) => {
    if (str.includes('k')) {
      return parseFloat(str) * 1000;
    }
    return parseFloat(str);
  };
  const handleShopOrder = async () => {
    try {
      const response = await axios.post(
        'https://jindoo.ma:6001/shoporders/shoporder',
        {
          idUser: user._id,
          price: price,
          memberShip: name,
          billingCycle: billingCycle,
          descriptionShort: descriptionShort,
          totalPrice: totalPrice,
          credit: parseNumber(credit),
          nbrAds: parseNumber(nbrAds),
          nbrAdmins: admins,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('shoporder', response.data.shopOrder.generatedId); // Check the response from the server

      // Assuming the order was created successfully, redirect to the cart page
      history.push(`/ad/shoporder/${response.data.shopOrder.generatedId}`);
    } catch (error) {
      // Handle any errors that occur during the HTTP request
      console.error('An error occurred:', error);
    }
  };
  return (
    <Card backgroundColor={backgroundColor} boxShadow={shadow}>
      {popular && (
        <Box
          position="absolute"
          top="15px"
          right="15px"
          backgroundColor={popul} // Replace with your badge color
          color={populText} // Replace with your badge text color
          padding="8px"
          borderRadius="md"
          fontSize="sm"
        >
          BEST SELLER {/* Replace with "Best Seller" or any text you prefer */}
        </Box>
      )}

      <Flex>
        <Box color={Color_title}>
          <Icon boxSize="60px" as={icon} />
        </Box>
        <Box textAlign="start">
          <Text color={textColorSecondary} fontSize="sm">
            {descriptionShort}
          </Text>
          <Text color={mainColor} fontWeight="bold" fontSize="lg" mb="4px">
            {name}
          </Text>
        </Box>
      </Flex>
      <Box px={5} mt={2} textAlign="start">
        <Text color={textColorSecondary} fontSize="sm">
          {descriptionLongue}
        </Text>
        <Flex>
          <Text color={mainColor} fontSize="3xl" fontWeight="bold">
            MAD{price}
          </Text>
          <Text mt={3} ml={2} fontSize="md" color={mainColor}>
            /mois
          </Text>
        </Flex>
        <Box mt={4}>
  <Text color={mainColor} fontWeight="bold" mb={2} fontSize="md">
    Ce qui est inclus :
  </Text>

  {/* Boutique */}
  <Flex align="center" mt={4}>
    <Box color={Color_title}>
      <FaCheckCircle size={20} />
    </Box>
    <Tooltip label={`Créez une boutique pour afficher et vendre jusqu'à ${nbrAds} annonces.`} placement="top">
      <Text ml={2} color={textColorSecondary}>
        Boutique <strong>{shopType} : {nbrAds} Annonces</strong>
      </Text>
    </Tooltip>
  </Flex>

  {/* Support Client */}
  <Flex align="center" mt={4}>
    <Box color={Color_title}>
      <FaCheckCircle size={20} />
    </Box>
    <Tooltip label={`Accédez à un support client ${support} pour obtenir de l'aide rapide.`} placement="top">
      <Text ml={2} color={textColorSecondary}>
        Support Client <strong>{support}</strong>
      </Text>
    </Tooltip>
  </Flex>

  {/* Jusqu'à {admins} admin */}
  <Flex align="center" mt={4}>
    <Box color={Color_title}>
      <FaCheckCircle size={20} />
    </Box>
    <Tooltip label={`Ajoutez jusqu'à ${admins} administrateurs pour gérer votre boutique.`} placement="top">
      <Text ml={2} color={textColorSecondary}>
        Jusqu'à <strong>{admins}</strong> admins
      </Text>
    </Tooltip>
  </Flex>

  {/* Analytiques */}
  <Flex align="center" mt={4}>
    <Box color={Color_title}>
      <FaCheckCircle size={20} />
    </Box>
    <Tooltip label={`Consultez des statistiques avancées avec les analytiques ${analytics}.`} placement="top">
      <Text ml={2} color={textColorSecondary}>
        Analytiques <strong>{analytics}</strong>
      </Text>
    </Tooltip>
  </Flex>

  {/* Crédits de Promotion inclus */}
  <Flex align="center" mt={4}>
    <Box color={Color_title}>
      <FaCheckCircle size={20} />
    </Box>
    <Tooltip label={`Utilisez les crédits de promotion pour mettre en avant vos annonces. Vous disposez de ${credit} crédits inclus.`} placement="top">
      <Text ml={2} color={textColorSecondary}>
        Crédits de Promotion inclus : <strong>{credit} Crédits</strong>
      </Text>
    </Tooltip>
  </Flex>

  {/* Ajoutez plus de composants Flex avec des icônes et des fonctionnalités incluses */}
</Box>
        <Button
          onClick={handleShopOrder}
          mt={6}
          w="100%"
          variant="solid"
          bg={Color_title}
          color={btnColor}
          _hover={{
            _before: {
              borderRadius: '15px',
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)', // Adjust the opacity or color as needed
              zIndex: 1,
            },
          }}
        >
          Commencer
        </Button>{' '}
        {/* Placeholder for the "Get Started" button */}
      </Box>
    </Card>
  );
}
