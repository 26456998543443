import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useColorMode } from "@chakra-ui/react";
import { Skeleton } from '@chakra-ui/react';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Grid,
  InputLeftElement,
  Select,
  InputLeftAddon,
} from '@chakra-ui/react';
// Custom components
import Banner from 'views/admin/marketplace/components/Banner';
import TableTopCreators from 'views/admin/marketplace/components/TableTopCreators';
import HistoryItem from 'views/admin/marketplace/components/HistoryItem';
import NFT from 'components/card/NFT';
import Card from 'components/card/Card.js';

// Assets
import Nft1 from 'assets/img/nfts/Nft1.png';
import Nft2 from 'assets/img/nfts/Nft2.png';
import Nft3 from 'assets/img/nfts/Nft3.png';
import Nft4 from 'assets/img/nfts/Nft4.png';
import Nft5 from 'assets/img/nfts/Nft5.png';
import Nft6 from 'assets/img/nfts/Nft6.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import { AiOutlineIdcard, AiOutlineOrderedList } from 'react-icons/ai';
import { MdSearch, MdCategory, MdLocationPin } from 'react-icons/md';

import tableDataTopCreators from 'views/admin/marketplace/variables/tableDataTopCreators.json';
import { tableColumnsTopCreators } from 'views/admin/marketplace/variables/tableColumnsTopCreators';
import AdAsList from 'views/ads/recentAds/components/AdAsList';
import { SearchIcon } from '@chakra-ui/icons';
import { t } from 'helpers/TransWrapper';
import moment from 'moment';

export default function CategoryDetails() {
  // Chakra Color Mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);


  const textColorBrand = useColorModeValue('brand.800', 'white');
  const [ads, setAds] = useState([]);
  const [SelectedAd, setSelectedAd] = useState([]);
  const history = useHistory();
  const [showLList, setshowList] = useState(false);
  const [showCard, setshowCard] = useState(true);
 
  const [category, setCategory] = useState();

  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await axios.get(`${process.env.REACT_APP_API}/categories/category/${id}`);
        setCategory(categoryResponse.data);
        const adsResponse = await axios.get(`${process.env.REACT_APP_API}/ads/category/${id}`);
        setAds(adsResponse.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);
  const getAdById = async id => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/ads/ad/${id}`);
      setSelectedAd(response.data);

      history.push(`/ads/${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const getLikers = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${id}/like`
      );
  
      const likerIds = response.data;
  
      const likerData = [];
  
      for (const likerId of likerIds) {
        const userDataResponse = await axios.get(
          `${process.env.REACT_APP_API}/users/${likerId}`
        );
        const userData = userDataResponse.data;
        likerData.push(userData);
  
        console.log("User Data:", userData); // Log the user data for debugging
      }
  
      const profilePictures = likerData.map((liker) => liker.profilePicture);
  
      console.log("Profile Pictures:", profilePictures); // Log profile pictures for debugging
  
      return profilePictures;
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };
  const NFTWrapper = ({ ad, user }) => {
    const [likersForAd, setLikersForAd] = useState([]);
  
    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }
  
      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
      <NFT
        id={ad?._id}
        bidders={likersForAd}
        name={ad.name}
        author={`${ad.firstName} ${ad.lastName}`}
        image={ad.adPictures[0] && Object.keys(ad.adPictures[0]).length ? ad.adPictures[0] : Nft3}
        category={ad.categoryLabel}
        propart={user?.ProPart}
        currentbid={ad.price === 0 ? t('Non défini') : `${ad.price} MAD`}
        Click={handleClick}
        city={ad.city}
        dateCreated={moment(ad.createdAt).fromNow()}
        nbrPictures={`${ad.adPictures.length} images `}

      />
    );
  };
  const ListWrapper = ({ ad, user }) => {
    const [likersForAd, setLikersForAd] = useState([]);
  
    useEffect(() => {
      async function fetchLikers() {
        const likers = await getLikers(ad._id);
        setLikersForAd(likers);
      }
  
      fetchLikers();
    }, [ad._id]);
    const handleClick = () => {
      getAdById(ad._id);
    };
    return (
    
         <AdAsList
                        id={ad?._id}
                          title={ad.name}
                          category={ad.categoryLabel}
                          //link={ad.name}
                          price= {ad.price === "Non défini" ? "Non défini " : ad.price + " MAD"}
                          image={ ad.adPictures[0] && Object.keys(ad.adPictures[0]).length ? ad.adPictures[0] : Nft3}
                          city={ad.city}
                          bidders={likersForAd}
                          dateCreated={moment(ad.createdAt).fromNow()}
  
                        />
    );
  };
  const [test,setTest]=useState(true)

  // useEffect(() => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
  // }, []);

  // useEffect(() => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
    
  // }, [test]);
  const [isLoading, setIsLoading] = useState(false);

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const items = Array.from({ length: 12 });

  const { colorMode } = useColorMode();

  const bgColor = colorMode === 'dark' ? 'rgb(17, 28, 68)' : 'gray.200';

  return (
    
    <Box pt={{ base: '120px', md: '80px', xl: '80px' }}>
      {' '}
      {/* Main Fields */}{' '}
      {isLoading==false ? 
      <Flex mb="20px" flexDir="column">
        <Flex flexDirection="Column" w="100%">
          <Card mb={5}>
            <Flex>
            <Text
              color={textColorBrand}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              {category?.label}
            </Text>
            </Flex>{' '}
          </Card>
{ads.length > 0 ? <><Card mb={5}>
            <Flex>
              <Button
                mr={5}
                w={100}
                h={10}
                borderRadius={20}
                leftIcon={<AiOutlineIdcard />}
                colorScheme="teal"
                variant="solid"
                onClick={() => [setshowCard(true), setshowList(false),,setTest(true)]}
              >
                Cartes{' '}
              </Button>{' '}
              <Button
                w={100}
                h={10}
                borderRadius={20}
                leftIcon={<AiOutlineOrderedList />}
                colorScheme="pink"
                variant="solid"
                onClick={() => [setshowCard(false), setshowList(true),,setTest(false)]}
              >
                Listes{' '}
              </Button>{' '}
            </Flex>{' '}
          </Card>
          {showLList && ads && ads.length > 0
            ? ads.map(ad => {
                const handleClick = () => {
                  getAdById(ad._id); // Get the ad by its id when the component is clicked
                };
                
                return (
                  <Flex
                    mb={5}
                    key={ad._id}
                    onClick={handleClick}
                    cursor="pointer"
                  >
                                                <ListWrapper ad={ad}  />
{' '}
                  </Flex>
                );
              })
            : null}{' '}
          {showCard && (
            <SimpleGrid
              columns={{ base: 1, md: 4 }}
              gap="20px"
              mb={{ base: '20px', xl: '0px' }}
            >
              {' '}
              {ads && ads.length > 0
                ? ads.map(ad => {
                 
                    
                    return (

                      <Flex key={ad._id} className='nft'>
            <NFTWrapper ad={ad}  />
            
                        </Flex>
                    );
                  })
                : null}{' '}
            </SimpleGrid>
          )} </>:     
          <Card mb={5}>
            <Flex>
            <Text
              color={textColorBrand}
              fontWeight="regular"
              fontSize="xl"
              mt="10px"
              mb="4px"
            >
              Pas encore d'annonces
            </Text>
            </Flex>{' '}
          </Card> }
         
        </Flex>{' '}
        {/* <Flex flexDirection="Column" align="end">
                            <Card w={{ base: '100%', xl: '95%' }} p="0px" mb="20px">
                              <Flex
                                align={{ sm: 'flex-start', lg: 'center' }}
                                justify="space-between"
                                w="100%"
                                px="22px"
                                py="18px"
                              >
                                <Text color={textColor} fontSize="xl" fontWeight="600">
                                  Sponsorisées
                                </Text>
                                <Button variant="action">Voir plus</Button>
                              </Flex>

                              <HistoryItem
                                name="Annonce sponsorise 1"
                                author="achraf ait beni ifit"
                                image={Nft5}
                                price="1000 MAD"
                              />
                              <HistoryItem
                                name="Annonce sponsorise 1"
                                author="achraf ait beni ifit"
                                image={Nft5}
                                price="1000 MAD"
                              />
                              <HistoryItem
                                name="Annonce sponsorise 1"
                                author="achraf ait beni ifit"
                                image={Nft5}
                                price="1000 MAD"
                              />
                              <HistoryItem
                                name="Annonce sponsorise 1"
                                author="achraf ait beni ifit"
                                image={Nft5}
                                price="1000 MAD"
                              />
                            </Card>
                          </Flex> */}{' '}
      </Flex>
      :(
        <>
   
        {items.map((_, index) => {
          return (
            <Flex key={index} className='nft' mb={5} mt={5}>
              <Box borderRadius="lg" overflow="hidden" bg={bgColor}>
                <Skeleton
                  height={{ base: '200px', md: '200px' }}
                  width={test ? "300px" : "800px"}
   
                  startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
                  endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
                />
                {/* <Box height="" width="600px"></Box> */}
                <Box p="6">
                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                    color={textColor}
                  >
                    <Skeleton
                      height="10px"
                      width={{ base: '50%', md: '30%' }}
                      mb={2}
                      startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
                      endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
                    />
                    <Box bg={bgColor} height="20px" width="50%" mb={2}></Box>
                    <Skeleton
                      height="10px"
                      width={{ base: '20%', md: '10%' }}
                      mr={2}
                      startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
                      endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
                    />
                     <Box bg={bgColor} height="20px" width="50%" mb={2}></Box>
                     {/* {test ? null :
                    <Box position="absolute" top="80%" left="30%" transform="translate(-50%, -50%)">
           <Skeleton isLoaded={!isLoading} borderRadius="full" height="50px" width="50px">
           </Skeleton>
         </Box>} */}
                  </Box>
                  <Box d="flex" alignItems="baseline" color={textColor} fontSize="sm">
                    <Box bg={bgColor} height="10px" width="20%" mr={2}></Box>
                    <Box bg={bgColor} height="10px" width="30%" mr={2}></Box>
                  </Box>
                </Box>
              </Box>
            </Flex>
          );
        })}
      </>
         )
         
         
         }
    </Box>
  );
}
