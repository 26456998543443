import { createSlice } from "@reduxjs/toolkit";

const categoriesInitialState = {
  categories: [],
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: categoriesInitialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    // Add other category-related reducers here if needed
  },
});

// Export the actions generated by createSlice
export const { setCategories } = categoriesSlice.actions;

// Export the reducer generated by createSlice
export default categoriesSlice.reducer;
