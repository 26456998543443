// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
} from '@chakra-ui/react';
import { Watermark } from 'react-watermark-component';
import myWatermark from '../../assets/img/output-onlinepngtools (1).png';
import Nft3 from 'assets/img/nfts/Nft3.png';
import { FaHeart, FaHandPointer } from 'react-icons/fa';

// Custom components
import { NavLink, useHistory } from 'react-router-dom';
import { FaStoreAlt } from 'react-icons/fa';
import { FaUserTie } from 'react-icons/fa';
import Card from 'components/card/Card.js';
// Assets
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { MdLocationOn, MdAccessTimeFilled, MdImage } from 'react-icons/md';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFavorites } from '../../contexts/FavoritesContext';

const NFT = React.memo(props => {
  const { listFavorites, updateFavorites } = useFavorites();
  const watermarkStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 1,
    background: `url(${myWatermark}) center center no-repeat`,
    pointerEvents: 'none',
    padding: '20px',
    boxSizing: 'border-box',
  };
  const {
    image,
    name,
    isShopPromoted,
    bidders,
    category,
    Click,
    currentbid,
    city,
    dateCreated,
    id,
    discountShop,
    nbrPictures,
    propart,
    promotedPrice,
  } = props;
  const [like, setLike] = useState({});
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorBid = useColorModeValue('brand.500', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const currentUserS = localStorage.getItem('user-token');
  const currentUser = JSON.parse(currentUserS);
  const [fav, setFav] = useState(false);
  const history = useHistory();
  const [pre, setPre] = useState(true);
  const [favStatus, setFavStatus] = useState(''); // Initialize the status state

  const handleLike = async () => {
    setFav(!fav);

    if (currentUser) {
      try {
        await axios.patch(
          `${process.env.REACT_APP_API}/ads/${id}/like`,
          { userId: currentUser._id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        // Update the local favorite list
        if (fav) {
          updateFavorites();
          toast.error('Cette annonce a été retirée de votre liste de favoris', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else {
          updateFavorites();
          toast.success(
            'Cette annonce a été ajoutée à votre liste de favoris!',
            {
              // Corrected from toast.sucess to toast.success
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            }
          );
        }
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    } else {
      toast.error(
        'Pour sauvegarder vos annonces préférées, veuillez vous connecter !',
        {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      history.push('/auth/login');
    }
  };
  const [isInView, setIsInView] = useState(false);
  const adRef = useRef();
  const updateAdViews = async adId => {
    try {
      // Make a PATCH request to update ad views with the provided headers
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/ads/${adId}/views/update`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      // Log the updated views (optional)
      console.log('Updated Ad Views:', response.data.nbrViews);
    } catch (error) {
      console.error('Error updating ad views:', error);
    }
  };
  const handleIntersection = useCallback(
    async entries => {
      entries.forEach(entry => {
        console.log('Intersection observed:', entry);
        if (entry.isIntersecting && !isInView) {
          setIsInView(true);
          console.log('Ad is visible. Updating views for ad id:', id);
          // Call the function to update ad views when the ad becomes visible for the first time
          updateAdViews(id);
        }
      });
    },
    [id, isInView]
  );

  useEffect(() => {
    console.log('Effect triggered');
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    const currentAdRef = adRef.current;
    console.log('Current adRef:', currentAdRef);

    if (currentAdRef) {
      observer.observe(currentAdRef);
    }

    return () => {
      if (currentAdRef) {
        observer.unobserve(currentAdRef);
      }
    };
  }, [adRef, handleIntersection]);

  // const getFavorites = async () => {
  //   try {
  //     // Fetch the currentUser object from localStorage
  //     const currentUserS = localStorage.getItem('user-token');
  //     const currentUser = JSON.parse(currentUserS);

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API}/ads/${currentUser._id}/favorites`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     setListFavorites(response.data);

  //     // Update the currentUser object with the new favorites
  //     currentUser.favorites = response.data;
  //     localStorage.setItem('user-token', JSON.stringify(currentUser));

  //     const isIdInFavorites = response.data.includes(id);
  //     setFav(isIdInFavorites);
  //   } catch (error) {
  //     console.error(error);
  //     // Handle the error
  //   }
  // };

  // useEffect(() => {
  //   getFavorites();
  // }, [listFavorites]);

  console.log('list favories', listFavorites);
  const handleClickNumber = async () => {
    try {
      // Make a PATCH request to update ad views with the provided headers
      const response = await axios.patch(
        `${process.env.REACT_APP_API}/ads/${id}/clicks/update`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      // Log the updated views (optional)
      console.log('Updated Ad clicks:', response.data.nbrClicks);
    } catch (error) {
      console.error('Error updating ad clicks:', error);
    }
    console.log('handleClickNumber executed');
  };
  const biddersArray = Array.isArray(bidders) ? bidders : [];

  // const handlfav = (id)=> {
  //   setFav(!fav)
  //   alert(id)
  // }

  return (
    <Card ref={adRef} shadow="lg" p={0}>
      <Flex direction={{ base: 'column' }}>
        <Box align="center">
          {isShopPromoted && (
            <Box
              position="absolute"
              bottom="5px"
              right="5px"
              align="center"
              justify="center"
            >
              <Badge fontSize="sm" colorScheme="red">
                PROMO -{discountShop}%
              </Badge>
            </Box>
          )}
          {propart === 'pro' ? (
            <Flex
              position="absolute"
              top="-6px"
              right="-6px"
              zIndex={1}
              align="center"
              justify="center"
              bg="green.500"
              borderRadius="full"
              w="35px"
              h="35px"
              boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
            >
              <IconButton
                icon={<FaStoreAlt />}
                aria-label="Shop Account"
                variant="solid"
                colorScheme="white"
                size="xl"
              />
            </Flex>
          ) : (
            <Flex
              position="absolute"
              top="-6px"
              zIndex={1}
              right="-6px"
              align="center"
              justify="center"
              bg="red.500"
              borderRadius="full"
              w="35px"
              h="35px"
              boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
            >
              <IconButton
                icon={<FaUserTie />}
                aria-label="Shop Account"
                variant="solid"
                colorScheme="white"
                size="xl"
              />
            </Flex>
          )}
          <Flex
            position="absolute"
            top="-6px"
            zIndex={1}
            left="-6px"
            align="center"
            justify="center"
            bg={fav || listFavorites.includes(id) ? 'red.500' : 'gray.500'}
            borderRadius="full"
            w="35px"
            h="35px"
            boxShadow="0 0 4px rgba(0, 0, 0, 0.4)"
          >
            <IconButton
              icon={<FaHeart />}
              aria-label="Favorite"
              variant="solid"
              colorScheme="white"
              size="xl"
              onClick={handleLike}
            />
          </Flex>
          <Box
            onClick={() => {
              Click(); // Call the original Click function
              handleClickNumber(); // Call the new handleClickNumber function
            }}
            style={{ position: 'relative', display: 'inline-block' }}
            cursor="pointer"
          >
            <Image
              cursor="pointer"
              src={image}
              loading="lazy"
              boxSize="300px"
              objectFit="cover"
              borderTopRadius="15px"
              fallbackSrc={Nft3}
            />
            <Box
              w="80%"
              position="absolute"
              bottom="5%"
              left="40%"
              transform="translateX(-50%)"
              zIndex="0"
            >
              <Box
                textAlign="start"
                bg="rgba(0, 0, 0, 0.6)"
                px="6px"
                py="2px"
                borderRadius="md"
                mb="2"
              >
                <Text fontSize="xs" fontWeight="bold" color="white">
                  <Icon as={MdImage} mr="1" />
                  {image === Nft3 ? '0 images' : nbrPictures}
                </Text>
              </Box>
              <Box
                textAlign="start"
                bg="rgba(0, 0, 0, 0.6)"
                px="6px"
                py="2px"
                borderRadius="md"
                mb="2"
              >
                <Text fontSize="xs" fontWeight="bold" color="white">
                  <Icon as={MdLocationOn} mr="1" />
                  {city}
                </Text>
              </Box>
              <Box
                bg="rgba(0, 0, 0, 0.6)"
                px="6px"
                py="2px"
                borderRadius="md"
                textAlign="start"
              >
                <Text fontSize="xs" fontWeight="bold" color="white">
                  <Icon as={MdAccessTimeFilled} mr="1" />
                  {dateCreated}
                </Text>
              </Box>
            </Box>

            <div style={watermarkStyle} />
          </Box>
        </Box>{' '}
        <Box
          ml={2}
          mt={4}
          align="start"
          h="100%"
          onClick={() => {
            Click(); // Call the original Click function
            handleClickNumber(); // Call the new handleClickNumber function
          }}
          cursor="pointer"
        >
          <Flex
            justify="space-between"
            direction={{
              base: 'row',
              md: 'column',
              lg: 'row',
              xl: 'column',
              '2xl': 'row',
            }}
            mb="auto"
          >
            <Flex direction="column" align="flex-start">
              <Flex>
                <Text
                  color={brandColor}
                  fontWeight="500"
                  fontSize="lg"
                  mb="4px"
                  style={{
                    textDecoration: isShopPromoted ? 'line-through' : 'none',
                    textDecorationThickness: isShopPromoted ? '2px' : 'auto',
                  }}
                >
                  {' '}
                  {currentbid}{' '}
                </Text>
                {isShopPromoted && (
                  <Text
                    ml={2}
                    color={brandColor}
                    fontWeight="600"
                    fontSize="lg"
                    mb="4px"
                  >
                    {' '}
                    {promotedPrice}{' '}
                  </Text>
                )}
              </Flex>
              <Text
                color={textColor}
                fontSize={{
                  base: 'sm',
                  md: 'lg',
                  lg: 'lg',
                  xl: 'md',
                  '2xl': 'md',
                  '3xl': 'lg',
                }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {' '}
                {name}{' '}
              </Text>{' '}
              <Text
                color="secondaryGray.600"
                fontSize={{
                  base: '10px',
                }}
                fontWeight="700"
                me="14px"
                pb={2}
              >
                {' '}
                {category}{' '}
              </Text>{' '}
            </Flex>{' '}
          </Flex>{' '}
          {/* <Flex display="flex" flexDirection="row">
            <Flex
              mr={5}
              align="start"
              justify="end"
              direction={{
                base: 'column',
                md: 'column',
                lg: 'column',
                xl: 'column',
                '2xl': 'column',
              }}
            >
              <Text fontWeight="300" fontSize="sm" color="secondaryGray.600">
                <Icon
                  w={4}
                  h={4}
                  mr={1}
                  as={MdLocationOn}
                  color="secondaryGray.600"
                />{' '}
                {city}{' '}
              </Text>{' '}
            </Flex>{' '}
            <Flex
              align="end"
              justify="center"
              direction={{
                base: 'column',
                md: 'column',
                lg: 'column',
                xl: 'column',
                '2xl': 'column',
              }}
              mt="25px"
            >
              <Text fontWeight="200" fontSize="sm" color="secondaryGray.600">
                <Icon
                  w={4}
                  h={4}
                  mr={1}
                  as={MdAccessTimeFilled}
                  color="secondaryGray.600"
                />
                {dateCreated}{' '}
              </Text>{' '}
            </Flex>{' '}
          </Flex>{' '} */}
        </Box>{' '}
      </Flex>{' '}
    </Card>
  );
});
export default NFT;
