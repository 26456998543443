import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';
import { useColorMode } from "@chakra-ui/react";
import { Skeleton } from '@chakra-ui/react';
import { useDispatch,useSelector } from 'react-redux';
import {setCategories} from '../../../state/categories/categoriesSlice'
import { setAdsCount } from "../../../state/adsCount/adsCountSlice";
// Custom components
import Category from 'components/card/category';

// Assets
import Nft4 from 'assets/img/nfts/Nft4.png';
import { useHistory } from 'react-router-dom';

export default function Categories() {
  //const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [adsCount, setAdsCount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const categoriesRedux = useSelector((state) => state.categories.categories);
  const adsCountRedux = useSelector((state) => state.adsCount); // Access adsCount from Redux

 

  useEffect(() => {
    const fetchData = async () => {
      if (categoriesRedux.length < 1) {
        setIsLoading(true); // Set isLoading to true as a local state

        try {
          const res = await axios.get(`${process.env.REACT_APP_API}/categories`);
          const categoriesData = res.data;

          dispatch(setCategories(categoriesData));

          // Fetch ads count for each category
          for (const category of categoriesData) {
            const response = await axios.get(
              `${process.env.REACT_APP_API}/ads/category/count/${category._id}`
            );

            dispatch(setAdsCount(adsCount));
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false); // Set isLoading to false as a local state
        }
      }
    };

    fetchData();
  }, [dispatch, categoriesRedux.length]);
  const getCategoryById = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/categories/category/${id}`
      );
      setCategory(response.data);

      history.push(`/categories/category/${id}`);
    } catch (error) {
      console.error(error);
    }
  };
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  const { colorMode } = useColorMode();

  return (
    <>
   {isLoading ? (
  <Box pt={{ base: '180px', md: '80px', xl: '80px' }} className='Box'>
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      gap="20px"
      mb={{ base: '20px', xl: '0px' }}
    >
      {[...Array(9)].map((_, index) => (
        <Box key={index} borderRadius="lg" overflow="hidden" className='Box'>
          <Skeleton
            height={{ base: '120px', md: '200px' }}
            width="100%"
            startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
            endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
          />
          <Box p="6">
            <Box
              mt="1"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
              color={textColor}
            >
              <Skeleton
                height="10px"
                width={{ base: '50%', md: '30%' }}
                mb={2}
                startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
                endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
              />
            </Box>
            <Box d="flex" alignItems="baseline" color={textColorBrand} fontSize="sm">
              <Skeleton
                height="10px"
                width={{ base: '20%', md: '10%' }}
                mr={2}
                startColor={colorMode === "dark" ? "gray.700" : "gray.200"}
                endColor={colorMode === "dark" ? "gray.600" : "gray.300"}
              />
            </Box>
            <Box d="flex" alignItems="baseline" color={textColorBrand} fontSize="sm">
 
          </Box>
          <Button isLoading loadingText="Loading..." />
        
          </Box>
          
        </Box>
      ))}
    </SimpleGrid>
  </Box>
) : (
  <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      gap="20px"
      mb={{ base: '20px', xl: '0px' }}
    >
      {categoriesRedux.map(category => {
        const handleClick = () => {
          getCategoryById(category._id);
        };
        return (
          <Category
            key={category._id}
            label={category.label}
            image={category.CatPicture}
            download={handleClick}
          />
        );
      })}
    </SimpleGrid>
  </Box>
)}
  </>
  );
}
