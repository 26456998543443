// chakra imports
import {
    Icon,
    Flex,
    Text,
    useColorModeValue,
    MenuItem,
    Box,
    Button,
    Center,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    IconButton,
    Stack,
  } from '@chakra-ui/react';
  import { BsThreeDotsVertical, BsChatSquareQuote } from 'react-icons/bs'
  import { getCreatedNotification } from '../../views/ads/createAd/';

import { RiShutDownLine, RiRestartLine, RiFileShredLine } from 'react-icons/ri'
  import { MdUpgrade, MdPersonAddAlt1, MdOutlineNotificationsPaused, MdDeleteForever } from 'react-icons/md';
  import { AiFillAlert } from 'react-icons/ai';
  import { FaCheckCircle } from 'react-icons/fa';
  import { TiDelete } from 'react-icons/ti';
  import React, { useEffect, useState, useRef } from 'react';
  import axios from 'axios';
  import { io } from 'socket.io-client';
  import { NavLink, useHistory } from 'react-router-dom';
  import moment from 'moment';
  import { useSocket } from '../../contexts/SocketContext'
  import socket from '../../socket'
  import { v4 } from "uuid";
  //import {showNotification} from 'state/notification/notificationSlice'
import { useDispatch } from 'react-redux';

  export function AllNotification(props) {
    const { Click, currentUserId, conversation } = props;
    const unread = useColorModeValue('gray.200', 'whiteAlpha.200');
    const read = useColorModeValue('navy.700', 'white');
    const [notificationsSocketAdmin, setNotificationsSocketAdmin] = useState([]);
    const [notificationsSocketFollower, setNotificationsSocketFollower] = useState([]);
    const [notificationsSocketAd, setNotificationsSocketAd] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notificationsAdmine, setNotificationsAdmine] = useState([]);
    const [notificationsFollowerAd, setNotificationsFollowerAd] = useState([]);
    const [notificationsNewFollower, setNotificationsNewFollower] = useState([]);
    const dispatch = useDispatch();

    const [notifUsers, setNotifUsers] = useState([]);
    const [deleted, setDeleted] = useState(false);
    const history = useHistory();
    const notificationColor = useColorModeValue('red.600', 'red.200');
    function generateSessionToken() {
      // Generate a random session token using UUID (Universally Unique Identifier)
      return v4();
    }
    const sessionT = generateSessionToken();
    console.log("sessionT",sessionT);
    const sessionToken = sessionStorage.getItem('sessionToken');
    useEffect(() => {
      if (socket) {
        if (sessionToken) {
          socket.emit('addUser', { userId: currentUserId, sessionToken });
        }
        else 
     {   socket.emit('addUser', { userId: currentUserId, sessionToken: sessionT });
        sessionStorage.setItem('sessionToken', sessionT);}
        socket.on('getUsers', users => {
          setNotifUsers(users);
        });
      }
    }, [currentUserId]);
    console.log("notifUsers",notifUsers);

    // get notif new ad from following


    useEffect(() => {
      // Check if socket is defined before setting up event listeners
      socket.on('receiveNotification', messageData => {
          // Handle new notifications here
           // Access the data in messageData
           console.log('Received new notification:', messageData);
  
    const { type, recipient, sender, message } = messageData;
  
    // Create a new notification object
    const newNotification = {
      type,
      recipient,
      sender,
      message,
      createdAt: Date.now(), // You can set the timestamp here
    };
  
    // Update the state with the new notification
    if (newNotification.type === 'newAdFollower') {
      setNotificationsSocketAd(prevNotifications => [
        ...prevNotifications,
        newNotification,
      ]);
    }
          
      });
    }, []);
    console.log("notificationsSocketAd", notificationsSocketAd);

    // get notif new ad admin to verify
    
    useEffect(() => {
      // Check if socket is defined before setting up event listeners
      socket.on('receiveNotification', messageData => {
          // Handle new notifications here
           // Access the data in messageData
           console.log('Received new notification:', messageData);
  
    const { type, recipient, sender, message } = messageData;
  
    // Create a new notification object
    const newNotification = {
      type,
      recipient,
      sender,
      message,
      createdAt: Date.now(), // You can set the timestamp here
    };
  
    // Update the state with the new notification
    if (newNotification.type === 'newAddAdmin') {
      setNotificationsSocketAdmin(prevNotifications => [
        ...prevNotifications,
        newNotification,
      ]);
    }
          
      });
    }, []);
    console.log("notificationsSocketAdmin", notificationsSocketAdmin);

    // get notif new Follower

    useEffect(() => {
      // Check if socket is defined before setting up event listeners
      socket.on('receiveNotification', messageData => {
          // Handle new notifications here
           // Access the data in messageData
           console.log('Received new notification:', messageData);
  
    const { type, recipient, sender, message } = messageData;
  
    // Create a new notification object
    const newNotification = {
      type,
      recipient,
      sender,
      message,
      createdAt: Date.now(), // You can set the timestamp here
    };
  
    // Update the state with the new notification
    if (newNotification.type === 'new_follower') {
      setNotificationsSocketFollower(prevNotifications => [
        ...prevNotifications,
        newNotification,
      ]);
    }
          
      });
    }, []);
    console.log("notificationsSocketFollower", notificationsSocketFollower);

  
    // Fetch notifications type newAddAdmin

    const fetchNotificationsAdmine = async () => 
    {
      try {
        const historicalNotificationsResponse = await axios.get(
          `${process.env.REACT_APP_API}/notifications/${currentUserId}?type=newAddAdmin`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
   
        const historicalNotifications = historicalNotificationsResponse.data;
  
        // Fetch real-time notifications from your state (notificationsSocket)
        const realTimeNotifications = notificationsSocketAdmin;
        const mergedNotifications = [
          ...historicalNotifications,
          ...realTimeNotifications.filter(realTimeNotif => {
            // Check if a notification with the same message ID exists in historical notifications
            return !historicalNotifications.some(
              historicalNotif =>
                historicalNotif.sender === realTimeNotif.sender && historicalNotif.recipient === realTimeNotif.recipient
            );
          }),
        ];
  
        // Combine historical and real-time notifications
        mergedNotifications.sort((a, b) => b.timestamp - a.timestamp);
  
  
        // Set the combined notifications in state
    
        const notifications = mergedNotifications;
        setNotificationsAdmine(notifications);
        console.log("NotificationsAdmine", notificationsAdmine);

          
        } catch (err) {
          
        }
    };
    
    useEffect(() => {
      fetchNotificationsAdmine();
    }, [notificationsSocketAdmin]);
// Fetch notifications type newAdFollower

    const fetchNotificationsFollowerAd = async () => {

      try {
        const historicalNotificationsResponse = await axios.get(
          `${process.env.REACT_APP_API}/notifications/${currentUserId}?type=newAdFollower`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
   
        const historicalNotifications = historicalNotificationsResponse.data;
  
        // Fetch real-time notifications from your state (notificationsSocket)
        const realTimeNotifications = notificationsSocketAd;
        const mergedNotifications = [
          ...historicalNotifications,
          ...realTimeNotifications.filter(realTimeNotif => {
            // Check if a notification with the same message ID exists in historical notifications
            return !historicalNotifications.some(
              historicalNotif =>
                historicalNotif.sender === realTimeNotif.sender && historicalNotif.recipient === realTimeNotif.recipient
            );
          }),
        ];
  
        // Combine historical and real-time notifications
        mergedNotifications.sort((a, b) => b.timestamp - a.timestamp);
  
  
        // Set the combined notifications in state
    
        const notifications = mergedNotifications;
        setNotificationsFollowerAd(notifications);
        console.log("NotificationsFollowerAd", notificationsFollowerAd);

          
        } catch (err) {
          
        }
    };
    useEffect(() => {
      fetchNotificationsFollowerAd();
    }, [notificationsSocketAd]);
// Fetch notifications type newFollower


    const fetchNotificationsNewFollower = async () => {
  
      try {
        const historicalNotificationsResponse = await axios.get(
          `${process.env.REACT_APP_API}/notifications/${currentUserId}?type=new_follower`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
   
        const historicalNotifications = historicalNotificationsResponse.data;
  
        // Fetch real-time notifications from your state (notificationsSocket)
        const realTimeNotifications = notificationsSocketFollower;
        const mergedNotifications = [
          ...historicalNotifications,
          ...realTimeNotifications.filter(realTimeNotif => {
            // Check if a notification with the same message ID exists in historical notifications
            return !historicalNotifications.some(
              historicalNotif =>
                historicalNotif.sender === realTimeNotif.sender && historicalNotif.recipient === realTimeNotif.recipient
            );
          }),
        ];
  
        // Combine historical and real-time notifications
        mergedNotifications.sort((a, b) => b.timestamp - a.timestamp);
  
  
        // Set the combined notifications in state
    
        const notifications = mergedNotifications;
        setNotificationsNewFollower(notifications);
        console.log("notificationsNewFollower", notificationsNewFollower);

          
        } catch (err) {
          
        }
    };

    useEffect(() => {
      fetchNotificationsNewFollower();
    }, [notificationsSocketFollower]);


 
    
    const handleNotificationClick = async (notificationId,adId) => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API}/notifications/${notificationId}`,
          { read: true },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        history.push(`/ads/${adId}`);
  
        
        // You could also remove the notification from the list of unread notifications
      } catch (err) {
        
      }
    };
    const handleNotificationClickFollower = async (notificationId,adId) => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API}/notifications/${notificationId}`,
          { read: true },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        history.push(`/admin/profile`);
  
        
        // You could also remove the notification from the list of unread notifications
      } catch (err) {
        
      }
    };


  
    const [showUnread, setShowUnread] = useState(false);
    const filteredNotificationsAdmine = showUnread
    ? notificationsAdmine.filter(notification => notification.read)
    : notificationsAdmine.filter(notification => !notification.read);

    const filteredNotificationsFollower = showUnread
    ? notificationsFollowerAd.filter(notification => notification.read)
    : notificationsFollowerAd.filter(notification => !notification.read);

    const filteredNotificationsNewFollowe = showUnread
    ? notificationsNewFollower.filter(notification => notification.read)
    : notificationsNewFollower.filter(notification => !notification.read);


    const handleDelete = async notificationId => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API}/notifications/delete/${notificationId}`,
          { read: true },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
       
        
        window.location.reload();
        setDeleted(true);
        // You could also remove the notification from the list of unread notifications
      } catch (err) {
        
      }
    };
    if (notificationsAdmine.length === 0 && notificationsFollowerAd.length===0 &&notificationsNewFollower.length===0) {
      return (
        <>
          <Box w='100%' display='flex' justifyContent='center' mb={2}  >
          <Flex direction='row' >        <Icon as={MdOutlineNotificationsPaused} color='grey'  w={14} h={14} />
  </Flex>
  </Box>
  <Box w='100%' display='flex' justifyContent='center'  >
  
            <Flex direction='row' align="center">
              <Text
                mb="5px"
                fontWeight="regular"
                color='grey'
                fontSize={{ base: 'md', md: 'md' }}
              >
                Désolé, pas de notifications à l'horizons
              </Text>
            </Flex>
          </Box>
        </>
      );
    } else {
      return (
        <>
      <Flex justify="space-between" align="center" mb={4} mt={4}>
    <Button onClick={() => setShowUnread(false)} variant="outline" size="sm">
      Show Unread
    </Button>
    <Button onClick={() => setShowUnread(true)} variant="outline" size="sm">
      Show Read
    </Button>
  </Flex>
 
  <Box maxHeight="300px" overflowY="scroll">

        {!deleted && 
          filteredNotificationsAdmine.map(notification => (
            <MenuItem w="100%" 
           bg={notification?.read ? 'none' : unread}
              
              px="0"
              borderRadius="8px"
              mb="4px"
              mt="2px"
              p={2}
            >
              <Flex  w="100%"  onClick={() => handleNotificationClick(notification._id,notification.adId)} key={notification._id} align="center"  >
                <Flex
                  justify="center"
                  align="center"
                  borderRadius="16px"
                  minH={{ base: '60px', md: '70px' }}
                  h={{ base: '60px', md: '70px' }}
                  minW={{ base: '60px', md: '70px' }}
                  w={{ base: '60px', md: '70px' }}
                  me="14px"
                  bg="linear-gradient(135deg, #d69e2e 0%, #d69e2e 100%)"
                >
                  <Icon as={AiFillAlert} color="white" w={8} h={14} />
                </Flex>
                <Flex flexDirection="column">
                  <Text
                    mb="5px"
                    fontWeight="bold"
                    color={read}
                    fontSize={{ base: 'md', md: 'md' }}
                  >
                   Nouvelle annonce ajoutèe non validèe.
                  </Text>
                  <Flex alignItems="center">
                    <Text
                      fontSize={{ base: 'sm', md: 'sm' }}
                      lineHeight="100%"
                      color={read}
                    >
                      {notification.message} {'          '}
                      {moment(notification.createdAt).fromNow()
}

                    </Text>
                    
                  </Flex>
                </Flex>
               
              </Flex>
              <Flex  ml={6} >
                <Icon onClick={() => handleDelete(notification._id)} as={TiDelete} color={notificationColor}  w={8} h={8} />
  
                </Flex>{' '}
            </MenuItem>
          ))}
          {!deleted && 
          filteredNotificationsFollower.map(notification => (
            <MenuItem w="100%" 
           bg={notification?.read ? 'none' : unread}
              
              px="0"
              borderRadius="8px"
              mb="4px"
              mt="2px"
              p={2}
            >
              <Flex  w="100%"  onClick={() => handleNotificationClick(notification._id,notification.adId)} key={notification._id} align="center"  >
                <Flex
                  justify="center"
                  align="center"
                  borderRadius="16px"
                  minH={{ base: '60px', md: '70px' }}
                  h={{ base: '60px', md: '70px' }}
                  minW={{ base: '60px', md: '70px' }}
                  w={{ base: '60px', md: '70px' }}
                  me="14px"
                  bg="linear-gradient(135deg, #2f855a 0%, #2f855a 100%)"
                >
                  <Icon as={FaCheckCircle} color="white" w={8} h={14} />
                </Flex>
                <Flex flexDirection="column">
                  <Text
                    mb="5px"
                    fontWeight="bold"
                    color={read}
                    fontSize={{ base: 'md', md: 'md' }}
                  >
                 Nouvelle Annonce ajoutèe
                  </Text>
                  <Flex alignItems="center">
                    <Text
                      fontSize={{ base: 'sm', md: 'sm' }}
                      lineHeight="100%"
                      color={read}
                    >
                      {notification.message} {'          '}
                      {moment(notification.createdAt).fromNow()
}

                    </Text>
                    
                  </Flex>
                </Flex>
               
              </Flex>
              <Flex  ml={6} >
                <Icon onClick={() => handleDelete(notification._id)} as={TiDelete} color={notificationColor}  w={8} h={8} />
  
                </Flex>{' '}
            </MenuItem>
          ))}
           {!deleted && 
          filteredNotificationsNewFollowe.map(notification => (
            <MenuItem w="100%" 
           bg={notification?.read ? 'none' : unread}
              
              px="0"
              borderRadius="8px"
              mb="4px"
              mt="2px"
              p={2}
            >
              <Flex  w="100%"  onClick={() => handleNotificationClickFollower(notification._id)} key={notification._id} align="center"  >
                <Flex
                  justify="center"
                  align="center"
                  borderRadius="16px"
                  minH={{ base: '60px', md: '70px' }}
                  h={{ base: '60px', md: '70px' }}
                  minW={{ base: '60px', md: '70px' }}
                  w={{ base: '60px', md: '70px' }}
                  me="14px"
                  bg="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
                >
                  <Icon as={MdPersonAddAlt1} color="white" w={8} h={14} />
                </Flex>
                <Flex flexDirection="column">
                  <Text
                    mb="5px"
                    fontWeight="bold"
                    color={read}
                    fontSize={{ base: 'md', md: 'md' }}
                  >
                   Nouveau fan en vue 
                  </Text>
                  <Flex alignItems="center">
                    <Text
                      fontSize={{ base: 'sm', md: 'sm' }}
                      lineHeight="100%"
                      color={read}
                    >
                      {notification.message} {'          '}
                      {moment(notification.createdAt).fromNow()
}

                    </Text>
                    
                  </Flex>
                </Flex>
               
              </Flex>
              <Flex  ml={6} >
                <Icon onClick={() => handleDelete(notification._id)} as={TiDelete} color={notificationColor}  w={8} h={8} />
  
                </Flex>{' '}
            </MenuItem>
          ))}
          </Box>

        </>
      );
    }
  }
  