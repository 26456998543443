import { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Input,
  Button,
  VStack,
  Alert,
  AlertIcon,
  CloseButton,
  SimpleGrid,
  ListItem,
  UnorderedList,
  Flex,
  Avatar,
  IconButton,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddAdminShop = ({ user, listAdmins }) => {
  const [searchEmail, setSearchEmail] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const addAdminToShop = async (userId, shopId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/shops/shop/${shopId}/admin`,
        { adminId: userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if searchEmail is set and is in a valid email format
        if (searchEmail.trim() && /^\S+@\S+\.\S+$/.test(searchEmail.trim())) {
          // Use Axios to fetch all users
          const res = await axios.get(
            `${process.env.REACT_APP_API}/users/admin/all`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            }
          );
          const allUsers = res.data;

          // Filter users based on searchEmail
          const filteredUsers = allUsers.filter(user =>
            user.email.includes(searchEmail.trim())
          );

          // Update search results
          setSearchResults(filteredUsers);
        } else {
          // If email is not set or not in a valid format, clear search results
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error if needed
      }
    };

    // Trigger fetchData whenever searchEmail changes
    fetchData();
  }, [searchEmail]);

  const handleAddUser = (selectedUser) => {
    // Check if the user is already in the list
    const userAlreadyAdded = selectedUsers.some((user) => user.email === selectedUser.email);
  
    if (!userAlreadyAdded) {
      // Add selected user to the list
      setSelectedUsers((prevUsers) => [...prevUsers, selectedUser]);
      setSearchResults([]);
      setSearchEmail('');
    } else {
      // Display a French error message using Toastify
      toast.error("Cet utilisateur est déjà ajouté en tant qu'administrateur.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000, // Adjust the autoClose duration as needed
      });
    }
  };

  const handleRemoveUser = index => {
    // Remove user from the list
    setSelectedUsers(prevUsers => [
      ...prevUsers.slice(0, index),
      ...prevUsers.slice(index + 1),
    ]);
  };

  const handleAddAdmin = async () => {
    try {
      if (selectedUsers.length + listAdmins > user.nbrAdmins) {
        toast.error(`Vous ne pouvez pas ajouter plus de ${user.nbrAdmins} administrateurs.`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        return;
      }
  
      for (const selectedUser of selectedUsers) {
        await addAdminToShop(selectedUser._id, user.idShop); // Assuming user ID is accessible as `id`
      }
  
      setSearchEmail('');
      setSelectedUsers([]);
  
      // Display a success message in French
      toast.success('Administrateur ajouté avec succès!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    } catch (error) {
      console.error('Error adding admin to shop:', error);
      toast.error("Une erreur s'est produite lors de l'ajout de l'administrateur au magasin", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  return (
    <Card shadow="lg">
      <VStack spacing="4" align="start">
        <Text fontWeight="bold" fontSize="xl">
          Ajouter un administrateur ({listAdmins}/{user.nbrAdmins})
        </Text>
        <Input
          variant="auth"
          placeholder="Entrez l'e-mail de l'utilisateur"
          value={searchEmail}
          onChange={e => setSearchEmail(e.target.value)}
        />
        {searchResults.length > 0 && (
          <Box>
            <Text fontWeight="bold" mb="2">
              Résultats de la recherche :
            </Text>
              {searchResults.map((result, index) => (
                <Box mb={2}>
        <Flex
          shadow="lg"
          w="150%"
          maxW="340px"
          alignItems="center"
          justify="space-between"
          p={4}
          borderWidth={1}
          borderRadius="15px"
        >
          <Flex alignItems="center">
            <Avatar
              boxSize="45px"
              borderRadius="full"
              src={result.profilePicture}
              alt={`Profile of ${result.firstName}`}
              mr={4}
            />
            <Box>
              <Text fontSize="sm" fontWeight="bold">
                {result.firstName} {result.lastName}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {result.email}
              </Text>
            </Box>
          </Flex>
          <Button
            ml={2}
            variant="brand"
            size="sm"
            onClick={() => handleAddUser(result)}
          >
            Ajouter
          </Button>
        </Flex>
      </Box>
              ))}
          </Box>
        )}
        {selectedUsers.length > 0 && (
          <Box>
            <Text fontWeight="bold" mb="2">
              Utilisateurs sélectionnés :
            </Text>
            {selectedUsers.map((selectedUser, index) => (
              <Box w="100%" mb={2} key={index}>
                <Flex
                  shadow="lg"
                  w="150%"
                  maxW="340px"
                  alignItems="center"
                  justify="space-between"
                  p={4}
                  borderWidth={1}
                  borderRadius="15px"
                >
                  <Flex alignItems="center">
                    <Avatar
                      boxSize="45px"
                      borderRadius="full"
                      src={selectedUser.profilePicture} // Replace with the actual property that holds the profile picture URL
                      alt={`Profile of ${selectedUser.firstName}`}
                      mr={4}
                    />
                    <Box>
                      <Text fontSize="sm" fontWeight="bold">
                        {selectedUser.firstName} {selectedUser.lastName}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {selectedUser.email}
                      </Text>
                    </Box>
                  </Flex>
                  <IconButton
                    aria-label={`Supprimer ${selectedUser.firstName}`}
                    icon={<FaTrash />}
                    variant="solid"
                    colorScheme="red"
                    borderRadius="full"
                    onClick={() => handleRemoveUser(index)}
                  />
                </Flex>
              </Box>
            ))}
          </Box>
        )}

        <Button w='100%' variant='brand' onClick={handleAddAdmin}>
          Ajouter les administrateurs
        </Button>
      </VStack>
    </Card>
  );
};

export default AddAdminShop;
