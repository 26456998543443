import React, { useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { Box } from "@chakra-ui/react";
import { motion,AnimatePresence  } from "framer-motion";
const WhatsAppIcon = () => {
  const [showMessage, setShowMessage] = useState(false);
  const phone = '+212647231100';

  const handleClick = () => {
    const message = `Chers utilisateurs,

    Aidez-nous à améliorer notre application ! Si vous rencontrez un bug, signalez-le via WhatsApp avec autant de détails que possible. Votre contribution est précieuse pour améliorer votre expérience utilisateur.
    
    Merci !
    
    L'équipe de développement de l'application`;
    

    // replace with your pre-defined message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');  };

  const handleMouseEnter = () => {
    setShowMessage(true);
  };

  const handleMouseLeave = () => {
    setShowMessage(false);
  };

  return (
    <Box
     className="whatsapp-icon"
        position="fixed"
        top="20px"
        left="20px"
        backgroundColor="#25D366"
        borderRadius="50%"
        width="50px"
        height="50px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        boxShadow="0 2px 5px rgba(0, 0, 0, 0.3)"
        zIndex="9999"
    >
      <Box
        position="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <IoLogoWhatsapp
          size={32}
          color="#fff"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        />
        <AnimatePresence>
          {showMessage && (
            <motion.div
            
              initial={{ opacity: 0, y: 80 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              style={{
                width:'200px',
                position: "absolute",
                bottom: "130%",
                right: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "#25D366",
                color: "#FFF",
                padding: "8px",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            >
              Signaler les Bug via Whatsapp
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default WhatsAppIcon;
