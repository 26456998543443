import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  IconButton,
  VStack,
  Avatar,
  Button,
  Flex
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { toast } from 'react-toastify';
import Card from 'components/card/Card.js';
import Swal from 'sweetalert2';
const ShopAdminList = ({ user, listAdmins, refreshShopAdmins }) => {


  const handleRemoveAdmin = async (adminId) => {
    try {
      // Display SweetAlert confirmation
      const result = await Swal.fire({
        title: 'Confirmation',
        text: 'Êtes-vous sûr de vouloir supprimer cet administrateur?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
      });

      if (result.isConfirmed) {
        // User confirmed, send a request to remove the admin from the shop
        await axios.delete(
          `${process.env.REACT_APP_API}/shops/shop/${user.idShop}/admin/${adminId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );

        // Refresh the list of shop listAdmins
        refreshShopAdmins();

        // Display a success message
        toast.success('Admin removed successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error('Error removing admin:', error);
      toast.error('An error occurred while removing the admin', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  return (
    <Card mt={5} shadow="lg">
      <VStack spacing="4" align="start">
        <Text fontWeight="bold" fontSize="xl">
          Liste des administrateurs ({listAdmins.length})
        </Text>
        {listAdmins.length > 0 ? (
          listAdmins.map((admin) => (
         
            <Box w="100%" mb={2} key={admin._id}>
                <Flex
                  shadow="lg"
                  w="150%"
                  maxW="340px"
                  alignItems="center"
                  justify="space-between"
                  p={4}
                  borderWidth={1}
                  borderRadius="15px"
                >
                  <Flex alignItems="center">
                    <Avatar
                      boxSize="45px"
                      borderRadius="full"
                      src={admin.profilePicture} // Replace with the actual property that holds the profile picture URL
                      alt={`Profile of ${admin.firstName}`}
                      mr={4}
                    />
                    <Box>
                      <Text fontSize="sm" fontWeight="bold">
                        {admin.firstName} {admin.lastName}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {admin.email}
                      </Text>
                    </Box>
                  </Flex>
                  <IconButton
                    aria-label={`Supprimer ${admin.firstName}`}
                    icon={<FaTrash />}
                    variant="solid"
                    colorScheme="red"
                    borderRadius="full"
                    onClick={() => handleRemoveAdmin(admin._id)}
                  />
                </Flex>
              </Box>
          ))
        ) : (
          <Text>Aucun administrateur trouvé</Text>
        )}
      </VStack>
    </Card>
  );
};

export default ShopAdminList;
