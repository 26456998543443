import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const adsCountSlice = createSlice({
  name: "adsCount",
  initialState,
  reducers: {
    setAdsCount: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setAdsCount } = adsCountSlice.actions;

export default adsCountSlice.reducer;