import { createSlice } from "@reduxjs/toolkit";

const recentAdsInitialState = {
  recentAds: [],
};

export const recentAdsSlice = createSlice({
  name: "recentAds",
  initialState: recentAdsInitialState,
  reducers: {
    setRecentAds: (state, action) => {
      state.recentAds = action.payload;
    },
    // Add other recent ads-related reducers here if needed
  },
});

// Export the actions generated by createSlice
export const { setRecentAds } = recentAdsSlice.actions;

// Export the reducer generated by createSlice
export default recentAdsSlice.reducer;
