import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Badge,
  Box,
  HStack,
  keyframes,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Stack,
  Grid,
  GridItem,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { t } from 'helpers/TransWrapper';
import Card from 'components/card/Card.js';
import MembershipPlan from './components/MembershipPlan.js';
import {
  MdBatteryCharging30,
  MdBatteryCharging60,
  MdBatteryChargingFull,
} from 'react-icons/md';
// Custom components

import { Skeleton } from '@chakra-ui/react';
// Assets

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Header } from 'semantic-ui-react';

const user = JSON.parse(localStorage.getItem('user-token'));

export default function Memberships() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const title = useColorModeValue('brand.500', 'brand.400');
  const textColorSecondary = 'gray.400';
  const [billingPeriod, setBillingPeriod] = useState('12');
  const handleBillingPeriodChange = value => {
    setBillingPeriod(value);
  };

  useEffect(() => {
    console.log('billingPeriod:', billingPeriod);
  }, [billingPeriod]);
  const formatNumber = num => {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k';
    }
    return num.toString();
  };
  const getPriceForBillingPeriod = monthlyPrice => {
    switch (billingPeriod) {
      case '1':
        return formatNumber(monthlyPrice);
      case '6':
        return formatNumber(Math.round((monthlyPrice * 6 * 0.8) / 6)); // Example: 10% discount for 6 months
      case '12':
        return formatNumber(Math.round((monthlyPrice * 12 * 0.7) / 12)); // Example: 20% discount for 12 months
      default:
        return formatNumber(monthlyPrice);
    }
  };

  const getAdsForBillingPeriod = monthlyAds => {
    switch (billingPeriod) {
      case '1':
        return formatNumber(monthlyAds);
      case '6':
        return ( monthlyAds === 200 ? formatNumber(Math.round(monthlyAds * 2 + 100 )): formatNumber(Math.round(monthlyAds * 2 ))); 
      case '12':
        return ( monthlyAds === 200 ? formatNumber(Math.round(monthlyAds * 4 + 200 )): formatNumber(Math.round(monthlyAds * 4 ))); 
      default:
        return formatNumber(monthlyAds);
    }
  };

  const getCreditForBillingPeriod = monthlyCredit => {
    switch (billingPeriod) {
      case '1':
        return formatNumber(monthlyCredit);
      case '6':
        return ( monthlyCredit === 200 ?formatNumber(Math.round(monthlyCredit * 2 + 300)) : formatNumber(Math.round(monthlyCredit * 2+ 50))); 
      case '12':
        return ( monthlyCredit === 200 ?formatNumber(Math.round(monthlyCredit * 4 + 700)) : formatNumber(Math.round(monthlyCredit * 4+ 200))); 
      default:
        return formatNumber(monthlyCredit);
    }
  };
  return (
    <Box pt={{ base: '100px', md: '80px', xl: '90px' }} pb={'80px'}>
      <Card>
        <Box textAlign="center">
          <Flex justifyContent="center">
            <Text color={title} fontWeight={600} fontSize="md">
              {' '}
              TARIFS PRO{' '}
              <Badge ml="1" px={1} borderRadius={5} colorScheme="green">
                NEW
              </Badge>{' '}
            </Text>
          </Flex>
          <Box textAlign="center">
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="5xl"
              mt="5px"
              mb="4px"
            >
              Boostez votre Boutique en Ligne
            </Text>
          </Box>
          <Box px={{ base: 'unset', md: '10px', xl: '60' }}>
            <Text color={textColorSecondary} fontSize="md" me="26px" mb="20px">
              Choisissez le plan idéal pour accélérer votre croissance,
              maximiser vos ventes et offrir une expérience client
              exceptionnelle
            </Text>
          </Box>
        </Box>
        <Box textAlign="center" mb="30px">
          <RadioGroup defaultValue="12" onChange={handleBillingPeriodChange}>
            <Stack direction="row" spacing={6} justify="center">
              <Radio value="1" colorScheme="blue" size="lg">
                1 Mois
              </Radio>
              <Radio value="6" colorScheme="green" size="lg">
                6 Mois
              </Radio>
              <Radio value="12" colorScheme="purple" size="lg">
                12 Mois
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Grid
          templateColumns={{ sm: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap={6}
          mb={5}
        >
          <MembershipPlan
            totalPrice={getPriceForBillingPeriod(299) * billingPeriod}
            billingCycle={billingPeriod}
            icon={MdBatteryCharging30}
            price={getPriceForBillingPeriod(299)}
            textColorSecondary={useColorModeValue('gray.700', 'gray.400')}
            mainColor={useColorModeValue('navy.500', 'white')}
            name="Débutant "
            descriptionShort="Premiers Pas"
            descriptionLongue="Idéal pour les nouveaux commerçants avec des outils de base"
            Color_title={useColorModeValue('brand.500', 'brand.400')}
            btnColor={useColorModeValue('white', 'white')}
            nbrAds={getAdsForBillingPeriod(30)}
            shopType="de base"
            support="de Base"
            admins="2"
            analytics="Basiques"
            credit={getCreditForBillingPeriod(30)}
          />
          <MembershipPlan
            totalPrice={getPriceForBillingPeriod(499) * billingPeriod}
            billingCycle={billingPeriod}
            icon={MdBatteryCharging60}
            price={getPriceForBillingPeriod(499)}
            popular={true}
            textColorSecondary={useColorModeValue('gray.400', 'gray.900')}
            backgroundColor={useColorModeValue('brand.500', 'white')}
            mainColor={useColorModeValue('white', 'navy.500')}
            name="Professionnel"
            descriptionShort="Élite des Affaires"
            descriptionLongue="Outils avancés pour une croissance commerciale"
            Color_title={useColorModeValue('white', 'brand.500')}
            btnColor={useColorModeValue('brand.500', 'white')}
            nbrAds={getAdsForBillingPeriod(50)}
            shopType="Évoluée"
            support="Prioritaire"
            admins="5"
            analytics="Avancées"
            credit={getCreditForBillingPeriod(50)}
          />
          <MembershipPlan
            totalPrice={getPriceForBillingPeriod(999) * billingPeriod}
            billingCycle={billingPeriod}
            icon={MdBatteryChargingFull}
            price={getPriceForBillingPeriod(999)}
            textColorSecondary={useColorModeValue('gray.900', 'gray.400')}
            mainColor={useColorModeValue('navy.500', 'white')}
            name="Expert"
            descriptionShort="Maître Marchand"
            descriptionLongue="Fonctionnalités avancées et support personnalisé"
            Color_title={useColorModeValue('brand.500', 'brand.400')}
            btnColor={useColorModeValue('white', 'white')}
            nbrAds={getAdsForBillingPeriod(200)}
            shopType="Complète"
            support="Premium"
            admins="10"
            analytics="Avancées"
            credit={getCreditForBillingPeriod(200)}
          />
        </Grid>
      </Card>
    </Box>
  );
}
