import {
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
  HStack,
  IconButton
} from "@chakra-ui/react";
import { FaAppStoreIos,FaGooglePlay } from 'react-icons/fa';


import { NavLink } from "react-router-dom";
import logoWhite from "../../../assets/img/layout/logoWhite.png";
import React from "react";
import { t } from "helpers/TransWrapper";

export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");
 const user = localStorage.getItem('user-token');
  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='20px'
      me='20px'
      position='relative'>

      <Flex
        direction='column'
        align='center'
        justify='center'
        px='15px'
        pt='15px'>
        <Text
          fontSize={{ base: "lg", xl: "18px" }}
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'
          mb='14px'>
        {t('JINDOO votre Marketplace 100% digitale')} 

        </Text>
      
      </Flex>
   
   
    </Flex>
  );
}
