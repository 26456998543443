import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  ThemeProvider,
  theme,
  Link,
  Box,
  Th,
  Tr,
  Td,
  Tbody,
  Table,
  Thead,
  VStack,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  Image,
  HStack,
  Divider,
  useDisclosure,
  CloseButton,
  Grid,
  GridItem,
  Progress,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  Wrap,
  WrapItem,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { FaArrowLeft, FaHeart, FaShareAlt, FaTimes } from 'react-icons/fa'; // Import icons
import Card from 'components/card/Card.js';
import {
  MdCameraEnhance,
  MdCategory,
  MdPerson,
  MdSearch,
  MdHomeFilled,
  MdDelete,
  MdImage,
  MdCheck,
  MdOutlineShoppingBag,
} from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';
import { TbRepeat, TbRocket } from 'react-icons/tb';
const Success = () => {
const history = useHistory();
const [subscription, setSubscription] = useState();
const updateOrder= async (id,status,paymentStatus,paypalPaymentStatus) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API}/orders/order/${id}`,
      {
        status: status,
        paymentStatus: paymentStatus,
        paypalPaymentStatus: paypalPaymentStatus,
        // Add other payment details as needed
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      }
    );
  

  } catch (error) {
    console.error('Error updating order:', error);
  }
}

const updateAd= async (id,isPromoted, isReposts,orderId,subscriptionId,promotionDays,repostsDays) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API}/ads/${id}/promo/update`,
      {
        isPromoted: isPromoted,
        isReposts: isReposts,
        orderId: orderId,
        subscriptionId: subscriptionId,
        promotionDays: promotionDays,
        repostsDays: repostsDays,
        // Add other payment details as needed
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      }
    );
  

  } catch (error) {
    console.error('Error updating Ad:', error);
  }
}



useEffect(() => {
  const handleReturnFromPaypal = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
      const paymentId = urlParams.get('paymentId');
      const payerId = urlParams.get('PayerID');
      const orderId = urlParams.get('orderId');
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/orders/order/${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const orderResponse = res.data.order;
        const order = orderResponse;
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_API}/subscriptions/get/${order?.idSubscription}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            }
          );
          const subscription = res.data;
          
        
        if (paymentId && payerId && orderId) {
      
          await updateOrder(orderId, 'SET', 'COMPLETED', 'completed');
          await updateAd(order?.idAd, subscription?.days !== 0 ? true:false ,subscription?.reposts !== 0 ? true:false, orderId, order?.idSubscription,order?.promo, order?.reposts );
        } else {
          // Handle missing parameters
          console.error('Missing payment or order parameters in the URL');
        }
      } catch (error) {
        console.error('Error handling PayPal return:', error);
        // Handle the error as needed, e.g., show an error message to the user
      }
    } catch (error) {
      console.error(error);
      // Handle the error as needed (e.g., show an error message to the user)
    }
      } catch (error) {
        console.error(error);
        // Handle the error as needed (e.g., show an error message to the user)
      }

   
  };

  // Call the function when the page loads
  handleReturnFromPaypal();
}, []);



  return (
    <Box p={5}>
      <VStack spacing={8} align="center">
        <FaCheckCircle size={50} color="green" />
        <Heading as="h2" size="xl">
          Paiement Réussi !
        </Heading>
        <Text fontSize="lg">
          Merci d'avoir souscrit à votre abonnement publicitaire.
        </Text>
        <Text fontSize="md">
          Vous pouvez maintenant profiter des avantages de votre abonnement.
        </Text>
        <Button
          colorScheme="brand"
          size="lg"
          onClick={() => {
            
           
            history.push('/');
          }}
        >
          Retour aux annonces 
        </Button>
      </VStack>
    </Box>
  );
}
export default Success;
