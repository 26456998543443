// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  Text,
  useColorModeValue,
  Button,
  Stack,
  Center,
  Badge,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  Input,
  Grid,
  Select,
  Textarea
  } from '@chakra-ui/react';
  import axios from 'axios';
  import Card from 'components/card/Card.js';
  import React, { useState, useEffect, useRef } from 'react';
  import { NavLink, useHistory } from 'react-router-dom';
  import { io } from 'socket.io-client';
  import { FaStoreAlt } from 'react-icons/fa';
  import { FaUserTie } from 'react-icons/fa';
  //import socket from '../../../socket';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { IoLogoWhatsapp } from 'react-icons/io';
  import { MdWorkspacePremium } from 'react-icons/md';
  import {
    MdDashboardCustomize,
    MdPhone,
    MdRateReview,
    MdPersonAddDisabled,
    MdPersonSearch,
    MdSettings,
  } from 'react-icons/md';
  import { t } from 'helpers/TransWrapper';
  import Rating from 'react-rating-stars-component';
  import moment from 'moment';
  const AddReviewModal = ({ user,isOpen, onClose, onSubmit,shop }) => {
    console.log('Modal rendering...');
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
  
    const handleSubmit = () => {
      // Validate the input if needed
      if (!rating || !comment) {
        // Display an error toast or handle validation
        toast.error('Veuillez remplir tous les champs.');
        return;
      }
  
      // Pass the review data to the parent component
      onSubmit({ user, rating, comment });
  
      // Reset the form and close the modal
      setRating(0);
      setComment('');
      onClose();
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un avis sur {shop}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Stack spacing={4}>
          <Box >
            <Text fontSize="md"  fontWeight="bold">
              Votre Évaluation ({rating}/5)
            </Text>
            <Rating
              count={5}
              onChange={(newRating) => setRating(newRating)}
              value={rating}
              size={40}
              activeColor="#F9B710"
            />
          </Box>
          <Box>
            <Text fontSize="md" mb={2} fontWeight="bold">
              Ajouter un Commentaire sur la boutique
            </Text>
            <Textarea
              placeholder="Ajouter un commentaire"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              size="md"
            />
          </Box>
        </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSubmit} variant="brand" mr={3}>
              Ajouter
            </Button>
            <Button onClick={onClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  
  export default function Banner(props) {
    const { banner, avatar, name, phone, posts, userId, memberShip, createdAt,positiveReviews,promoAds,shopId, visibility } =
      props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'gray.400';
    const borderColor = useColorModeValue(
      'white !important',
      '#111C44 !important'
    );
    const handleClick = () => {
      if (currentUser) {
        setIsModalOpen(true);
      } else {
        // Redirect to the sign-in page
        history.push('/auth/sign-in'); // Update with your sign-in route
      }
    };
    const formattedDate = moment(createdAt).format('MMM YYYY');
    const userData = localStorage.getItem('user-token');
    const currentUser = JSON.parse(userData);
    const [followersCount, setFollowersCount] = useState();
    const [followersFlag, setFollowersFlag] = useState(false);
    const [followingCount, setFollowingCount] = useState();
    const [followers, setFollowers] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [following, setFollowing] = useState([]);
    const [isFollowing, setIsFollowing] = useState(() => {
      const storedFollowStatus = localStorage.getItem('followStatus');
      return storedFollowStatus ? JSON.parse(storedFollowStatus) : false;
    });
    const [isOpen, setIsOpen] = useState(false);
  const [confirmationName, setConfirmationName] = useState('');
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddReview = async reviewData => {
    const currentDate = new Date();
    try {
      // Make API call to add the review
      const response = await axios.post(
        `${process.env.REACT_APP_API}/shops/shop/${shopId}/review`, 
        {
          userId: reviewData.user,
          rating: reviewData.rating,
          comment: reviewData.comment,
          createdAt: currentDate.toISOString(), // Convert to ISO string format
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Handle success
      console.log('Review added successfully:', response.data);
  
      // Display a success toast
      toast.success('Avis ajouté avec succès', { autoClose: 3000 });
  
      // Additional logic if needed
  
    } catch (error) {
      // Handle API call error
      console.error('Error adding review:', error.message);
      toast.error('Une erreur s\'est produite lors de l\'ajout de l\'avis.');
    }
  };

    const notification = {
      recipient: userId, // replace with actual recipient ID
      sender: currentUser?._id, // replace with actual sender ID
      message: `${currentUser?.firstName} ${currentUser?.lastName}, vous suit.`,
      type: 'new_follower', // specify the type of notification
    };
    const history = useHistory();
    const updateProfile = e => {
      history.push('/register/updateUser');
    };
    const updateShop = e => {
      history.push(`/shop/update/${name}`);
    };
    const dashboard = e => {
      history.push(`/shop/dashboard/${name}`);
    };
    console.log('followersFlag', followersFlag);
  
    const onClose = () => {
      setIsOpen(false);
      setConfirmationName('');
      setDeletionConfirmed(false);
    };
  
    const handleDelete = async () => {
      if (confirmationName.toLowerCase() === name.toLowerCase()) {
        try {
          const response = await axios.delete(
            `${process.env.REACT_APP_API}/shops/shop/${shopId}`, // Replace with your delete shop API endpoint
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            }
          );
    
          if (response.status === 200) {
            const ownerResponse = await axios.get(
              `${process.env.REACT_APP_API}/users/${currentUser._id}`
            );
            const updatedUser = ownerResponse.data;
            localStorage.setItem('user-token', JSON.stringify(updatedUser));
            toast.success(`La boutique "${name}" a été supprimée avec succès`);
            setIsOpen(false);
            setDeletionConfirmed(true);
            setTimeout(() => history.push('/'), 1500);
          } else {
            // Handle deletion failure
            toast.error('Échec de la suppression de la boutique');
          }
        } catch (error) {
          // Handle API call error
          toast.error('Une erreur s\'est produite lors de la suppression');
        }
      } else {
        toast.error('Le nom de la boutique ne correspond pas. La suppression est annulée.');
      }
    };  
  
   
    return (
      <Card
        shadow="lg"
        p={0}
        mb={{ base: '0px', lg: '5px' }}
        align="center"
      >
        <Box
          bg={`url(${banner})`}
          bgSize="cover"
          borderRadius="16px"
          borderBottomRadius={0}
          h="160px"
          w="100%"
        >
               <Badge
               style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1' }}
                  colorScheme={
                    visibility === 'Public'
                      ? 'green'
                      : visibility ==='Private' ?
                       'red' : 'yellow'
                  }
                  fontSize="sm"
                  mr="2"
                  borderRadius="md"
                  px="2"
                  py="1"
                >
                  {visibility || 'no visibility'}
                </Badge>

          </Box>
  
        {/* Add the shop badge overlay to the user avatar */}
  
        {/* User avatar with modified border color */}
        <Avatar
          mx="auto"
          src={avatar}
          h="90px"
          w="90px"
          mt="-43px"
          border={`4px solid ${memberShip === 'Débutant ' ? '#82d173' : memberShip === 'Professionnel' ? '#F9B710' : '#ED4C40'}`}

          //border={`2px solid ${props.propart === 'pro' ? 'green' : borderColor}`}
        />
        <Flex justify="center">
          {' '}
          <Text mr={1} color={textColorPrimary} fontWeight="bold" fontSize="xl">
            {name}
          </Text>
          {memberShip === 'Débutant ' ? (
            <MdWorkspacePremium size={25} color="#82d173" />
          ) : memberShip === 'Professionnel' ? (
            <MdWorkspacePremium size={25} color="#F9B710" />
          ) : (
            <MdWorkspacePremium size={25} color="#ED4C40" />
          )}
        </Flex>
        <Text>Vendeur depuis {formattedDate}</Text>
  
        <Center>
          <Stack
            mt={2}
            align="center"
            direction={['column', 'row']}
            spacing="40px"
          >
            <Box>
              <Flex mx="auto" direction="column">
                <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                  {posts ? posts :0}
                </Text>
                <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                  {t('Annonces')}
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex mx="auto" direction="column">
                <Text
                  color={
                    parseFloat(positiveReviews) > 80
                      ? 'green.500'
                      : parseFloat(positiveReviews) > 50
                      ? 'yellow.500'
                      : 'red.500'
                  }
                  fontSize="2xl"
                  fontWeight="bold"
                >
                  {positiveReviews ? positiveReviews : 0}%
                </Text>
                <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                  {t('Evaluations positives')}
                </Text>
              </Flex>
            </Box>
  
            <Box>
              <Flex mx="auto" direction="column">
                <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
                  {promoAds ? promoAds : 0}
                </Text>
                <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
                  {t('Promotions')}
                </Text>
              </Flex>
            </Box>
          </Stack>
        </Center>
        {currentUser?._id === userId ? (
          <Grid
  templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
  gap={3}
  mt={3}
  p={3}
>
  <NavLink to="/ad/createAd">
    <Button
      leftIcon={<MdPersonSearch />}
      variant="action"
      fontWeight="regular"
      fontSize="md"
      minW="100%"
      mx="auto"
    >
      {t('Ajouter une annonce')}
    </Button>
  </NavLink>

  <Button
    leftIcon={<MdSettings />}
    variant="action"
    fontWeight="regular"
    fontSize="md"
    minW="100%"
    mx="auto"
    onClick={updateShop}
  >
    {t('Gérer ma boutique')}
  </Button>

  <Button
    leftIcon={<MdDashboardCustomize />}
    variant="action"
    fontWeight="regular"
    fontSize="md"
    minW="100%"
    mx="auto"
    onClick={dashboard}
  >
    {t('Tableau de bord')}
  </Button>

  <Button colorScheme="red" onClick={() => setIsOpen(true)}>
    Supprimer la boutique
  </Button>
      
      {/* Modal de confirmation de suppression */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Supprimer la boutique</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Êtes-vous sûr de vouloir supprimer la boutique ? cela entrainera la  suppression des  annonces aussi. Cette action est irréversible.
            </Text>
            <InputGroup>
              <Input
                placeholder={`Tapez "${name}" pour confirmer`}
                value={confirmationName}
                onChange={(e) => setConfirmationName(e.target.value)}
              />
            </InputGroup>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              isDisabled={!confirmationName || deletionConfirmed}
            >
              {deletionConfirmed ? 'Supprimée' : 'Supprimer'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        </Grid>
      ) : (
          <>
          <Flex
    px={3}
    direction={{ base: 'column', md: 'row' }}
    justify={{ base: 'center', md: 'space-between' }}
    mt={3}
  >
    <Button
      w={{ base: '100%', md: '100%' }}
      leftIcon={<IoLogoWhatsapp />}
      variant="solid"
      colorScheme="whatsapp"
      fontWeight="regular"
      fontSize={{ base: 'md', md: 'md' }}
      mb={{ base: '3', md: '0' }}
      onClick={() => {
        const message = "Bonjour, je suis intéressé(e) par vos produits. Pourriez-vous s'il vous plaît m'envoyer plus d'informations ?";
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
      }}
    >
      Whatsapp
    </Button>
    <Button
      w={{ base: '100%', md: '100%' }}
      colorScheme="navy"
      leftIcon={<MdPhone />}
      variant="darkBrand"
      fontWeight="regular"
      fontSize={{ base: 'md', md: 'md' }}
      ml={{ base: '0', md: '3' }}
      onClick={() => {
        window.location.href = `tel:${phone}`;
      }}
    >
      {t(`Appeler`)}
    </Button>
  </Flex>
  
  <Stack p={3}>
  <Button
    leftIcon={<MdRateReview />}
    variant="darkBrand"
    fontWeight="regular"
    fontSize="md"
    minW="100%"
    mx="auto"
    onClick={handleClick}
  >
    {t(`Ajouter un avis`)}
  </Button>
  <AddReviewModal
  user= {currentUser?._id}
  shop = {name}
    isOpen={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    onSubmit={handleAddReview}
  />
</Stack>

          </>
        )}
      </Card>
    );
  }
  