import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import '../NFT.css';
import moment from 'moment';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Divider,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  InputLeftElement,
  VStack,
  List,
  ListItem,
  ListIcon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Select,
  Grid,
  Tooltip,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatArrow,
  StatHelpText,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
// Custom components
import Banner from 'views/shop/components/Banner';
import ShopInfoCard from 'views/shop/components/ShopInfoCard';
import AddAdminShop from 'views/shop/components/AddAdminShop';
import ShopAdminList from 'views/shop/components/ShopAdminList';
import { useParams } from 'react-router-dom';
import NFT from 'components/card/NFT';
import Card from 'components/card/Card.js';
import { SmallCloseIcon } from '@chakra-ui/icons';
// Custom components
import { FaCheckCircle } from 'react-icons/fa';
import CountryList from 'country-list-with-dial-code-and-flag';
import { ToastContainer, toast } from 'react-toastify';

import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
// Assets
import {
  MdIntegrationInstructions,
  MdCategory,
  MdBarChart,
  MdPeople,
  MdAssignmentTurnedIn,
  MdWorkspacePremium,
  MdShoppingBasket,
} from 'react-icons/md';
import HistoryItem from 'views/ads/recentAds/components/HistoryItem';
import {
  MdLanguage,
  MdPhone,
  MdEmail,
  MdSearch,
  MdPerson,
} from 'react-icons/md';
import Nft3 from 'assets/img/nfts/Nft3.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import { t } from 'helpers/TransWrapper';
import { useColorMode } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { addAds } from '../../../state/auth/authSlice';
import banner from 'assets/img/shopBanner.png';
import { SearchIcon } from '@chakra-ui/icons';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase';
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import Ads from '../components/ads';
import { WarningIcon } from '@chakra-ui/icons';
import Rating from 'react-rating-stars-component';
export default function DashboardShop() {
  // Chakra Color Mode
  const { name } = useParams();
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const [owner, setOwner] = useState(null);
  const [shopAds, setShopAds] = useState(null);
  const [filteredShopAds, setFilteredShopAds] = useState(null);
  const dispatch = useDispatch();
  const TestAds = state => state.auth.Test;
  // const TestAds = useSelector((state) => state.Test);
  const [likers, setLikers] = useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const [likedAds, setLikedAds] = useState([]);
  const premium = useColorModeValue('#6f432a', 'gold');
  const liked = useColorModeValue('#960018', 'gold');
  const [adsCount, setAdsCount] = useState();
  const [adData, setAdData] = useState([]);
  const [like, setLike] = useState();
  const [SelectedAd, setSelectedAd] = useState([]);
  const history = useHistory();
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const [isLoading, setIsLoading] = useState(false);
  const NFTMemoized = React.memo(NFT);
  const [user, setUser] = useState({});
  const [promotedAds, setPromotedAds] = useState([]);
  const [shop, setShop] = useState(null);
  const [reviews, setReviews] = useState([]);

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const [reviewsWithUsers, setReviewsWithUsers] = useState([]);
  function revertFormattedName(originalName) {
    // Replace dashes with spaces
    const revertedName = originalName.replace(/-/g, ' ');

    return revertedName;
  }

  // Usage example
  // Pass the review and the shop name to the handleReport function

  const shopName = revertFormattedName(name);
  const processedTestAds = createSelector([TestAds], testAds => {
    return testAds;
  });
  const selectProcessedTestAds = useSelector(processedTestAds);

  const fetchShopByName = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/name/${name}`
      );
      const shopData = response.data;
      console.log('shopData', shopData);
      setShop(shopData);
      setReviews(shopData.reviews);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchShopByName();
  }, [name]); // Assuming 'name' is a dependency that triggers fetching shop data
  const [totalAdViews, setTotalAdViews] = useState(0);
  const [totalAdClicks, setTotalAdClicks] = useState(0);
  const previousTotalViewsRef = useRef(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const fetchAdsForShop = async shop => {
    try {
      const validAdIds = shop.ads.filter(adId => adId);
      const adPromises = validAdIds.map(async adId => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/ads/ad/${adId}`
          );
          return response.data;
        } catch (error) {
          console.error(`Error fetching ad with ID ${adId}:`, error);
          // Handle specific error for this ad ID fetch failure if needed
          return null; // Return null for the failed fetch
        }
      });

      const ads = await Promise.all(adPromises);
      // 'ads' is an array containing all the fetched ad data for the shop
      const filteredAds = ads.filter(ad => ad !== null);
      const filteredValidAds = filteredAds.filter(
        ad => ad.status === 'Validée'
      );
      const filteredShopAds = filteredValidAds.filter(ad => ad.isShopPromoted);
      setFilteredShopAds(filteredShopAds);
      const totalViews = filteredValidAds.reduce(
        (accumulator, ad) => accumulator + ad.nbrViews,
        0
      );
      const totalClicks = filteredValidAds.reduce(
        (accumulator, ad) => accumulator + ad.nbrClicks,
        0
      );
      setPercentageChange(
        previousTotalViewsRef.current !== 0
          ? ((totalViews - previousTotalViewsRef.current) /
              previousTotalViewsRef.current) *
              100
          : 0
      );

      // Update previous total views
      previousTotalViewsRef.current = totalViews;

      setTotalAdViews(totalViews);
      setTotalAdClicks(totalClicks);
      setShopAds(filteredValidAds);

      console.log('ads', filteredAds);
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  useEffect(() => {
    if (shop) {
      fetchAdsForShop(shop);
    }
  }, [shop]);
  useEffect(() => {
    const fetchOwner = async () => {
      try {
        if (shop && shop.owner) {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/users/${shop.owner}`
          );
          const owner = response.data;
          setOwner(owner);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOwner();
  }, [shop]);
  console.log('ads', shopAds);
  const countAds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/count/ads`
      );
      setAdsCount(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getRatingEmoji = rating => {
    if (rating >= 4) {
      return '😍'; // Emoji for high rating
    } else if (rating >= 3) {
      return '😊'; // Emoji for medium rating
    } else {
      return '😐'; // Emoji for low rating
    }
  };
  const handleSendEmail = userEmail => {
    const subject = 'Concernant Votre Commentaire';
    const body = 'Bonjour, je voulais discuter de votre commentaire...'; // Ajoutez le corps de l'e-mail personnalisé

    const mailtoLink = `mailto:${userEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };
  const handleReport = (review, shopName, user) => {
    const yourEmail = 'jumatikmaroc@gmail.com'; // Replace with your actual email address
    const subject = `Signalement d'un commentaire - ${shopName}`;
    const body = `
      Bonjour,
  
      Un commentaire a été signalé pour examen concernant le magasin ${shopName}. Voici les détails :
      
      Auteur du commentaire : ${user.firstName} ${user.lastName}
      Commentaire : ${review.comment}
      
      Merci de prendre les mesures nécessaires.
  
      Cordialement,
      [Votre Nom]
    `;

    const mailtoLink = `mailto:${yourEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };
  const [selectedCountryCode, setSelectedCountryCode] = useState('+212');

  const formatPhoneNumber = number => {
    // Remove leading 0
    const formattedNumber = number.replace(/^0+/, '');

    // Add country code
    return selectedCountryCode + formattedNumber;
  };

  const countries = CountryList.getAll();
  const countryCodes = countries.map(country => ({
    value: country.dial_code,
    label: country.code + ' ' + country.dial_code,
  }));

  console.log(JSON.stringify(adData)); // JSON.stringify
  const getLikers = async id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/ads/${id}/like`
      );

      const likerIds = response.data;

      const likerData = [];

      for (const likerId of likerIds) {
        const userDataResponse = await axios.get(
          `${process.env.REACT_APP_API}/users/${likerId}`
        );
        const userData = userDataResponse.data;
        likerData.push(userData);

        console.log('User Data:', userData); // Log the user data for debugging
      }

      const profilePictures = likerData.map(liker => liker.profilePicture);

      console.log('Profile Pictures:', profilePictures); // Log profile pictures for debugging

      return profilePictures;
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  useEffect(() => {
    const likedAds = async () => {
      try {
        // Fetch ads and user data in a single request
        const response = await axios.get(
          `${process.env.REACT_APP_API}/ads/home/likedAds`
        );
        const likedAds = response.data;

        setLikedAds(likedAds);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    likedAds();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectProcessedTestAds.length < 1) {
        setIsLoading(true);

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API}/ads/Adsm/home`
          );
          const ads = response.data;

          const adData = await Promise.all(
            ads.map(async ad => {
              const userResponse = await axios.get(
                `${process.env.REACT_APP_API}/users/${ad.userId}`
              );
              const user = userResponse.data;
              return user ? { ad, user } : null;
            })
          );

          const filteredAdData = adData.filter(Boolean);
          dispatch(addAds(filteredAdData));
          setAdData(filteredAdData);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [TestAds.length]);

  useEffect(() => {
    countAds();
  }, []);

  const { colorMode } = useColorMode();

  const bgColor = colorMode === 'dark' ? 'rgb(17, 28, 68)' : 'gray.200';

  const positiveReviews = reviews.filter(review => review.rating >= 4);
  const totalReviews = reviews.length;
  const totalPositiveReviews = positiveReviews.length;

  const positiveReviewsPercentage =
    totalReviews === 0
      ? 'N/A'
      : ((totalPositiveReviews / totalReviews) * 100).toFixed(2);
  console.log('ad', adData);

  const fetchUserDetails = async userId => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/users/${userId}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error fetching user details for user ${userId}:`, error);
      return null;
    }
  };

  const fetchUserDetailsForReviews = async () => {
    const userDetailsPromises = reviews.map(review =>
      fetchUserDetails(review.user)
    );
    const userDetails = await Promise.all(userDetailsPromises);
    return userDetails;
  };
  const loadReviews = async () => {
    const userDetails = await fetchUserDetailsForReviews();
    const reviewsWithUsers = reviews.map((review, index) => ({
      review,
      user: userDetails[index],
    }));
    // Now, set or render the reviewsWithUsers in your component state or JSX
    // For example, you might want to set them in your component state
    setReviewsWithUsers(reviewsWithUsers);
  };
  useEffect(() => {
    loadReviews();
  }, [reviews]);

  console.log('reviewsWithUsers', reviewsWithUsers);
  const [listAdmins, setListAdmins] = useState([]);

  // Assume this function fetches the list of admins and updates the state
  const fetchAdminList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/shops/shop/${currentUser.idShop}/admin`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setListAdmins(response.data);
    } catch (error) {
      console.error('Error fetching shop admins:', error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    // Fetch the initial list of admins when the component mounts
    fetchAdminList();
  }, [listAdmins]); // Empty dependency array to ensure it only runs once when mounted
  // Define the refresh function
  const refreshShopAdmins = () => {
    // Call the fetchAdminList function to refresh the list of admins
    fetchAdminList();
  };
  return (
    <Box pt={{ base: '100px', md: '80px', xl: '90px' }}>
      {' '}
      {/* Main Fields */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
        gap={{ base: '20px', xl: '20px' }}
        display={{ base: 'block', xl: 'grid' }}
      >
        <Banner
          shopId={shop?._id}
          promoAds={filteredShopAds ? filteredShopAds.length : 0}
          posts={shopAds?.length}
          avatar={shop?.logo}
          name={shop?.name}
          userId={shop?.owner}
          banner={banner}
          memberShip={owner?.memberShip}
          positiveReviews={positiveReviewsPercentage}
        />
        <ShopInfoCard
          description={shop?.description}
          phone={shop?.contactInfo.phone}
          email={shop?.contactInfo.email}
          address={shop?.address}
          delivers={shop?.delivery}
          facebook={shop?.contactInfo.socialMedia.facebook}
          instagram={shop?.contactInfo.socialMedia.instagram}
          twitter={shop?.contactInfo.socialMedia.twitter}
          createdAt={shop?.createdAt}
          tags={shop?.tags}
          paymentMethods={shop?.paymentMethods}
        />
        <Box mb={5}>
          {' '}
          <AddAdminShop user={currentUser} listAdmins={listAdmins.length} />
          <ShopAdminList
            user={currentUser}
            listAdmins={listAdmins}
            refreshShopAdmins={refreshShopAdmins}
          />
        </Box>
        <Box
          flexDirection="column"
          gridArea={{ xl: '4 / 3 / 1 / 1', '2xl': '1 / 1 / 2 / 2' }}
        >
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb={5}>
            <MiniCalendar minW="100%" selectRange={false} />
            <Box minH="100%" minW="100%">
              <Card shadow="lg" mb={2}>
                <StatGroup>
                  <Stat>
                    <StatLabel>Vues Annonces</StatLabel>
                    <StatNumber>{totalAdViews}</StatNumber>
                  </Stat>
                  <Stat>
                    <StatLabel>Clics Annonces</StatLabel>
                    <StatNumber>{totalAdClicks}</StatNumber>
                    {/* Ajoutez d'autres composants Stat pour les clics publicités au besoin */}
                  </Stat>
                </StatGroup>
              </Card>

              <Card shadow="lg" mb={2}>
                <Flex w="100%" justify="space-between">
                  <Stat>
                    <StatLabel>Vues Boutique</StatLabel>
                    <StatNumber>{shop?.nbrViews}</StatNumber>
                    {/* Ajoutez d'autres composants Stat pour la boutique au besoin */}
                  </Stat>
                  <Stat>
                    <StatLabel>Clics Boutique</StatLabel>
                    <StatNumber>10</StatNumber>
                    {/* Ajoutez d'autres composants Stat pour les clics boutique au besoin */}
                  </Stat>
                </Flex>
              </Card>

              <Card shadow="lg">
                <Flex w="100%" justify="space-between">
                  <Stat>
                    <StatLabel>Annonces</StatLabel>
                    <StatNumber>{shopAds?.length}</StatNumber>
                    {/* Ajoutez d'autres composants Stat pour la boutique au besoin */}
                  </Stat>
                  <Stat>
                    <StatLabel>Annonces en promotion</StatLabel>
                    <StatNumber>
                      {filteredShopAds ? filteredShopAds.length : 0}
                    </StatNumber>
                    {/* Ajoutez d'autres composants Stat pour les clics boutique au besoin */}
                  </Stat>
                </Flex>
              </Card>
            </Box>
          </SimpleGrid>

          <Ads />
          <Card mb="10px" shadow="lg" maxHeight="400px">
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="15px"
            >
              {t(`Liste des avis`)}
            </Text>

            <Box overflowY="auto" maxHeight="400px">
              {reviewsWithUsers.map(({ review, user }) => (
                <Card
                  shadow="lg"
                  borderColor="gray.300"
                  mb="10px"
                  key={review._id}
                >
                  <Flex w="100%" align="start" justify="space-between" mb={2}>
                    <Flex align="start">
                      <Avatar size="md" src={user.profilePicture} />
                      <Box ml={4}>
                        <Text>{`${user.firstName} ${user.lastName}`}</Text>
                        <Text fontSize="sm" color="gray.500">
                          {moment(review.createdAt).format('MMM D, YYYY')}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex>
                      <Tooltip
                        label={`Envoyer un e-mail à ${user.email}`}
                        hasArrow
                      >
                        <IconButton
                          mr={2}
                          colorScheme="purple"
                          borderRadius="full"
                          aria-label="Send Email"
                          icon={<EmailIcon />}
                          onClick={() => handleSendEmail(user.email)} // Replace handleSendEmail with your email sending logic
                        />
                      </Tooltip>
                      <Tooltip
                        label={`Signaler le commentaire de  ${user.firstName}`}
                        hasArrow
                      >
                        <IconButton
                          colorScheme="red"
                          borderRadius="full"
                          aria-label="Send Email"
                          icon={<WarningIcon />}
                          onClick={() => handleReport(review, shop.name, user)} // Replace handleSendEmail with your email sending logic
                        />
                      </Tooltip>
                    </Flex>

                    <Box>
                      <Flex align="center">
                        <Rating
                          count={5}
                          value={review.rating}
                          size={25}
                          edit={false}
                          activeColor="#F9B710"
                        />
                        <Box mt={1} fontSize="20px" ml="1">
                          {getRatingEmoji(review.rating)}
                        </Box>
                      </Flex>
                    </Box>
                    {/* IconButton and Tooltip for sending email */}
                  </Flex>
                  <Text>{review.comment}</Text>
                </Card>
              ))}
            </Box>
          </Card>
        </Box>{' '}
      </Grid>{' '}
    </Box>
  );
}
