import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  ModalFooter,
  Box,
  Text,
  Icon,
  Flex,
  Link,
  Heading
} from '@chakra-ui/react';
import { EmailIcon, InfoIcon } from '@chakra-ui/icons';

const Popup = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg="rgba(255, 255, 255, 0.9)"
        backdropFilter="blur(15px)"
        borderRadius="lg"
        boxShadow="lg"
      >
        <ModalHeader bg="brand.500" color="white">
          <Flex alignItems="center">
            <Icon as={InfoIcon} mr={2} />
            <Heading size="md">Welcome to Jindoo!</Heading>
          </Flex>
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody>
          <Box p={4} borderRadius="md">
            <Text fontSize="lg" mb={2}>
              Thank you for joining us on this exciting journey! Our app is currently in its testing phase, and we appreciate your understanding and support as we work to improve.
            </Text>
            <Text fontSize="lg" mb={2}>
              <strong>Arabic:</strong> شكراً لانضمامكم إلينا في هذه الرحلة المثيرة! تطبيقنا حاليًا في مرحلة الاختبار، ونقدر تفهمكم ودعمكم بينما نعمل على تحسينه.
            </Text>
            <Text fontSize="lg" mb={2}>
              <strong>French:</strong> Merci de nous rejoindre dans cette aventure passionnante ! Notre application est actuellement en phase de test, et nous apprécions votre compréhension et votre soutien alors que nous travaillons à l'améliorer.
            </Text>
            <Text fontSize="lg" mb={2}>
              If you encounter any issues, please bear with us and share your feedback.
            </Text>
            <Box display="flex" alignItems="center">
              <Icon as={EmailIcon} mr={2} color="brand.500" />
              <Link href="mailto:jindoomaroc@gmail.com" color="brand.500" fontSize="lg">
                jindoomaroc@gmail.com
              </Link>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Popup;