import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Center,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useColorModeValue,
  Stack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Avatar,
  AvatarBadge,
  IconButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Divider,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  WrapItem,
  InputLeftElement,
  VStack,
  List,
  ListItem,
  ListIcon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Select
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import Card from 'components/card/Card.js';
import { SmallCloseIcon } from '@chakra-ui/icons';
// Custom components
import { FaCheckCircle } from 'react-icons/fa';

import axios from 'axios';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'; // Import icons for Facebook, Twitter, Instagram
import { HSeparator } from 'components/separator/Separator';
import {
  MdLanguage,
  MdPhone,
  MdEmail,
  MdSearch,
  MdPerson,
} from 'react-icons/md';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
// Assets
import AsyncSelect from 'react-select/async';
//import Select from 'react-select';
import illustration from 'assets/img/auth/auth.png';
import { FcGoogle } from 'react-icons/fc';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CountryList from 'country-list-with-dial-code-and-flag';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../firebase';
const CreateShop = () => {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const currentUser = JSON.parse(localStorage.getItem('user-token'));
  const [tags, setTags] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [shopCreated, setShopCreated] = useState(false);
  const handleTagsChange = e => {
    const tagsFromInput = e.target.value.split(',');
    setTags(tagsFromInput.map(tag => tag.trim()));
  };
  const [selectedCountryCode, setSelectedCountryCode] = useState('+212');

  const history = useHistory();
  const formatPhoneNumber = number => {
    // Remove leading 0
    const formattedNumber = number.replace(/^0+/, '');

    // Add country code
    return selectedCountryCode + formattedNumber;
  };
  const [shop, setShop] = useState({
    owner: currentUser._id,
    logo: '',
    name: '',
    description: '',
    rc: '',
    admins: [],
    tags: tags,
    contactInfo: {
      phone: '',
      email: '',
      socialMedia: {
        facebook: '',
        instagram: '',
        twitter: '',
      },
    },
    address: '',
    paymentMethods: '',
    delivery: false,
    timeFrame: {
      startDate: currentUser.memberShipPeriod.startDate,
      endDate: currentUser.memberShipPeriod.endDate,
    },
  });
  const [file, setFile] = useState();

  const removeTag = indexToRemove => {
    const updatedTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(updatedTags);
  };
  const onFileChange = event => {
    setShop({
      ...shop, //spread operator
      logo: event.target.files[0],
    });

    setFile(URL.createObjectURL(event.target.files[0]));
  };
  const DeleteImg = event => {
    // Update the state
    setFile();
    setShop({
      ...shop, //spread operator
      logo: '',
    });
  };

  const handleDeliveryChange = e => {
    setShop({
      ...shop,
      delivery: e.target.checked,
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    console.log('Name:', name);
    console.log('Value:', value);
    if (name === 'phone') {
      const formattedPhoneNumber = formatPhoneNumber(value);

      setShop({
        ...shop,
        contactInfo: {
          ...shop.contactInfo,
          phone: formattedPhoneNumber,
        },
      });
    } else if (name === 'email') {
      setShop({
        ...shop,
        contactInfo: {
          ...shop.contactInfo,
          email: value,
        },
      });
    } else {
      setShop({
        ...shop,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    console.log('shop', shop);
  }, [shop]);
  const handleCheckboxChange = tagsSelectionnes => {
    setShop({
      ...shop,
      paymentMethods: tagsSelectionnes,
    });
  };

  const handleSocialMediaChange = (plateforme, valeur) => {
    setShop({
      ...shop,
      contactInfo: {
        ...shop.contactInfo,
        socialMedia: {
          ...shop.contactInfo.socialMedia,
          [plateforme]: valeur,
        },
      },
    });
  };
  const countries = CountryList.getAll();
  const countryCodes = countries.map(country => ({
    value: country.dial_code,
    label: country.code + ' ' + country.dial_code,
  }));
  const [searchText, setSearchText] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  // Mocked shop data
  // const mockUsers = [
  //   { id: 1, email: 'user1@example.com', name: 'User One' },
  //   { id: 2, email: 'user2@example.com', name: 'User Two' },
  //   // ... Add more mocked shop data
  // ];

  //     const [inputValue, setInputValue] = useState('');

  //   const handleInputChangeSelect = (inputValue) => {
  //     setInputValue(inputValue);
  //   };

  //   const handleUserSelect = (selectedOption) => {
  //     setSelectedUsers(selectedOption);
  //   };

  //   const filterUsers = () => {
  //     return mockUsers.filter((shop) =>
  //       shop.email.toLowerCase().includes(inputValue.toLowerCase())
  //     );
  //   };

  //   const formatUsersForSelect = (users) => {
  //     return users.map((shop) => ({
  //       value: shop.email,
  //       label: shop.email,
  //     }));
  //   };
  //   const loadOptions = async (inputValue, callback) => {
  //     const filteredUsers = await filterUsers(inputValue);
  //     callback(formatUsersForSelect(filteredUsers));
  //   };

  //   const filteredOptions = formatUsersForSelect(filterUsers());
  const formatShopNameForURL = (shopName) => {
    // Replace spaces with hyphens and convert to lowercase
    return shopName.replace(/\s+/g, '-').toLowerCase();
  };
  const removeUser = userId => {
    const updatedUsers = selectedUsers.filter(shop => shop.id !== userId);
    setSelectedUsers(updatedUsers);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (
        !shop.name ||
        !shop.description ||
        !shop.contactInfo.phone ||
        !shop.contactInfo.email ||
        !shop.address ||
        !shop.paymentMethods ||
        (shop.tags && shop.tags.length === 0)
      ) {
        // Empty fields messages
        const errorMessages = {
          name: 'Veuillez entrer le nom du magasin',
          description: 'Veuillez ajouter une description',

          address: 'Veuillez entrer une adresse',
          paymentMethods:
            'Veuillez sélectionner au moins une méthode de paiement',
          tags: 'Veuillez ajouter des tags',
        };

        // Check each field and trigger specific toast messages
        Object.entries(errorMessages).forEach(([field, message]) => {
          if (!shop[field]) {
            toast.error(message, {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        });

        // Prevent submission if any required fields are empty
      }

      
      const shopData = {
        owner: shop.owner,
        logo: '',
        name: shop.name,
        description: shop.description,
        rc: shop.rc,
        contactInfo: {
          phone: shop.contactInfo.phone,
          email: shop.contactInfo.email,
          socialMedia: {
            facebook: shop.contactInfo.socialMedia.facebook,
            instagram: shop.contactInfo.socialMedia.instagram,
            twitter: shop.contactInfo.socialMedia.twitter,
          },
        },
        address: shop.address,
        paymentMethods: shop.paymentMethods,
        delivery: shop.delivery,
        tags: tags,
        
      };
  
      // Upload the file to storage
      const storageRef = ref(
        storage,
        `ShopsLogos/${shop.name}/${shop.logo.name}`
      );
      await uploadBytes(storageRef, shop.logo);
      const downloadURL = await getDownloadURL(storageRef);
  
      // Append the logo downloadURL to shop data
      shopData.logo = downloadURL;
  
      console.log('shop submitted', shopData);
      const response = await axios.post(
        'https://jindoo.ma:6001/shops/shop',
        shopData,
        {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
      ); // Adjust the API endpoint

      if (response.data) {
        // Shop created successfully
        toast.success('Shop created successfully', {
          // Toast success message
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        const user = await axios.patch(
          `https://jindoo.ma:6001/users/update/${currentUser._id}`,
          {
            idShop: response.data._id, // Assuming your response structure includes the created shop's ID
            shop : response.data.name
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );
        
        const idShop = user.data.idShop;
        const shop = user.data.shop;
        currentUser.idShop = idShop;
        currentUser.shop = shop;
        localStorage.setItem('user-token', JSON.stringify(currentUser));
        // Redirect to shop page after shop creation
        setShopCreated(true);
      } else {
        // Error creating shop
        toast.error('Failed to create shop', {
          // Toast error message
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } 
    catch (error) {
      // Handle any unexpected errors during shop creation
      console.error('Error creating shop:', error);
      toast.error('An error occurred while creating the shop', {
        // Toast error message
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <Card shadow="lg" padding="20px" mt={{ base: '80px', md: '90px' }}>
      {!shopCreated ? (
        <FormControl>
          {/* Section Identification */}
          <FormLabel>Identification</FormLabel>
          <SimpleGrid columns={2} gap={4}>
            <Stack direction={['column', 'row']} spacing={6} mb="20px">
              <Center>
                <Avatar size="lg" bg="#11047A" src={file}>
                  <AvatarBadge
                    as={IconButton}
                    size="sm"
                    rounded="full"
                    top="-10px"
                    colorScheme="red"
                    aria-label="remove Image"
                    icon={<SmallCloseIcon onClick={DeleteImg} />}
                  />
                </Avatar>
              </Center>

              <Center w="full">
                <Input
                  size="lg"
                  color={textColor}
                  variant="auth"
                  name="logo"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  pt="10px"
                  mb="24px"
                  fontWeight="500"
                  type="file"
                  onChange={onFileChange}
                />{' '}
              </Center>
            </Stack>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdPerson />}
                mt={1}
              />
              <Input
                variant="auth"
                size="lg"
                color={textColor}
                name="name"
                placeholder="Nom du magasin"
                onChange={handleInputChange}
              />
            </InputGroup>
            <Textarea
              name="description"
              placeholder="Description"
              onChange={handleInputChange}
            />
            <Input
              variant="auth"
              size="lg"
              color={textColor}
              name="rc"
              placeholder="Registre de commerce (RC)"
              onChange={handleInputChange}
            />
          </SimpleGrid>
          <Divider my={4} />

          {/* Section Infos de contact */}
          <FormLabel>Infos de contact</FormLabel>
          <SimpleGrid columns={2} gap={4}>
            {/* Telephone */}
            <Stack spacing={4}>
                <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                           <InputLeftElement
                pointerEvents="none"
                children={<MdPhone />}
                ml={120}
                mt={1}
              />
                  <Select
                    mr={1}
                    size="lg"
                    fontSize="sm"
                    w="150px"
                    variant="auth"
                    value={selectedCountryCode}
                    onChange={e => setSelectedCountryCode(e.target.value)}
                  >
                    {countryCodes.map(country => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </Select>
       
                 <Input
                variant="auth"
                size="lg"
                color={textColor}
                name="phone"
                placeholder="Téléphone"
                onChange={handleInputChange}
              />
                </InputGroup>
              </Stack>
        

            {/* Email */}
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdEmail />}
                mt={1}
              />
              <Input
                variant="auth"
                size="lg"
                color={textColor}
                name="email"
                placeholder="Email"
                onChange={handleInputChange}
              />
            </InputGroup>
            {/* ... Add more contact fields with icons */}
          </SimpleGrid>
          <Divider my={4} />

          {/* Section Adresse */}
          <FormLabel>Adresse</FormLabel>
          <Textarea
            name="address"
            placeholder="Adresse"
            onChange={handleInputChange}
          />
          {/* Ajoutez plus de champs d'adresse si nécessaire */}
          <Divider my={4} />

          {/* Section Horaires d'ouverture */}

          <Divider my={4} />

          {/* Section Réseaux sociaux */}
          <FormLabel>Réseaux sociaux</FormLabel>
          <SimpleGrid columns={2} gap={4}>
            {/* Facebook */}
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<FaFacebookF />}
                mt={1}
              />{' '}
              {/* Facebook Icon */}
              <Input
                variant="auth"
                size="lg"
                color={textColor}
                name="facebook"
                placeholder="Facebook"
                onChange={e =>
                  handleSocialMediaChange('facebook', e.target.value)
                }
              />
            </InputGroup>

            {/* Instagram */}
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<FaInstagram />}
                mt={1}
              />{' '}
              {/* Instagram Icon */}
              <Input
                variant="auth"
                size="lg"
                color={textColor}
                name="instagram"
                placeholder="Instagram"
                onChange={e =>
                  handleSocialMediaChange('instagram', e.target.value)
                }
              />
            </InputGroup>

            {/* Twitter */}
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<FaTwitter />}
                mt={1}
              />{' '}
              {/* Twitter Icon */}
              <Input
                variant="auth"
                size="lg"
                color={textColor}
                name="twitter"
                placeholder="Twitter"
                onChange={e =>
                  handleSocialMediaChange('twitter', e.target.value)
                }
              />
            </InputGroup>
            {/* ... Add more social fields with icons */}
          </SimpleGrid>
          <Divider my={4} />

          {/* Section Tags */}
          <FormLabel>Tags</FormLabel>
          <Input
            variant="auth"
            size="lg"
            color={textColor}
            name="tags"
            placeholder="Ajouter des tags (séparés par des virgules)"
            onChange={handleTagsChange}
          />
          <Wrap spacing={2} mt={2}>
            {tags.map((tag, index) => (
              <WrapItem key={index}>
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme="blue"
                >
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton onClick={() => removeTag(index)} />
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
          <Divider my={4} />
          {/* Section Admins */}
          {/* <FormLabel>Admins</FormLabel> */}

          {/* Display matching users as a menu */}
          {/* <Select
        isMulti
        cacheOptions
        onInputChange={handleInputChangeSelect}
        options={filteredOptions}
        onChange={handleUserSelect}
        value={selectedUsers}
        placeholder="Search users by email"
      /> */}

          {/* Display selected users */}

          {/* Section Payment Methods */}
          {/* Section Payment Methods */}
          {/* Section Payment Methods */}
          {/* Section Payment Methods and Delivery */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={5}>
            <VStack align="start" spacing={4}>
              <FormLabel>Methodes de payement</FormLabel>
              <CheckboxGroup
                onChange={checkedValues => handleCheckboxChange(checkedValues)}
              >
                <VStack align="start" spacing={2}>
                  <Checkbox value="Paypal">Paypal</Checkbox>
                  <Checkbox value="Carte bancaire">Carte bancaire</Checkbox>
                  <Checkbox value="Cash a la livraison">
                    Cash à la livraison
                  </Checkbox>
                  {/* Add more payment methods as needed */}
                </VStack>
              </CheckboxGroup>
            </VStack>

            {/* Divider for separation */}

            {/* Section Delivery */}
            <VStack align="start" spacing={4}>
              <FormLabel>Livraison</FormLabel>
              <Checkbox
                name="delivery"
                colorScheme="brand"
                onChange={handleDeliveryChange}
              >
                Proposez-vous la livraison ?
              </Checkbox>
            </VStack>
          </SimpleGrid>
          {/* Boutons de soumission */}
          <Button
            onClick={handleSubmit}
            //mt={5}
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
          >
            Créer ma boutique
          </Button>
        </FormControl>
      ) : (
        <VStack spacing={8} align="center">
          <FaCheckCircle size={50} color="green" />
          <Heading as="h2" size="xl">
            Boutique créée !
          </Heading>
          <Text fontSize="lg">Merci d'avoir créé votre boutique.</Text>
          <Text fontSize="md">
            Vous pouvez maintenant profiter de votre boutique en ligne.
          </Text>
          <Button
            colorScheme="brand"
            size="lg"
            onClick={() => {
              history.push(`/shop/shop/${formatShopNameForURL(currentUser.shop)}`);
            }}
          >
            Afficher ma boutique
          </Button>
        </VStack>
      )}
    </Card>
  );
};

export default CreateShop;
