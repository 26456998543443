import {
  Flex,
  useColorModeValue,
  Divider,
  Grid,
  Text,
  Center,
  Box,
  HStack,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React, { useState, useEffect, useRef } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Messages from './components/Messages';
import { Conversation } from './components/Conversation';
import { ConversationsMobile } from './components/ConversationsMobile.js';
import axios from 'axios';
import { CgLayoutGrid } from 'react-icons/cg';
import socket from '../../../socket';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
export default function Chat() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const currentUser = JSON.parse(localStorage.getItem('user-token'));
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(
    JSON.parse(localStorage.getItem('currentChat'))
  );
  const [messages, setMessages] = useState([]);
  const [messageSocket, setMessageSocket] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [voiceUrl, setVoiceUrl] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [lastMessage, setLastMessage] = useState('');
  const [selectedChat, setSelectedChat] = useState(null);
  const [notificationsSent, setNotificationsSent] = useState([]);
  const [notifUsers, setNotifUsers] = useState([]);
  const [friend, setFriend] = useState();
  const [userStatus, setUserStatus] = useState('offline'); // Initialize with 'offline'
  let activeIcon = useColorModeValue('brand.500', 'white');
  let textColor = useColorModeValue('secondaryGray.500', 'white');
  let brandColor = useColorModeValue('brand.500', 'brand.400');
  let activeColor = useColorModeValue('gray.700', 'white');
  function generateSessionToken() {
    // Generate a random session token using UUID (Universally Unique Identifier)
    return v4();
  }
  const sessionT = generateSessionToken();
  console.log('sessionT', sessionT);
  const storedSessionToken = sessionStorage.getItem('sessionToken');
  useEffect(() => {
    // Fetch notifications when the component mounts or when the user logs in
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/notifications/sender/${currentUser._id}?type=new_message`, // Adjust the API endpoint as needed
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        // Extract conversation IDs from notifications sent by the current user
        const sentNotifications = response.data.filter(
          notification => notification.sender === currentUser._id
        );

        const conversationIds = sentNotifications.map(
          notification => notification.conversationId
        );

        // Initialize the notificationsSent state with conversation IDs
        setNotificationsSent(conversationIds);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [currentUser._id]);
  const bgItem = useColorModeValue(
    { bg: 'white', boxShadow: '0px 40px 58px -20px rgba(112, 144, 176, 0.12)' },
    { bg: 'navy.700', boxShadow: 'unset' }
  );
  const [isMobileView] = useMediaQuery('(max-width: 1200px)');
  const history = useHistory();
  const [conversation, setConversation] = useState([]);
  const test = localStorage.getItem('user-token');
  // const userToken = JSON.parse(test);
  // const userId = userToken ? userToken._id : null;
  const [fileUrl, setFileUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    const friendId = selectedChat?.members.find(m => m !== currentUser._id);

    const getUser = async () => {
      try {
        const res = await axios(
          `${process.env.REACT_APP_API}/users/${friendId}`
        );
        setFriend(res.data);
        const friendStatusInfo = notifUsers.find(
          user => user.userId === friendId
        );

        // Set userStatus based on the friend's status
        setUserStatus(
          friendStatusInfo ? friendStatusInfo.status : 'Hors ligne'
        );
      } catch (err) {}
    };

    getUser();
  }, [selectedChat, notifUsers, currentUser._id]);
  // Replace with the actual user ID
  const sessionToken = sessionStorage.getItem('sessionToken');
  useEffect(() => {
    if (socket) {
      if (sessionToken) {
        socket.emit('addUser', { userId: currentUser._id, sessionToken });
      } else {
        socket.emit('addUser', {
          userId: currentUser._id,
          sessionToken: sessionT,
        });
        sessionStorage.setItem('sessionToken', sessionT);
      }

      socket.on('getUsers', users => {
        setNotifUsers(users);
      });
      socket.on('statusUpdate', statusData => {
        // Update the status of other users in the conversation
        const updatedUsers = notifUsers.map(user => {
          const statusInfo = statusData.find(
            status => status.userId === user.userId
          );
          if (statusInfo) {
            user.status = statusInfo.status;
          }
          return user;
        });

        setNotifUsers(updatedUsers);
      });
    }
  }, [currentUser._id]);
  console.log('notifUsers', notifUsers);

  useEffect(() => {
    // Check if socket is defined before setting up event listeners
    socket.on('getMessage', messageData => {
      // Handle new notifications here
      // Access the data in messageData
      console.log('Received new message:', messageData);

      const { conversationId, senderId, text } = messageData;

      // Create a new notification object
      const newMessage = {
        conversationId,
        senderId,
        text,
        createdAt: Date.now(), // You can set the timestamp here
      };

      // Update the state with the new notification
      setMessageSocket(prevMessages => [...prevMessages, newMessage]);
      // setLastMessage(newMessage);
      // console.log("lastMessage", lastMessage);
    });
  }, []);
  console.log('messageSocket', messageSocket);
  // console.log(userId);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages(prev => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  // useEffect(() => {
  //   socket.emit("addUser", currentUser._id);
  //   socket.on("getUsers", (users) => {

  //   });
  // }, [currentUser]);
  useEffect(() => {
    const getConversations = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/conversations/${currentUser._id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        setConversations(res.data);
      } catch (err) {}
    };

    getConversations();
  }, [currentUser._id]);
  useEffect(() => {
    const getMessages = async () => {
      try {
        const historicalMessagesResponse = await axios.get(
          ` ${process.env.REACT_APP_API}/messages/` + currentChat?._id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        const historicalMessages = historicalMessagesResponse.data;

        // Fetch real-time notifications from your state (notificationsSocket)
        const realTimeNotifications = messageSocket;
        const mergedNotifications = [
          ...historicalMessages,
          ...realTimeNotifications.filter(realTimeNotif => {
            // Check if a notification with the same message ID exists in historical notifications
            return !historicalMessages.some(
              historicalNotif => historicalNotif.text === realTimeNotif.text
            );
          }),
        ];
        mergedNotifications.sort((a, b) => b.timestamp - a.timestamp);

        setMessages(mergedNotifications);
        // const lastMessageConv = mergedNotifications.length > 0 ? mergedNotifications[mergedNotifications.length - 1].text : null;
        // setLastMessage(lastMessageConv);
      } catch (err) {}
    };
    getMessages();
  }, [currentChat, messageSocket]);

  const handleSendMessage = async e => {
    const trimmedMessage = newMessage.trim();
    if (
      trimmedMessage.length === 0 &&
      fileUrl === null &&
      imageUrl === null &&
      voiceUrl === null
    ) {
      // Don't send the message if it's empty or just spaces
      return;
    }

    const message = {
      sender: currentUser._id,
      text: trimmedMessage,
      conversationId: currentChat._id,
      fileURL: fileUrl,
      imageURL: imageUrl,
      voiceURL: voiceUrl,
    };

    const receiverId = currentChat.members.find(
      member => member !== currentUser._id
    );
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/messages`,
        message,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      // Check if a notification has already been sent for this conversation
      const notificationAlreadySent = notificationsSent.includes(
        currentChat._id
      );
      console.log('notificationAlreadySent', notificationAlreadySent);

      // Send a notification only if it hasn't been sent before for this conversation
      if (!notificationAlreadySent) {
        const notification = {
          recipient: receiverId,
          sender: currentUser?._id,
          message: `${currentUser?.firstName} ${currentUser?.lastName}, vous a envoyé un message.`,
          type: 'new_message',
          conversationId: currentChat._id,
        };

        try {
          // Create the notification
          await axios.post(
            `${process.env.REACT_APP_API}/notifications`,
            notification,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );

          // Update the notificationsSent state to indicate that a notification has been sent for this conversation
          setNotificationsSent([...notificationsSent, currentChat._id]);
        } catch (error) {
          console.error(error);
        }

        socket.emit('sendNotification', notification);
      }
      const messageSocket = {
        conversationId: currentChat._id,
        receiverId: receiverId,
        senderId: currentUser._id,
        text: newMessage,
      };
      socket.emit('sendMessage', messageSocket);

      setMessages([...messages, res.data]);
      setNewMessage('');
    } catch (err) {
      console.error(err);
    }
  };

  function handleChatClick(chat) {
    setSelectedChat(chat);
    setCurrentChat(chat);
  }
  const bg = useColorModeValue('white', 'navy.800');
  const divider = useColorModeValue('gray.500', 'white.200');

  return (
    <Grid
      p={{ base: '20px', xl: '5px' }}
      mt={{ base: '100px', xl: '0px' }}
      gridTemplateColumns={{ xl: '1fr  1fr', '1fr  1fr': '1fr  1fr' }}
      gap={{ base: '20px', xl: '1px', '2xl': '20px' }}
      display={{ base: 'grid', xl: 'grid' }}
      // Set the Grid's height to fill the viewport minus 100px
    >
      {isMobileView ? (
        <>
          <Card position="relative" overflowX="auto">
            {' '}
            <Flex textAlign="start" direction="column">
              <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                Conversations
              </Text>
              <Flex textAlign="start">
                {conversations.map(conv => (
                  <VStack
                    spacing={currentChat === conv ? '22px' : '26px'}
                    py="5px"
                    ps="10px"
                    key={conv._id}
                    onClick={() => handleChatClick(conv)}
                  >
                    <ConversationsMobile
                      notifUsers={notifUsers}
                      status={userStatus}
                      textColor={currentChat === conv ? activeColor : textColor}
                      modeText={currentChat === conv ? 'bold' : 'normal'}
                      conversation={conv}
                      friendId={conv?.members.find(m => m !== currentUser._id)}
                    />

                    {/* <Box
                  h="36px"
                  w="4px"
                  bg={currentChat === conv ? brandColor : 'transparent'}
                  borderRadius="5px"
                /> */}
                  </VStack>
                ))}
              </Flex>
            </Flex>
          </Card>

          <Flex
            align="start"
            flexDirection="column"
            gridArea={{ xl: '1 / 3 / 1 / 20', '2xl': '1 / 2 / 1 / 20' }}
            h="100%"
          >
            {selectedChat ? (
              <>
                <Card>
                  {' '}
                  <Header
                    userId={friend?._id}
                    id={selectedChat._id}
                    name={friend?.firstName + ' ' + friend?.lastName}
                    avatar={friend?.profilePicture}
                    status={userStatus}
                  />
                </Card>

                <Card
                  mt="15px"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1, // Allow the Card to grow to fill available space vertically
                  }}
                >
                  <Flex w="100%" h="400px" justify="start" align="start">
                    <Flex
                      overflowY="auto" // Add this line
                      w="100%"
                      h="100%"
                      flexDir="column"
                    >
                      {messages.map(m => (
                        <Messages
                          key={m._id}
                          messages={m}
                          currentUser={currentUser}
                        />
                      ))}{' '}
                    </Flex>
                  </Flex>
                </Card>
                <Footer
                  setVoiceUrl={setVoiceUrl}
                  voiceUrl={voiceUrl}
                  inputMessage={newMessage}
                  setInputMessage={setNewMessage}
                  handleSendMessage={handleSendMessage}
                  fileUrl={fileUrl}
                  imageUrl={imageUrl}
                  setFileUrl={setFileUrl}
                  setImageUrl={setImageUrl}
                />
              </>
            ) : (
              <Flex w="100%" h="400px" justify="center" align="center" bg={bg}>
                <Text fontSize={30} opacity="30%">
                  {' '}
                  Select a chat or start a new conversation
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      ) : (
        <>
          <Card
            style={{
              position: 'fixed',
              top: '100px', // Adjust this value to match your desired vertical position
              left: 'calc(100vh - 55vh)', // Adjust this value to match your desired horizontal position
              width: '350px', // Adjust the width as needed
              height: 'calc(100vh - 120px)', // Adjust the height as needed
              overflowY: 'auto',
            }}
            position="relative"
          >
            {' '}
            <Flex
              flexDirection="column"
              gridArea={{ xl: '1 / 1 / 2 ', '2xl': '1 / 1 / 1 ' }}
            >
              <Flex direction="column" textAlign="start">
                <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
                  Conversations
                </Text>
                {conversations.map(conv => (
                  <HStack
                    spacing={currentChat === conv ? '22px' : '26px'}
                    py="5px"
                    ps="10px"
                  >
                    <Card
                      key={conv._id}
                      _hover={bgItem}
                      mt={2}
                      onClick={() => handleChatClick(conv)}
                      bg={conv === selectedChat ? bg : bgItem}
                    >
                      <Conversation
                        notifUsers={notifUsers}
                        status={userStatus}
                        textColor={
                          currentChat === conv ? activeColor : textColor
                        }
                        modeText={currentChat === conv ? 'bold' : 'normal'}
                        conversation={conv}
                        friendId={conv?.members.find(
                          m => m !== currentUser._id
                        )}
                        //lastMessage={lastMessage.conversationId === conv._id ? lastMessage.text : null}
                      />
                    </Card>
                    <Box
                      h="36px"
                      w="4px"
                      bg={currentChat === conv ? brandColor : 'transparent'}
                      borderRadius="5px"
                    />
                  </HStack>
                ))}
              </Flex>
            </Flex>
          </Card>

          <Flex
            style={{
              position: 'fixed',
              top: '100px', // Adjust this value to match your desired vertical position
              left: 'calc(100vh - 5vh)', // Adjust this value to match your desired horizontal position
              width: '105vh', // Adjust the width as needed
              height: 'calc(100vh - 120px)', // Adjust the height as needed
            }}
            align="start"
            flexDirection="column"
            gridArea={{ xl: '1 / 3 / 1 / 20', '2xl': '1 / 2 / 1 / 20' }}
          >
            {selectedChat ? (
              <>
                <Card
                  style={{
                    position: 'fixed',
                    top: '100px', // Adjust this value to match your desired vertical position
                    left: 'calc(100vh - 5vh)', // Adjust this value to match your desired horizontal position
                    width: 'calc(100vh)', // Adjust the width as needed
                    height: '80px', // Adjust the height as needed
                  }}
                >
                  {' '}
                  <Header
                    userId={friend?._id}
                    id={selectedChat._id}
                    name={friend?.firstName + ' ' + friend?.lastName}
                    avatar={friend?.profilePicture}
                    status={userStatus}
                  />
                </Card>

                <Card
                  overflowY="auto"
                  mt="15px"
                  style={{
                    top: '180px',
                    position: 'fixed',
                    width: 'calc(100vh)',
                    bottom: '100px',
                    height: 'calc(100vh - 280px)',
                    // Adjust this value to match your desired vertical position

                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  zIndex={-9}
                >
                  <Flex w="100%" justify="start" align="start" overflowY="auto">
                    <Flex
                      // Add this line
                      w="100%"
                      flexDir="column"
                    >
                      {messages.map(m => (
                        <Messages
                          key={m._id}
                          messages={m}
                          currentUser={currentUser}
                        />
                      ))}{' '}
                    </Flex>
                  </Flex>
                </Card>
                <Flex
                  style={{
                    position: 'fixed',
                    top: 'calc(100vh - 12vh)', // Adjust this value to match your desired vertical position
                    left: 'calc(100vh - 5vh)', // Adjust this value to match your desired horizontal position
                    width: 'calc(100vh)', // Adjust the width as needed
                    height: '80px', // Adjust the height as needed

                    zIndex: 1,
                  }}
                >
                  <Footer
                    setVoiceUrl={setVoiceUrl}
                    voiceUrl={voiceUrl}
                    inputMessage={newMessage}
                    setInputMessage={setNewMessage}
                    handleSendMessage={handleSendMessage}
                    fileUrl={fileUrl}
                    imageUrl={imageUrl}
                    setFileUrl={setFileUrl}
                    setImageUrl={setImageUrl}
                  />
                </Flex>
              </>
            ) : (
              <Flex
                w="100%"
                h="calc(100vh - 120px)"
                justify="center"
                align="center"
                bg={bg}
              >
                <Text fontSize={30} opacity="30%">
                  {' '}
                  Select a chat or start a new conversation
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Grid>
  );
}
