import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice"; // Import your authSlice reducer
import recentAdsReducer from "./recentAds/recentAdsSlice"; // Import your recentAdsSlice reducer
import categoriesReducer from "./categories/categoriesSlice"; 
import adsCountReducer from "./adsCount/adsCountSlice"; // Import your new adsCountSlice reducer

const rootReducer = combineReducers({
  auth: authReducer,
  recentAds: recentAdsReducer,
  categories: categoriesReducer,
  adsCount: adsCountReducer,
  // Add other slice reducers here if you have more slices
});

export default rootReducer;
