import React, { useMemo, useState, useEffect } from 'react';
import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Badge,
  useColorModeValue,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  IconButton,
  Select,
  Switch,
} from '@chakra-ui/react';
import axios from 'axios';
import { EditIcon, CheckIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import Swal from 'sweetalert2';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { NavLink, useHistory } from 'react-router-dom';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

export default function CheckTable(props) {
  const { columnsData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const [editedData, setEditedData] = useState({});
  const [editMode, setEditMode] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const brand = useColorModeValue('brand.500', 'pink.200');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/users/get/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const adData = response.data;
      const newData = adData.map(item => ({
        action: item._id,
        nom: item.firstName + ' ' + item.lastName,
        status: item.isVerified,
        phone: item.phone,
        ProPart: item.ProPart === 'pro' ? 'Professionel' : 'Particulier',
        isAdmin: item.isAdmin,
        email: item.email,
        date: new Date(item.createdAt).toLocaleDateString(),
      }));

      setTableData(newData);
      setEditedData(newData.reduce((acc, item) => ({ ...acc, [item.action]: item }), {}));
    } catch (error) {
      console.log(error);
    }
  }

  const handleEditToggle = rowIndex => {
    setEditMode(prev => ({ ...prev, [rowIndex]: !prev[rowIndex] }));
  };

  const handleCancelEdit = userId => {
    setEditedData(prevData => ({
      ...prevData,
      [userId]: tableData.find(item => item.action === userId),
    }));
    setEditMode(prev => ({ ...prev, [userId]: false }));
  };

  const handleSave = async userId => {
    const userData = editedData[userId];
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Les modifications seront enregistrées !',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, enregistrer !',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await axios.patch(
            `${process.env.REACT_APP_API}/users/update/${userId}`,
            userData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          Swal.fire('Enregistré !', 'Vos modifications ont été enregistrées.', 'success');
          fetchData(); // Refresh the data
          setEditMode(prev => ({ ...prev, [userId]: false }));
        } catch (error) {
          Swal.fire('Erreur', "Une erreur s'est produite lors de la sauvegarde.", 'error');
        }
      }
    });
  };

  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (searchQuery) {
      const filtered = tableData.filter(row =>
        row.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
    }
  }, [searchQuery, tableData]);

  const dataToDisplay = searchQuery ? filteredData : tableData;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: dataToDisplay,
    },
    usePagination
  );

  initialState.pageSize = 10;

  return (
    <Card direction="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex mb={5} px="15px" justify="space-between" align="center">
        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
          Utilisateurs
        </Text>
        <Box>
          <InputGroup width="300px">
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
              variant="search"
              fontSize="sm"
              value={searchQuery}
              onChange={handleSearchInputChange}
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius="30px"
              placeholder="Rechercher par email..."
            />
          </InputGroup>
        </Box>
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.700" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th pe="10px" key={index} borderColor={borderColor}>
                  <Flex justify="space-between" align="center" fontSize={{ sm: '10px', lg: '10px' }} color={brand}>
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, cellIndex) => {
                  let data = '';
                  const isEditMode = editMode[row.original.action];

                  if (cell.column.Header === 'NOM COMPLET') {
                    data = <Text color={textColor} fontSize="13px" fontWeight="600">{cell.value}</Text>;
                  } else if (cell.column.Header === 'EMAIL') {
                    data = isEditMode ? (
                      <Input
                        px={3}
                        py={1}
                        borderWidth="2px"
                        borderColor={brand}
                        color={textColor}
                        key={cellIndex}
                        value={editedData[row.original.action]?.email || cell.value}
                        onChange={e => {
                          const newValue = e.target.value;
                          setEditedData(prevData => ({
                            ...prevData,
                            [row.original.action]: {
                              ...prevData[row.original.action],
                              email: newValue,
                            },
                          }));
                        }}
                        style={{ fontSize: '13px', fontWeight: '600', borderRadius: '15px', backgroundColor: 'transparent' }}
                      />
                    ) : (
                      <Text color={textColor} fontSize="13px" fontWeight="600">{cell.value}</Text>
                    );
                  } else if (cell.column.Header === 'TELEPHONE') {
                    data = isEditMode ? (
                      <Input
                        px={3}
                        py={1}
                        borderWidth="2px"
                        borderColor={brand}
                        color={textColor}
                        key={cellIndex}
                        value={editedData[row.original.action]?.phone || cell.value}
                        onChange={e => {
                          const newValue = e.target.value;
                          setEditedData(prevData => ({
                            ...prevData,
                            [row.original.action]: {
                              ...prevData[row.original.action],
                              phone: newValue,
                            },
                          }));
                        }}
                        style={{ fontSize: '13px', fontWeight: '600', borderRadius: '15px', backgroundColor: 'transparent' }}
                      />
                    ) : (
                      <Text color={textColor} fontSize="13px" fontWeight="600">{cell.value}</Text>
                    );
                  } else if (cell.column.Header === 'TYPE') {
                    data = isEditMode ? (
                      <Select
                        variant="filled"
                        value={editedData[row.original.action]?.ProPart || cell.value}
                        onChange={e => {
                          const newValue = e.target.value;
                          setEditedData(prevData => ({
                            ...prevData,
                            [row.original.action]: {
                              ...prevData[row.original.action],
                              ProPart: newValue,
                            },
                          }));
                        }}
                      >
                        <option value="Professionel">Professionel</option>
                        <option value="Particulier">Particulier</option>
                      </Select>
                    ) : (
                      <Text color={textColor} fontSize="13px" fontWeight="600">{cell.value}</Text>
                    );
                  } else if (cell.column.Header === 'ADMIN') {
                    data = (
                      <Switch
                        isChecked={cell.value}
                        onChange={e => {
                          const newValue = e.target.checked;
                          setEditedData(prevData => ({
                            ...prevData,
                            [row.original.action]: {
                              ...prevData[row.original.action],
                              isAdmin: newValue,
                            },
                          }));
                          handleSave(row.original.action);
                        }}
                      />
                    );
                  } else if (cell.column.Header === 'STATUS') {
                    let colorScheme;

                    switch (cell.value) {
                      case true:
                        colorScheme = 'green';
                        cell.value = 'Vérifié';
                        break;
                      case false:
                        colorScheme = 'red';
                        cell.value = 'Non Vérifié';
                        break;
                      default:
                        colorScheme = 'blue';
                    }
                    data = (
                      <Badge me="16px" ms="auto" colorScheme={colorScheme}>
                        {cell.value}
                      </Badge>
                    );
                  } else if (cell.column.Header === 'DATE DE CREATION') {
                    data = (
                      <Text color={textColor} fontSize="13px" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === 'ACTION') {
                    data = (
                      <Flex>
                        {isEditMode ? (
                          <>
                            <IconButton
                              icon={<CheckIcon />}
                              onClick={() => handleSave(row.original.action)}
                              colorScheme="teal"
                              variant="outline"
                              aria-label="Save"
                              mr={2}
                            />
                            <IconButton
                              icon={<CloseIcon />}
                              onClick={() => handleCancelEdit(row.original.action)}
                              colorScheme="red"
                              variant="outline"
                              aria-label="Cancel"
                            />
                          </>
                        ) : (
                          <IconButton
                            icon={<EditIcon />}
                            onClick={() => handleEditToggle(row.original.action)}
                            colorScheme="blue"
                            variant="outline"
                            aria-label="Edit"
                          />
                        )}
                      </Flex>
                    );
                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={cellIndex}
                      fontSize={{ sm: '14px' }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex justify="space-between" align="center" p="10px">
        <Button onClick={previousPage} disabled={!canPreviousPage}>
          Précédent
        </Button>
        <span>
          Page {pageIndex + 1} sur {pageOptions.length}
        </span>
        <Button onClick={nextPage} disabled={!canNextPage}>
          Suivant
        </Button>
      </Flex>
    </Card>
  );
}
