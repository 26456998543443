import {
  InputGroup,
  InputRightElement,
  Input,
  Stack,
  Badge,
  IconButton,
  Text,
  useColorModeValue,
  Flex,
  Box,
  ButtonGroup,
  Button,
  Checkbox,
  TableCaption,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
  Tab,
  TabList,
  Tabs,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormLabel,
  FormControl,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Project from 'views/admin/profile/components/Project';
import AdsCard from '../../../components/sidebar/components/SidebarCard';
import Nft3 from 'assets/img/nfts/Nft3.png';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { t } from 'helpers/TransWrapper';
import Card from 'components/card/Card.js';
import ReactPaginate from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { CheckIcon } from '@chakra-ui/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  MdOutlineRocketLaunch,
  MdOpenInNew,
  MdOutlineEdit,
  MdDeleteOutline,
  MdMoreVert,
} from 'react-icons/md';
import { SearchIcon } from '@chakra-ui/icons';
const fetchUserAds = async () => {
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  try {
    let url = `${process.env.REACT_APP_API}/ads/shop/${currentUser.idShop}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const adData = response.data;

    return adData;
  } catch (error) {
    console.error(error);
    return []; // Return an empty array or handle the error accordingly
  }
};
const DiscountModal = ({ ad, onClose }) => {
  const [discount, setDiscount] = useState(0);
  const textColorBrand = useColorModeValue('brand.500', 'purple.300');
  const handleDiscountChange = e => {
    setDiscount(parseFloat(e.target.value));
  };

  const calculateNewPrice = () => {
    const parsedDiscount = parseFloat(discount);
    const parsedOldPrice = parseFloat(ad?.price) || 0;

    // Check if discount and old price are valid numbers
    if (isNaN(parsedDiscount) || isNaN(parsedOldPrice)) {
      return 0; // Return 0 if discount or old price is not a number
    }

    // Avoid negative prices
    const discountedPrice = Math.max(
      (1 - parsedDiscount / 100) * parsedOldPrice,
      0
    );

    return discountedPrice;
  };

  console.log('price', ad?.price);
  const handleSubmit = async () => {
    try {
      let url = `${process.env.REACT_APP_API}/shops/shop/${ad.idShop}/promotion`;
  
      const response = await axios.post(
        url,
        {
          // Include any data you need to send to the API
          discount: discount,
          ad: ad._id,
          discountedPrice : calculateNewPrice().toFixed(2), 
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      const data = response.data;
      console.log('promo', data);
      toast.success('Avis ajouté avec succès', { autoClose: 3000 });
    } catch (error) {
      console.error('Error adding review:', error.message);
      toast.error("Une erreur s'est produite lors de l'ajout de l'avis.");
    }
  
    onClose(); // Close the modal after handling the submission
  };
  

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter une promotion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Nouvelle remise en pourcentage:</FormLabel>
            <Input
              type="number"
              value={discount}
              onChange={handleDiscountChange}
            />
          </FormControl>
          <Flex w="100%" justify="space-around">
            <Flex textAlign="center" direction="column" mt={4}>
              <Text>Ancien prix</Text>
              <Text fontSize="lg" fontWeight="bold">
                {/* Replace with logic to get the old price */}
                MAD{ad?.price} {/* Example value */}
              </Text>
            </Flex>

            <Flex textAlign="center" direction="column" mt={4}>
              <Text color={textColorBrand}>Nouveau prix</Text>
              <Text fontSize="lg" fontWeight="bold" color={textColorBrand}>
                {/* Display the calculated new price */}
                {`MAD${calculateNewPrice().toFixed(2)}`} {/* Example value */}
              </Text>
            </Flex>
          </Flex>
          {/* Display the old and new prices */}
        </ModalBody>
        <ModalFooter>
          <Button variant="brand" mr={3} onClick={handleSubmit}>
            Enregistrer
          </Button>
          <Button onClick={onClose}>Annuler</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default function Ads(props) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset'
  );
  const bgItem = useColorModeValue('white', 'navy.700');
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('all'); // Default is 'all', can be changed to 'published', 'draft', or 'validation'
  const [selectAll, setSelectAll] = useState(false);
  const [pageSize, setPageSize] = useState(10); // Number of ads per page
  const [discountAdId, setDiscountAdId] = useState(null);
  const userData = localStorage.getItem('user-token');
  const currentUser = JSON.parse(userData);
  const history = useHistory();
  const [selectedAds, setSelectedAds] = useState([]);
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    if (selectedStatus === 'all') {
      setFilteredAds(ads);
    } else {
      const filtered = ads.filter(ad => ad.status === selectedStatus);
      setFilteredAds(filtered);
    }
  }, [ads, selectedStatus]);
  const handleClickPrevious = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 0));
  };

  const handleClickNext = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages - 1));
  };
  const handleSearchQueryChange = event => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const adsData = await fetchUserAds();

      if (searchQuery) {
        const filteredData = adsData.filter(
          item =>
            item.name &&
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredAds(filteredData);
      } else {
        setAds(adsData);
        setFilteredAds([]); // Clear filtered data if search query is empty
      }
    };

    fetchData();
  }, [ads, searchQuery]); // Use currentUser._id in the dependency array to fetch data when the user changes or searchQuery changes

  const deleteAd = async id => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/ads/ad/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAd = adId => {
    setSelectedAds(prevSelectedAds => {
      if (prevSelectedAds.includes(adId)) {
        return prevSelectedAds.filter(id => id !== adId);
      } else {
        return [...prevSelectedAds, adId];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedAds(selectAll ? [] : displayedAds.map(ad => ad._id));
  };

  const handleDeleteSelectedAds = async () => {
    if (selectedAds.length === 0) return;

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await Promise.all(
            selectedAds.map(async adId => {
              await deleteAd(adId);
            })
          );

          Swal.fire(
            'Supprimée !',
            'Vos annonces sélectionnées ont été supprimées.',
            'success'
          );
        } catch (error) {
          console.error(error);
          Swal.fire(
            'Erreur',
            "Une erreur s'est produite lors de la suppression des annonces.",
            'error'
          );
        }

        setSelectedAds([]);
      }
    });
  };

  const handleStatusChange = status => {
    setSelectedStatus(status);
  };

  const handleClick = id => {
    history.push(`/ads/${id}`);
  };
  const handleClickDiscount = adId => {
    setDiscountAdId(adId); // Set the ad ID for which the discount modal should be opened
  };

  const handleUpdate = id => {
    history.push(`/ad/updateAd/${id}`);
  };

  const handleDelete = id => {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
      cancelButtonText: 'Annuler',
    }).then(result => {
      if (result.isConfirmed) {
        deleteAd(id);
        Swal.fire('Supprimée !', 'Votre annonce a été supprimé.', 'success');
      }
    });
  };
  const getColorScheme = status => {
    let colorScheme;

    switch (status) {
      case 'Validée':
        colorScheme = 'green';
        break;
      case 'Brouillon':
        colorScheme = 'orange';
        break;
      case 'Annulée':
        colorScheme = 'red';
        break;
      default:
        colorScheme = 'blue';
        break;
    }

    return colorScheme;
  };
  const totalPages = Math.ceil(filteredAds.length / itemsPerPage);
  const currentPageClamped = Math.max(0, Math.min(currentPage, totalPages - 1));
  const start = currentPageClamped * itemsPerPage;
  const end = start + itemsPerPage;
  const displayedAds = filteredAds.slice(start, end);
  const disabledButtonColor = useColorModeValue('gray.300', 'gray.700');
  console.log('Ad Data:', displayedAds);
  // Update the conditions for disabling the previous and next buttons
  const isPreviousDisabled = currentPageClamped === 0;
  const isNextDisabled = currentPageClamped >= totalPages - 1;

  return (
    <Card h="650px" shadow="lg" mb={{ base: '20px', lg: '20px' }}>
      <Box py={4}>
        <Flex w="100%" justify="space-between">
          <Tabs colorScheme="purple" variant="enclosed" flex="1">
            <TabList mb={{ base: '4px', md: '0' }}>
              <Tab
                fontSize="sm"
                fontWeight={selectedStatus === 'all' ? 'bold' : 'normal'}
                onClick={() => handleStatusChange('all')}
                isSelected={selectedStatus === 'all'}
              >
                Vos annonces
              </Tab>
              <Tab
                fontSize="sm"
                fontWeight={selectedStatus === 'Validée' ? 'bold' : 'normal'}
                onClick={() => handleStatusChange('Validée')}
                isSelected={selectedStatus === 'Validée'}
              >
                Validées
              </Tab>
              <Tab
                fontSize="sm"
                fontWeight={selectedStatus === 'Brouillon' ? 'bold' : 'normal'}
                onClick={() => handleStatusChange('Brouillon')}
                isSelected={selectedStatus === 'Brouillon'}
              >
                Brouillon
              </Tab>
              <Tab
                fontSize="sm"
                fontWeight={
                  selectedStatus === 'En cours de Validation'
                    ? 'bold'
                    : 'normal'
                }
                onClick={() => handleStatusChange('En cours de Validation')}
                isSelected={selectedStatus === 'En cours de Validation'}
              >
                En cours de validation
              </Tab>
            </TabList>
          </Tabs>
          <Box maxWidth="250px">
            {' '}
            {/* Adjust the width of the search bar */}
            <InputGroup>
              <Input
                type="text"
                variant="outline"
                placeholder="Rechercher des annonces"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
              <InputRightElement>
                <Icon aria-label="Search" as={SearchIcon} />
              </InputRightElement>
            </InputGroup>
          </Box>
        </Flex>
      </Box>

      {selectedAds.length > 0 && (
        <Box mb={4} mt={4} p={4} borderRadius="md" bg={bgItem} shadow="lg">
          <Flex justify="space-between" align="center">
            <Text color="red" fontWeight="bold">
              {selectedAds.length} annonce{selectedAds.length > 1 ? 's' : ''}{' '}
              sélectionnée{selectedAds.length > 1 ? 's' : ''}
            </Text>
            <Button
              colorScheme="red"
              size="sm"
              onClick={handleDeleteSelectedAds}
            >
              Supprimer
            </Button>
          </Flex>
        </Box>
      )}

      {filteredAds && filteredAds.length > 0 ? (
        <Box>
          <Box borderWidth="1px" overflowX="auto" style={{ borderTop: 'none' }}>
            <Table height="100%" variant="simple" mt={2} minWidth="100%">
              <Thead>
                <Tr>
                  <Th display={{ base: 'none', md: 'table-cell' }}>
                    {' '}
                    <Checkbox
                      colorScheme="blue"
                      isChecked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </Th>{' '}
                  {/* Checkbox column */}
                  <Th>Titre</Th>
                  <Th>Annonceur</Th>
                  <Th>Status</Th>
                  <Th>Promotion</Th>
                  <Th>Date</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayedAds.map(ad => {
                  const colorScheme = getColorScheme(ad.status);
                  const createdAt = ad.createdAt; // Example: "2023-12-01T12:34:56.789Z"

                  const date = new Date(createdAt);
                  const formattedDate = `${date.getDate()}/${
                    date.getMonth() + 1
                  }/${date.getFullYear() % 100}`;
                  return (
                    <Tr key={ad._id}>
                      <Td display={{ base: 'none', md: 'table-cell' }}>
                        <Checkbox
                          colorScheme="blue"
                          isChecked={selectedAds.includes(ad._id)}
                          onChange={() => handleSelectAd(ad._id)}
                        />
                      </Td>
                      <Td>{ad.name}</Td>
                      <Td>{ad.firstName + ' ' + ad.lastName}</Td>
                      <Td>
                        <Badge me="16px" ms="auto" colorScheme={colorScheme}>
                          {ad.status}
                        </Badge>
                      </Td>
                      <Td>
                        <Text me="16px" ms="auto" colorScheme={colorScheme}>
                          {ad.isShopPromoted ? 'oui' : 'non'}
                        </Text>
                      </Td>

                      <Td>{formattedDate}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<MdMoreVert />} // Icône des points
                            variant="action"
                            size="md"
                            bg="transparent"
                          />
                          <MenuList>
                            <MenuItem onClick={() => handleClick(ad._id)}>
                              <Box display="flex" alignItems="center">
                                <MdOpenInNew /> {/* Icône Ouvrir */}
                                <Box pl={2}>Ouvrir</Box> {/* Texte Ouvrir */}
                              </Box>
                            </MenuItem>
                            {!ad.isShopPromoted &&          <MenuItem
                              fontWeight={600}
                              onClick={() => handleClickDiscount(ad._id)}
                            >
                              <Box display="flex" alignItems="center">
                                <MdOutlineRocketLaunch /> {/* Icône Ouvrir */}
                                <Box pl={2}>Ajouter en promotion</Box>{' '}
                                {/* Texte Ouvrir */}
                              </Box>
                            </MenuItem>}
                  
                            <MenuItem onClick={() => handleUpdate(ad._id)}>
                              <Box display="flex" alignItems="center">
                                <MdOutlineEdit /> {/* Icône Modifier */}
                                <Box pl={2}>Modifier</Box>{' '}
                                {/* Texte Modifier */}
                              </Box>
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleDelete(ad._id)}
                              color="red"
                            >
                              <Box display="flex" alignItems="center">
                                <MdDeleteOutline /> {/* Icône Supprimer */}
                                <Box pl={2} color="red">
                                  Supprimer
                                </Box>{' '}
                                {/* Texte Supprimer */}
                              </Box>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  );
                })}
                {discountAdId && (
                  // Render your discount modal here, passing ad ID and other necessary props
                  // You can use a separate component for the modal or use a modal library
                  <DiscountModal
                    ad={displayedAds.find(ad => ad._id === discountAdId)}
                    onClose={() => setDiscountAdId(null)}
                  />
                )}
              </Tbody>
            </Table>
          </Box>
          <Flex justify="space-between" align="center" mt="4">
            <IconButton
              variant="brand"
              size="sm"
              aria-label="Précédent"
              icon={<ChevronLeftIcon />}
              onClick={handleClickPrevious}
              isDisabled={currentPage === 0}
              _disabled={{ bg: disabledButtonColor, cursor: 'not-allowed' }}
            />
            <Text fontSize="sm" color="gray.500">
              Page {currentPageClamped + 1} sur {totalPages}
            </Text>
            <IconButton
              variant="brand"
              size="sm"
              aria-label="Suivant"
              icon={<ChevronRightIcon />}
              onClick={handleClickNext}
              isDisabled={currentPage === totalPages - 1}
              _disabled={{ bg: disabledButtonColor, cursor: 'not-allowed' }}
            />
          </Flex>
        </Box>
      ) : (
        <Box>
          <Text fontWeight={300} fontFamily="body" mb={15}>
            Aucune annonce n'a été trouvée pour le moment. <br></br> Ajoutez une
            annonce via le bouton "Annonce gratuite"
          </Text>
          <AdsCard />
        </Box>
      )}
    </Card>
  );
}
