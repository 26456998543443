import {
  Flex,
  useColorModeValue,
  Divider,
  Box,
  Grid,
  Text,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Heading
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React from 'react';

export default function Licence() {
  const bg = useColorModeValue('white', 'navy.800');
  return (
    <Card textAlign="start" maxW="1200px"  mt="80px" p="4" boxShadow="md" borderRadius="md" >

<Heading as="h1" fontSize="2xl" mb="4">Conditions générales de vente</Heading>
      
      {/* Généralités */}
      <Box my="4">
        <Heading as="h2" fontSize="xl" mb="2">Généralités</Heading>
        
      <Text>Nom de la société : JUMATIK SARL</Text>
      <Text>Siège social : 4, RUE 18, 2EME ETAGE N°2 AMAL, SIDI BERNOUSSI CASABLANCA</Text>
      <Text>Forme juridique : SARL</Text>
      <Text>Capital : 100 000 MAD</Text>
      <Text>RC : 528593</Text>
      <Text>IF : 51621257</Text>
      <Text>TP : 1651816</Text>
      <Text>ICE : 002966921000001</Text>
      <Text>Adresse E-mail : jumatikmaroc@gmail.com</Text>
      <Text>Tél : +212 647231100</Text>
        <Text>Conditions générales de vente des produits vendus sur jumatik.ma
Date de dernière mise à jour 29/11/2023
</Text>
      </Box>

      {/* Article 1 */}
      <Box my="4">
        <Heading as="h2" fontSize="xl" mb="2">Article 1 - Objet</Heading>
        <Text>
          Les présentes conditions générales de vente (CGV) établissent les conditions contractuelles applicables à toute souscription, par un annonceur connecté ou non à son compte personnel, d'option(s) de visibilité payante(s) depuis le site Jumatik.ma ou depuis les applications mobiles.
        </Text>
        {/* ... other details for Article 1 */}
      </Box>
      <Box my="4">
      <Heading as="h2" fontSize="xl" mb="2">Article 2 - Prix</Heading>
      <Text>
        Les prix de nos produits sont indiqués en MAD toutes taxes comprises (TVA et autres taxes applicables au jour de la commande), sauf indication contraire et hors frais de traitement et d'expédition.
      </Text>
      <Text>
        Toutes les commandes quelle que soit leur origine sont payables en MAD.
      </Text>
      <Text>
        La société JUMATIK SARL se réserve le droit de modifier ses prix à tout moment, mais le produit sera facturé sur la base du tarif en vigueur au moment de la validation de la commande et sous réserve de disponibilité.
      </Text>
      <Text>
        Les produits demeurent la propriété de la société JUMATIK SARL jusqu'au paiement complet du prix.
      </Text>
      {/* Add any other relevant details from the original Article 2 */}
    </Box>
    <Box my="4">
      <Heading as="h2" fontSize="xl" mb="2">Article 3 - Commandes</Heading>
      <Text>
        Vous pouvez passer commande :
      </Text>
      <Text>
        Sur Internet : https://jumatik.ma
      </Text>
      <Text>
        Via Application Android : https://play.google.com/store/apps/details?id=com.marocapps.jumatik.sellandbuy
      </Text>
      <Text>
        Les informations contractuelles sont présentées en langue française et feront l'objet d'une confirmation au plus tard au moment de la validation de votre commande.
      </Text>
      <Text>
        La société JUMATIK SARL se réserve le droit de ne pas enregistrer un paiement, et de ne pas confirmer une commande pour quelque raison que ce soit, et plus particulièrement en cas de problème d'approvisionnement, ou en cas de difficulté concernant la commande reçue.
      </Text>
      {/* Add any other relevant details from the original Article 3 */}
    </Box>
    <Box my="4">
      <Heading as="h2" fontSize="xl" mb="2">Article 4 - Validation de votre commande</Heading>
      <Text>
        Toute commande figurant sur le site Internet https://jumatik.ma suppose l'adhésion aux présentes Conditions Générales. Toute confirmation de commande entraîne votre adhésion pleine et entière aux présentes conditions générales de vente, sans exception ni réserve.
      </Text>
      <Text>
        L'ensemble des données fournies et la confirmation enregistrée vaudront preuve de la transaction.
      </Text>
      <Text>
        Vous déclarez en avoir parfaite connaissance.
      </Text>
      <Text>
        La confirmation de commande vaudra signature et acceptation des opérations effectuées.
      </Text>
      <Text>
        Un récapitulatif des informations de votre commande et des présentes Conditions Générales, vous sera communiqué en format PDF via l'adresse e-mail de confirmation de votre commande.
      </Text>
      {/* Add any other relevant details from the original Article 4 */}
    </Box>
    <Box my="4">
      <Heading as="h2" fontSize="xl" mb="2">Article 5 - Paiement</Heading>
      <Text>
        Le fait de valider votre commande implique pour vous l'obligation de payer le prix indiqué.
      </Text>
      <Text>
        Le règlement de vos achats s'effectue par carte bancaire grâce au système sécurisé « PAYZONE ».
      </Text>
      <Text>
        Les seules cartes de paiements acceptées sur le Site sont : Visa et Mastercard.
      </Text>
      <Text>
        Le débit de la carte n'est effectué qu'au moment de l'expédition de la commande. En cas de livraisons fractionnées, seuls les produits expédiés sont débités.
      </Text>
      {/* Add any other relevant details from the original Article 5 */}
    </Box>
    <Box my="4">
      <Heading as="h2" fontSize="xl" mb="2">Article 6 - Rétractation</Heading>
      <Text>
        En application de l'article 36 de la loi 31-08 édictant des mesures de protection du consommateur, le visiteur ayant souscrit une option de visibilité payante dispose d'un délai de 7 jours pour se rétracter.
      </Text>
      <Text>
        Toutefois, en validant la commande, ledit visiteur accepte que JUMATIK commence à exécuter la prestation et renonce expressément à son droit de rétractation.
      </Text>
      {/* Add any other relevant details from the original Article 6 */}
    </Box>
    <Box my="4">
      <Heading as="h2" fontSize="xl" mb="2">Article 7 - Remboursement</Heading>
      <Text>
        Les options payantes ne sont pas remboursables :
      </Text>
      <Text>
        a- En cas de suppression d'annonce : Si la diffusion d'une annonce est refusée par Jumatik.ma en raison de sa non-conformité au règlement alors que l’annonceur a souscrit une option de visibilité payante lors du dépôt, un service client par chat, téléphone et e-mail est à disposition afin de l’aider à l’insertion de cette dernière. En revanche, si Jumatik.ma était contraint de supprimer l'annonce en cours de diffusion en raison de sa non-conformité au règlement de publication du site Jumatik.ma et notamment parce que son contenu a été, légitimement, signalé comme abusif, Jumatik.ma ne remboursera pas à l'annonceur le montant de la ou des option(s) de visibilité payante(s) souscrite(s) lors du dépôt ou de la modification ou en cours de diffusion de l'annonce ainsi supprimée en cours de diffusion. Un crédit équivalent sera toutefois accordé à l'utilisateur, sous réserve de réclamation dans les 30 jours qui suivent. Le crédit sera appliqué pour la promotion d'une annonce conforme avec le règlement et publiée à l'intérieur de ce délai.
      </Text>
      <Text>
        b- En cas de suppression d'un compte personnel : La suppression d'un compte personnel entraine la suppression de toute(s) annonce(s) attachée(s), et en conséquence, la suppression de toute(s) option(s) payante(s) souscrite(s) pour ces annonces et ne donne droit à aucun droit à remboursement, ni indemnité, ce que l'annonceur reconnait et accepte.
      </Text>
    </Box>
     <Box my="4">
      <Heading as="h2" fontSize="xl" mb="2">Article 8 - Responsabilité et force majeure</Heading>
      <Text>
        La responsabilité de JUMATIK / jumatik.ma ne peut être engagée en cas d'inexécution ou de mauvaise exécution de la commande due, soit du fait de l'annonceur, soit d'un cas de force majeure.après plusieurs propositions de rendez-vous.
      </Text>
      {/* Add any other relevant details from the original Article 8 */}
    </Box>
    <Box my="4">
      <Heading as="h2" fontSize="xl" mb="2">Article 9 - Modification</Heading>
      <Text>
        JUMATIK / jumatik.ma se réserve le droit de modifier les conditions générales de vente à n'importe quel moment. Toute modification entrera en vigueur immédiatement après sa publication sur le site Jumatik.ma. Vous êtes tenus de consulter régulièrement le site et votre accès régulier ou usage du site jumatik.ma induisent votre approbation des conditions modifiées.
      </Text>
      {/* Add any other relevant details from the original Article 9 */}
    </Box>
      <Box my="4">
        <Heading as="h2" fontSize="xl" mb="2">Article 10 - Droit applicable en cas de litiges</Heading>
        <Text>
          Les présentes conditions générales de vente sont soumises au droit Marocain. La langue du présent contrat est la langue française. Les conditions de vente correspondant aux offres proposées sur ce Site sont soumises aux dispositions protectrices des consommateurs applicables au Maroc. Tout litige devra faire l’objet d'une tentative préalable de règlement amiable. En l’absence de règlement amiable, seuls les tribunaux marocains auront compétence à traiter le litige.
        </Text>
      </Box>
 
      
      {/* Add other sections as needed */}
    </Card>
  );
}
